import { brmApi as api } from "./brm-api"
export const addTagTypes = [
  "field-config",
  "workflow-definitions",
  "workflow-runs",
  "user",
  "user-settings",
  "user-email-delegation",
  "user-notifications",
  "organization-users",
  "organization",
  "spend",
  "persons",
  "tools",
  "timeline",
  "legal",
  "vendors",
  "documents",
  "integrations",
  "logins",
  "workflow-field-timeline",
  "saved-view",
  "notifications",
  "dynamo",
] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getOpenapi: build.query<GetOpenapiApiResponse, GetOpenapiApiArg>({
        query: () => ({ url: "/openapi" }),
      }),
      getSchemaV1ByObjectType: build.query<GetSchemaV1ByObjectTypeApiResponse, GetSchemaV1ByObjectTypeApiArg>({
        query: (queryArg) => ({ url: `/schema/v1/${queryArg.objectType}` }),
        providesTags: ["field-config"],
      }),
      getSchemaV1ByObjectTypePatch: build.query<
        GetSchemaV1ByObjectTypePatchApiResponse,
        GetSchemaV1ByObjectTypePatchApiArg
      >({
        query: (queryArg) => ({ url: `/schema/v1/${queryArg.objectType}/patch` }),
        providesTags: ["field-config"],
      }),
      getSchemaV1ByObjectTypeInput: build.query<
        GetSchemaV1ByObjectTypeInputApiResponse,
        GetSchemaV1ByObjectTypeInputApiArg
      >({
        query: (queryArg) => ({ url: `/schema/v1/${queryArg.objectType}/input` }),
        providesTags: ["field-config"],
      }),
      getSchemaV1ByObjectTypeFields: build.query<
        GetSchemaV1ByObjectTypeFieldsApiResponse,
        GetSchemaV1ByObjectTypeFieldsApiArg
      >({
        query: (queryArg) => ({ url: `/schema/v1/${queryArg.objectType}/fields` }),
        providesTags: ["field-config", "workflow-definitions"],
      }),
      postSchemaV1FieldsDuplicates: build.query<
        PostSchemaV1FieldsDuplicatesApiResponse,
        PostSchemaV1FieldsDuplicatesApiArg
      >({
        query: (queryArg) => ({
          url: "/schema/v1/fields/duplicates",
          method: "POST",
          body: queryArg.similarFieldQuery,
        }),
      }),
      putSchemaV1ByObjectTypeFieldsAndFieldName: build.mutation<
        PutSchemaV1ByObjectTypeFieldsAndFieldNameApiResponse,
        PutSchemaV1ByObjectTypeFieldsAndFieldNameApiArg
      >({
        query: (queryArg) => ({
          url: `/schema/v1/${queryArg.objectType}/fields/${queryArg.fieldName}`,
          method: "PUT",
          body: queryArg.fieldConfigInput,
        }),
        invalidatesTags: ["field-config", "workflow-definitions", "workflow-runs"],
      }),
      deleteSchemaV1ByObjectTypeFieldsAndFieldName: build.mutation<
        DeleteSchemaV1ByObjectTypeFieldsAndFieldNameApiResponse,
        DeleteSchemaV1ByObjectTypeFieldsAndFieldNameApiArg
      >({
        query: (queryArg) => ({
          url: `/schema/v1/${queryArg.objectType}/fields/${queryArg.fieldName}`,
          method: "DELETE",
        }),
        invalidatesTags: ["field-config", "workflow-definitions", "workflow-runs"],
      }),
      postSchemaV1FieldsTextToField: build.mutation<
        PostSchemaV1FieldsTextToFieldApiResponse,
        PostSchemaV1FieldsTextToFieldApiArg
      >({
        query: (queryArg) => ({ url: "/schema/v1/fields/text-to-field", method: "POST", body: queryArg.body }),
      }),
      postSchemaV1Map: build.query<PostSchemaV1MapApiResponse, PostSchemaV1MapApiArg>({
        query: (queryArg) => ({ url: "/schema/v1/map", method: "POST", body: queryArg.body }),
        providesTags: ["field-config"],
      }),
      postEmailV1Inbound: build.mutation<PostEmailV1InboundApiResponse, PostEmailV1InboundApiArg>({
        query: (queryArg) => ({ url: "/email/v1/inbound", method: "POST", body: queryArg.postmarkInboundMessage }),
      }),
      getUserV1Whoami: build.query<GetUserV1WhoamiApiResponse, GetUserV1WhoamiApiArg>({
        query: () => ({ url: "/user/v1/whoami" }),
        providesTags: ["user"],
      }),
      getUserV1Settings: build.query<GetUserV1SettingsApiResponse, GetUserV1SettingsApiArg>({
        query: () => ({ url: "/user/v1/settings" }),
        providesTags: ["user-settings"],
      }),
      getUserV1SettingsTotpQr: build.query<GetUserV1SettingsTotpQrApiResponse, GetUserV1SettingsTotpQrApiArg>({
        query: () => ({ url: "/user/v1/settings/totp/qr" }),
        providesTags: ["user-settings"],
      }),
      getUserV1EmailDelegates: build.query<GetUserV1EmailDelegatesApiResponse, GetUserV1EmailDelegatesApiArg>({
        query: () => ({ url: "/user/v1/email-delegates" }),
        providesTags: ["user-email-delegation"],
      }),
      postUserV1EmailDelegates: build.mutation<PostUserV1EmailDelegatesApiResponse, PostUserV1EmailDelegatesApiArg>({
        query: (queryArg) => ({ url: "/user/v1/email-delegates", method: "POST", body: queryArg.body }),
        invalidatesTags: ["user-email-delegation"],
      }),
      postUserV1SettingsTotpEnable: build.mutation<
        PostUserV1SettingsTotpEnableApiResponse,
        PostUserV1SettingsTotpEnableApiArg
      >({
        query: (queryArg) => ({ url: "/user/v1/settings/totp/enable", method: "POST", body: queryArg.body }),
        invalidatesTags: ["user-settings"],
      }),
      postUserV1SettingsTotpDisable: build.mutation<
        PostUserV1SettingsTotpDisableApiResponse,
        PostUserV1SettingsTotpDisableApiArg
      >({
        query: (queryArg) => ({ url: "/user/v1/settings/totp/disable", method: "POST", body: queryArg.body }),
        invalidatesTags: ["user-settings"],
      }),
      getUserV1NotificationSettings: build.query<
        GetUserV1NotificationSettingsApiResponse,
        GetUserV1NotificationSettingsApiArg
      >({
        query: () => ({ url: "/user/v1/notification-settings" }),
        providesTags: ["user-notifications"],
      }),
      putUserV1NotificationSettings: build.mutation<
        PutUserV1NotificationSettingsApiResponse,
        PutUserV1NotificationSettingsApiArg
      >({
        query: (queryArg) => ({ url: "/user/v1/notification-settings", method: "PUT", body: queryArg.body }),
        invalidatesTags: ["user-notifications"],
      }),
      postUserV1DisableNotificationChannel: build.mutation<
        PostUserV1DisableNotificationChannelApiResponse,
        PostUserV1DisableNotificationChannelApiArg
      >({
        query: (queryArg) => ({ url: "/user/v1/disable-notification-channel", method: "POST", body: queryArg.body }),
        invalidatesTags: ["user-notifications"],
      }),
      getUserV1ById: build.query<GetUserV1ByIdApiResponse, GetUserV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/user/v1/${queryArg.id}` }),
      }),
      deleteUserV1ById: build.mutation<DeleteUserV1ByIdApiResponse, DeleteUserV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/user/v1/${queryArg.id}`, method: "DELETE", body: queryArg.body }),
        invalidatesTags: ["organization-users"],
      }),
      putUserV1ById: build.mutation<PutUserV1ByIdApiResponse, PutUserV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/user/v1/${queryArg.id}`, method: "PUT", body: queryArg.updateUser }),
        invalidatesTags: ["organization-users", "user"],
      }),
      getUserV1DeletionRequirementsById: build.query<
        GetUserV1DeletionRequirementsByIdApiResponse,
        GetUserV1DeletionRequirementsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/user/v1/deletion-requirements/${queryArg.id}` }),
      }),
      postUserV1Organization: build.query<PostUserV1OrganizationApiResponse, PostUserV1OrganizationApiArg>({
        query: (queryArg) => ({ url: "/user/v1/organization", method: "POST", body: queryArg.body }),
        providesTags: ["organization-users"],
      }),
      postUserV1Create: build.mutation<PostUserV1CreateApiResponse, PostUserV1CreateApiArg>({
        query: (queryArg) => ({ url: "/user/v1/create", method: "POST", body: queryArg.body }),
        invalidatesTags: ["organization-users"],
      }),
      postUserV1UpdateRoles: build.mutation<PostUserV1UpdateRolesApiResponse, PostUserV1UpdateRolesApiArg>({
        query: (queryArg) => ({ url: "/user/v1/update_roles", method: "POST", body: queryArg.body }),
        invalidatesTags: ["organization-users"],
      }),
      postUserV1Invite: build.mutation<PostUserV1InviteApiResponse, PostUserV1InviteApiArg>({
        query: (queryArg) => ({ url: "/user/v1/invite", method: "POST", body: queryArg.inviteUser }),
        invalidatesTags: ["organization-users"],
      }),
      postUserV1ResendInvite: build.mutation<PostUserV1ResendInviteApiResponse, PostUserV1ResendInviteApiArg>({
        query: (queryArg) => ({ url: "/user/v1/resend-invite", method: "POST", body: queryArg.body }),
        invalidatesTags: ["organization-users"],
      }),
      postUserV1Login: build.mutation<PostUserV1LoginApiResponse, PostUserV1LoginApiArg>({
        query: (queryArg) => ({ url: "/user/v1/login", method: "POST", body: queryArg.loginInput }),
      }),
      postUserV1SigninOktaInitiate: build.mutation<
        PostUserV1SigninOktaInitiateApiResponse,
        PostUserV1SigninOktaInitiateApiArg
      >({
        query: (queryArg) => ({ url: "/user/v1/signin/okta/initiate", method: "POST", body: queryArg.body }),
      }),
      postUserV1ForgotPassword: build.mutation<PostUserV1ForgotPasswordApiResponse, PostUserV1ForgotPasswordApiArg>({
        query: (queryArg) => ({ url: "/user/v1/forgot-password", method: "POST", body: queryArg.body }),
      }),
      postUserV1ChangePassword: build.mutation<PostUserV1ChangePasswordApiResponse, PostUserV1ChangePasswordApiArg>({
        query: (queryArg) => ({ url: "/user/v1/change-password", method: "POST", body: queryArg.changePasswordInput }),
      }),
      getUserV1Logout: build.mutation<GetUserV1LogoutApiResponse, GetUserV1LogoutApiArg>({
        query: () => ({ url: "/user/v1/logout", method: "GET" }),
      }),
      postUserV1CreateUser: build.mutation<PostUserV1CreateUserApiResponse, PostUserV1CreateUserApiArg>({
        query: (queryArg) => ({ url: "/user/v1/create_user", method: "POST", body: queryArg.createUserInput }),
      }),
      getOrganizationV1Current: build.query<GetOrganizationV1CurrentApiResponse, GetOrganizationV1CurrentApiArg>({
        query: () => ({ url: "/organization/v1/current" }),
        providesTags: ["organization"],
      }),
      getOrganizationV1CurrentOktaConfig: build.query<
        GetOrganizationV1CurrentOktaConfigApiResponse,
        GetOrganizationV1CurrentOktaConfigApiArg
      >({
        query: () => ({ url: "/organization/v1/current/okta/config" }),
        providesTags: ["organization"],
      }),
      putOrganizationV1Update: build.mutation<PutOrganizationV1UpdateApiResponse, PutOrganizationV1UpdateApiArg>({
        query: (queryArg) => ({ url: "/organization/v1/update", method: "PUT", body: queryArg.updateOrganization }),
        invalidatesTags: ["organization"],
      }),
      postOrganizationV1Create: build.mutation<PostOrganizationV1CreateApiResponse, PostOrganizationV1CreateApiArg>({
        query: (queryArg) => ({ url: "/organization/v1/create", method: "POST", body: queryArg.createOrganization }),
        invalidatesTags: ["organization"],
      }),
      postOrganizationV1ClearOrganization: build.mutation<
        PostOrganizationV1ClearOrganizationApiResponse,
        PostOrganizationV1ClearOrganizationApiArg
      >({
        query: (queryArg) => ({ url: "/organization/v1/clear-organization", method: "POST", body: queryArg.body }),
      }),
      postOrganizationV1PickableEntities: build.query<
        PostOrganizationV1PickableEntitiesApiResponse,
        PostOrganizationV1PickableEntitiesApiArg
      >({
        query: (queryArg) => ({ url: "/organization/v1/pickable-entities", method: "POST", body: queryArg.body }),
        providesTags: ["organization-users"],
      }),
      postOrganizationV1PermissionedPickableEntities: build.query<
        PostOrganizationV1PermissionedPickableEntitiesApiResponse,
        PostOrganizationV1PermissionedPickableEntitiesApiArg
      >({
        query: (queryArg) => ({
          url: "/organization/v1/permissioned-pickable-entities",
          method: "POST",
          body: queryArg.body,
        }),
        providesTags: ["organization-users"],
      }),
      getSpendV1ByTypeAndId: build.query<GetSpendV1ByTypeAndIdApiResponse, GetSpendV1ByTypeAndIdApiArg>({
        query: (queryArg) => ({
          url: `/spend/v1/${queryArg["type"]}/${queryArg.id}`,
          params: {
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            interval: queryArg.interval,
            currency_code: queryArg.currencyCode,
          },
        }),
        providesTags: ["spend"],
      }),
      getStatisticsV1EmailIntegrations: build.query<
        GetStatisticsV1EmailIntegrationsApiResponse,
        GetStatisticsV1EmailIntegrationsApiArg
      >({
        query: () => ({ url: "/statistics/v1/email-integrations" }),
      }),
      getStatisticsV1Agreements: build.query<GetStatisticsV1AgreementsApiResponse, GetStatisticsV1AgreementsApiArg>({
        query: () => ({ url: "/statistics/v1/agreements" }),
      }),
      getStatisticsV1Persons: build.query<GetStatisticsV1PersonsApiResponse, GetStatisticsV1PersonsApiArg>({
        query: () => ({ url: "/statistics/v1/persons" }),
        providesTags: ["persons"],
      }),
      getStatisticsV1Tools: build.query<GetStatisticsV1ToolsApiResponse, GetStatisticsV1ToolsApiArg>({
        query: () => ({ url: "/statistics/v1/tools" }),
        providesTags: ["tools"],
      }),
      postToolV1List: build.query<PostToolV1ListApiResponse, PostToolV1ListApiArg>({
        query: (queryArg) => ({ url: "/tool/v1/list", method: "POST", body: queryArg.listQueryStringParams }),
        providesTags: ["tools"],
      }),
      postToolV1Csv: build.mutation<PostToolV1CsvApiResponse, PostToolV1CsvApiArg>({
        query: (queryArg) => ({ url: "/tool/v1/csv", method: "POST", body: queryArg.body }),
        invalidatesTags: ["tools"],
      }),
      postToolV1AiFilter: build.mutation<PostToolV1AiFilterApiResponse, PostToolV1AiFilterApiArg>({
        query: (queryArg) => ({ url: "/tool/v1/ai-filter", method: "POST", body: queryArg.body }),
      }),
      getToolV1PickerOptions: build.query<GetToolV1PickerOptionsApiResponse, GetToolV1PickerOptionsApiArg>({
        query: (queryArg) => ({
          url: "/tool/v1/picker-options",
          params: { search: queryArg.search, link_code: queryArg.linkCode },
        }),
      }),
      putToolV1ByIdFollowing: build.mutation<PutToolV1ByIdFollowingApiResponse, PutToolV1ByIdFollowingApiArg>({
        query: (queryArg) => ({ url: `/tool/v1/${queryArg.id}/following`, method: "PUT", body: queryArg.body }),
        invalidatesTags: ["tools"],
      }),
      patchToolV1ById: build.mutation<PatchToolV1ByIdApiResponse, PatchToolV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/tool/v1/${queryArg.id}`, method: "PATCH", body: queryArg.toolPatch }),
        invalidatesTags: ["tools", "timeline"],
      }),
      getToolV1ById: build.query<GetToolV1ByIdApiResponse, GetToolV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/tool/v1/${queryArg.id}` }),
        providesTags: ["tools"],
      }),
      postToolV1ByIdLoginsList: build.query<PostToolV1ByIdLoginsListApiResponse, PostToolV1ByIdLoginsListApiArg>({
        query: (queryArg) => ({
          url: `/tool/v1/${queryArg.id}/logins/list`,
          method: "POST",
          body: queryArg.listQueryStringParams,
        }),
      }),
      postToolV1ByIdPersonsList: build.query<PostToolV1ByIdPersonsListApiResponse, PostToolV1ByIdPersonsListApiArg>({
        query: (queryArg) => ({
          url: `/tool/v1/${queryArg.id}/persons/list`,
          method: "POST",
          body: queryArg.listQueryStringParams,
        }),
        providesTags: ["tools"],
      }),
      postToolV1ByIdPersonsCsv: build.mutation<PostToolV1ByIdPersonsCsvApiResponse, PostToolV1ByIdPersonsCsvApiArg>({
        query: (queryArg) => ({ url: `/tool/v1/${queryArg.id}/persons/csv`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["tools"],
      }),
      postToolV1: build.mutation<PostToolV1ApiResponse, PostToolV1ApiArg>({
        query: (queryArg) => ({ url: "/tool/v1/", method: "POST", body: queryArg.body }),
      }),
      getToolV1Categories: build.query<GetToolV1CategoriesApiResponse, GetToolV1CategoriesApiArg>({
        query: () => ({ url: "/tool/v1/categories" }),
      }),
      getToolV1ByIdAnd: build.query<GetToolV1ByIdAndApiResponse, GetToolV1ByIdAndApiArg>({
        query: (queryArg) => ({ url: `/tool/v1/${queryArg.id}/${queryArg["*"]}` }),
        providesTags: ["tools"],
      }),
      postLegalV1AgreementsList: build.query<PostLegalV1AgreementsListApiResponse, PostLegalV1AgreementsListApiArg>({
        query: (queryArg) => ({
          url: "/legal/v1/agreements/list",
          method: "POST",
          body: queryArg.listQueryStringParams,
        }),
        providesTags: ["tools", "legal", "vendors", "documents"],
      }),
      getLegalV1AgreementsPickerOptionsByQuery: build.query<
        GetLegalV1AgreementsPickerOptionsByQueryApiResponse,
        GetLegalV1AgreementsPickerOptionsByQueryApiArg
      >({
        query: (queryArg) => ({ url: `/legal/v1/agreements/picker-options/${queryArg.query}` }),
        providesTags: ["tools", "legal", "vendors", "documents"],
      }),
      getLegalV1AgreementsById: build.query<GetLegalV1AgreementsByIdApiResponse, GetLegalV1AgreementsByIdApiArg>({
        query: (queryArg) => ({ url: `/legal/v1/agreements/${queryArg.id}` }),
        providesTags: ["tools", "legal", "vendors", "documents"],
      }),
      deleteLegalV1AgreementsById: build.mutation<
        DeleteLegalV1AgreementsByIdApiResponse,
        DeleteLegalV1AgreementsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/legal/v1/agreements/${queryArg.id}`, method: "DELETE" }),
        invalidatesTags: ["tools", "legal", "vendors", "documents"],
      }),
      postLegalV1AgreementsByIdVerification: build.mutation<
        PostLegalV1AgreementsByIdVerificationApiResponse,
        PostLegalV1AgreementsByIdVerificationApiArg
      >({
        query: (queryArg) => ({
          url: `/legal/v1/agreements/${queryArg.id}/verification`,
          method: "POST",
          body: queryArg.agreementVerificationRequest,
        }),
        invalidatesTags: ["tools", "legal", "vendors", "documents"],
      }),
      postLegalV1DetectLegalAgreementsByOrganizationId: build.mutation<
        PostLegalV1DetectLegalAgreementsByOrganizationIdApiResponse,
        PostLegalV1DetectLegalAgreementsByOrganizationIdApiArg
      >({
        query: (queryArg) => ({ url: `/legal/v1/detect-legal-agreements/${queryArg.organizationId}`, method: "POST" }),
      }),
      getLegalV1AgreementsByIdDocumentsAndDocumentIdUrl: build.query<
        GetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlApiResponse,
        GetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlApiArg
      >({
        query: (queryArg) => ({ url: `/legal/v1/agreements/${queryArg.id}/documents/${queryArg.documentId}/url` }),
      }),
      getLegalV1AgreementsByIdDocumentsAndDocumentIdContent: build.query<
        GetLegalV1AgreementsByIdDocumentsAndDocumentIdContentApiResponse,
        GetLegalV1AgreementsByIdDocumentsAndDocumentIdContentApiArg
      >({
        query: (queryArg) => ({ url: `/legal/v1/agreements/${queryArg.id}/documents/${queryArg.documentId}/content` }),
      }),
      postLegalV1Agreements: build.mutation<PostLegalV1AgreementsApiResponse, PostLegalV1AgreementsApiArg>({
        query: (queryArg) => ({ url: "/legal/v1/agreements", method: "POST", body: queryArg.legalAgreementInput }),
        invalidatesTags: ["tools", "legal", "vendors", "documents"],
      }),
      getLegalV1AgreementsByIdAnd: build.query<
        GetLegalV1AgreementsByIdAndApiResponse,
        GetLegalV1AgreementsByIdAndApiArg
      >({
        query: (queryArg) => ({ url: `/legal/v1/agreements/${queryArg.id}/${queryArg["*"]}` }),
        providesTags: ["tools"],
      }),
      postVendorV1List: build.query<PostVendorV1ListApiResponse, PostVendorV1ListApiArg>({
        query: (queryArg) => ({ url: "/vendor/v1/list", method: "POST", body: queryArg.listQueryStringParams }),
        providesTags: ["vendors"],
      }),
      postVendorV1Csv: build.mutation<PostVendorV1CsvApiResponse, PostVendorV1CsvApiArg>({
        query: (queryArg) => ({ url: "/vendor/v1/csv", method: "POST", body: queryArg.body }),
        invalidatesTags: ["vendors"],
      }),
      postVendorV1AiFilter: build.mutation<PostVendorV1AiFilterApiResponse, PostVendorV1AiFilterApiArg>({
        query: (queryArg) => ({ url: "/vendor/v1/ai-filter", method: "POST", body: queryArg.body }),
      }),
      getVendorV1PickerOptions: build.query<GetVendorV1PickerOptionsApiResponse, GetVendorV1PickerOptionsApiArg>({
        query: (queryArg) => ({ url: "/vendor/v1/picker-options", params: { search: queryArg.search } }),
      }),
      putVendorV1ByIdFollowing: build.mutation<PutVendorV1ByIdFollowingApiResponse, PutVendorV1ByIdFollowingApiArg>({
        query: (queryArg) => ({ url: `/vendor/v1/${queryArg.id}/following`, method: "PUT", body: queryArg.body }),
        invalidatesTags: ["vendors"],
      }),
      patchVendorV1ById: build.mutation<PatchVendorV1ByIdApiResponse, PatchVendorV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/vendor/v1/${queryArg.id}`, method: "PATCH", body: queryArg.vendorPatch }),
        invalidatesTags: ["vendors", "timeline"],
      }),
      getVendorV1ById: build.query<GetVendorV1ByIdApiResponse, GetVendorV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/vendor/v1/${queryArg.id}` }),
        providesTags: ["vendors"],
      }),
      getVendorV1ByIdSpendBreakdown: build.query<
        GetVendorV1ByIdSpendBreakdownApiResponse,
        GetVendorV1ByIdSpendBreakdownApiArg
      >({
        query: (queryArg) => ({
          url: `/vendor/v1/${queryArg.id}/spend-breakdown`,
          params: { currency_code: queryArg.currencyCode, start_date: queryArg.startDate, end_date: queryArg.endDate },
        }),
        providesTags: ["vendors"],
      }),
      getVendorV1ByIdAnd: build.query<GetVendorV1ByIdAndApiResponse, GetVendorV1ByIdAndApiArg>({
        query: (queryArg) => ({ url: `/vendor/v1/${queryArg.id}/${queryArg["*"]}` }),
        providesTags: ["vendors"],
      }),
      getOauthV1SigninByProvider: build.query<GetOauthV1SigninByProviderApiResponse, GetOauthV1SigninByProviderApiArg>({
        query: (queryArg) => ({
          url: `/oauth/v1/signin/${queryArg.provider}`,
          params: { linkAuthCode: queryArg.linkAuthCode, iss: queryArg.iss },
        }),
      }),
      getOauthV1SigninByProviderCallback: build.query<
        GetOauthV1SigninByProviderCallbackApiResponse,
        GetOauthV1SigninByProviderCallbackApiArg
      >({
        query: (queryArg) => ({
          url: `/oauth/v1/signin/${queryArg.provider}/callback`,
          params: { state: queryArg.state, code: queryArg.code },
        }),
      }),
      getOauthV1AuthByProvider: build.query<GetOauthV1AuthByProviderApiResponse, GetOauthV1AuthByProviderApiArg>({
        query: (queryArg) => ({ url: `/oauth/v1/auth/${queryArg.provider}` }),
      }),
      getOauthV1AuthByProviderCallback: build.query<
        GetOauthV1AuthByProviderCallbackApiResponse,
        GetOauthV1AuthByProviderCallbackApiArg
      >({
        query: (queryArg) => ({
          url: `/oauth/v1/auth/${queryArg.provider}/callback`,
          params: { state: queryArg.state, code: queryArg.code },
        }),
      }),
      getOauthV1ConnectByProvider: build.query<
        GetOauthV1ConnectByProviderApiResponse,
        GetOauthV1ConnectByProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/oauth/v1/connect/${queryArg.provider}`,
          params: { integrationId: queryArg.integrationId },
        }),
      }),
      getOauthV1ConnectByProviderCallback: build.query<
        GetOauthV1ConnectByProviderCallbackApiResponse,
        GetOauthV1ConnectByProviderCallbackApiArg
      >({
        query: (queryArg) => ({
          url: `/oauth/v1/connect/${queryArg.provider}/callback`,
          params: {
            state: queryArg.state,
            code: queryArg.code,
            integrationId: queryArg.integrationId,
            realmId: queryArg.realmId,
          },
        }),
      }),
      getIntegrationV1: build.query<GetIntegrationV1ApiResponse, GetIntegrationV1ApiArg>({
        query: () => ({ url: "/integration/v1/" }),
        providesTags: ["integrations"],
      }),
      getIntegrationV1Private: build.query<GetIntegrationV1PrivateApiResponse, GetIntegrationV1PrivateApiArg>({
        query: () => ({ url: "/integration/v1/private" }),
        providesTags: ["integrations"],
      }),
      deleteIntegrationV1ById: build.mutation<DeleteIntegrationV1ByIdApiResponse, DeleteIntegrationV1ByIdApiArg>({
        query: (queryArg) => ({
          url: `/integration/v1/${queryArg.id}`,
          method: "DELETE",
          params: { data: queryArg.data },
        }),
        invalidatesTags: ["integrations"],
      }),
      postIntegrationV1ClearData: build.mutation<
        PostIntegrationV1ClearDataApiResponse,
        PostIntegrationV1ClearDataApiArg
      >({
        query: (queryArg) => ({ url: "/integration/v1/clear-data", method: "POST", body: queryArg.body }),
        invalidatesTags: ["integrations"],
      }),
      postIntegrationV1Create: build.mutation<PostIntegrationV1CreateApiResponse, PostIntegrationV1CreateApiArg>({
        query: (queryArg) => ({ url: "/integration/v1/create", method: "POST", body: queryArg.createIntegration }),
        invalidatesTags: ["integrations"],
      }),
      postIntegrationV1ByIdDisable: build.mutation<
        PostIntegrationV1ByIdDisableApiResponse,
        PostIntegrationV1ByIdDisableApiArg
      >({
        query: (queryArg) => ({ url: `/integration/v1/${queryArg.id}/disable`, method: "POST" }),
        invalidatesTags: ["integrations"],
      }),
      postIntegrationV1ByIdEnable: build.mutation<
        PostIntegrationV1ByIdEnableApiResponse,
        PostIntegrationV1ByIdEnableApiArg
      >({
        query: (queryArg) => ({ url: `/integration/v1/${queryArg.id}/enable`, method: "POST" }),
        invalidatesTags: ["integrations"],
      }),
      postIntegrationV1Update: build.mutation<PostIntegrationV1UpdateApiResponse, PostIntegrationV1UpdateApiArg>({
        query: (queryArg) => ({ url: "/integration/v1/update", method: "POST", body: queryArg.updateIntegration }),
        invalidatesTags: ["integrations"],
      }),
      postIntegrationV1CrawlById: build.mutation<
        PostIntegrationV1CrawlByIdApiResponse,
        PostIntegrationV1CrawlByIdApiArg
      >({
        query: (queryArg) => ({ url: `/integration/v1/crawl/${queryArg.id}`, method: "POST" }),
        invalidatesTags: ["integrations"],
      }),
      getIntegrationV1HrisBySourceLinkToken: build.query<
        GetIntegrationV1HrisBySourceLinkTokenApiResponse,
        GetIntegrationV1HrisBySourceLinkTokenApiArg
      >({
        query: (queryArg) => ({ url: `/integration/v1/hris/${queryArg.source}/link-token` }),
        providesTags: ["integrations"],
      }),
      getIntegrationV1AccountingBySourceLinkToken: build.query<
        GetIntegrationV1AccountingBySourceLinkTokenApiResponse,
        GetIntegrationV1AccountingBySourceLinkTokenApiArg
      >({
        query: (queryArg) => ({ url: `/integration/v1/accounting/${queryArg.source}/link-token` }),
        providesTags: ["integrations"],
      }),
      postIntegrationV1MergeSync: build.mutation<
        PostIntegrationV1MergeSyncApiResponse,
        PostIntegrationV1MergeSyncApiArg
      >({
        query: (queryArg) => ({
          url: "/integration/v1/merge/sync",
          method: "POST",
          body: queryArg.mergeWebhookPayload,
        }),
        invalidatesTags: ["integrations"],
      }),
      postPersonV1: build.query<PostPersonV1ApiResponse, PostPersonV1ApiArg>({
        query: (queryArg) => ({ url: "/person/v1/", method: "POST", body: queryArg.listQueryStringParams }),
        providesTags: ["persons"],
      }),
      postPersonV1Merge: build.mutation<PostPersonV1MergeApiResponse, PostPersonV1MergeApiArg>({
        query: (queryArg) => ({ url: "/person/v1/merge", method: "POST", body: queryArg.body }),
      }),
      getPersonV1ById: build.query<GetPersonV1ByIdApiResponse, GetPersonV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/person/v1/${queryArg.id}` }),
        providesTags: ["persons", "organization-users"],
      }),
      getPersonV1ByIdTools: build.query<GetPersonV1ByIdToolsApiResponse, GetPersonV1ByIdToolsApiArg>({
        query: (queryArg) => ({ url: `/person/v1/${queryArg.id}/tools` }),
        providesTags: ["persons"],
      }),
      getLoginV1Summary: build.query<GetLoginV1SummaryApiResponse, GetLoginV1SummaryApiArg>({
        query: (queryArg) => ({ url: "/login/v1/summary", params: { person_id: queryArg.personId } }),
        providesTags: ["logins"],
      }),
      getLoginV1UserActivity: build.query<GetLoginV1UserActivityApiResponse, GetLoginV1UserActivityApiArg>({
        query: (queryArg) => ({
          url: "/login/v1/user-activity",
          params: {
            tool_listing_id: queryArg.toolListingId,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            interval: queryArg.interval,
          },
        }),
        providesTags: ["logins"],
      }),
      getSearchV1Text: build.query<GetSearchV1TextApiResponse, GetSearchV1TextApiArg>({
        query: (queryArg) => ({ url: "/search/v1/text", params: { q: queryArg.q } }),
      }),
      getEmployeeGroupV1: build.query<GetEmployeeGroupV1ApiResponse, GetEmployeeGroupV1ApiArg>({
        query: (queryArg) => ({ url: "/employee-group/v1/", params: { group_type: queryArg.groupType } }),
      }),
      postDocumentV1: build.mutation<PostDocumentV1ApiResponse, PostDocumentV1ApiArg>({
        query: (queryArg) => ({ url: "/document/v1/", method: "POST", body: queryArg.documentInput }),
        invalidatesTags: ["documents"],
      }),
      putDocumentV1ById: build.mutation<PutDocumentV1ByIdApiResponse, PutDocumentV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/document/v1/${queryArg.id}`, method: "PUT", body: queryArg.body }),
        invalidatesTags: ["documents"],
      }),
      getDocumentV1Extracting: build.query<GetDocumentV1ExtractingApiResponse, GetDocumentV1ExtractingApiArg>({
        query: () => ({ url: "/document/v1/extracting" }),
      }),
      postDocumentV1BackgroundExtract: build.mutation<
        PostDocumentV1BackgroundExtractApiResponse,
        PostDocumentV1BackgroundExtractApiArg
      >({
        query: (queryArg) => ({ url: "/document/v1/background-extract", method: "POST", body: queryArg.body }),
      }),
      postDocumentV1MoveToAgreement: build.mutation<
        PostDocumentV1MoveToAgreementApiResponse,
        PostDocumentV1MoveToAgreementApiArg
      >({
        query: (queryArg) => ({
          url: "/document/v1/move-to-agreement",
          method: "POST",
          body: queryArg.moveDocumentRequest,
        }),
        invalidatesTags: ["documents"],
      }),
      postDocumentV1ByIdBackgroundExtract: build.mutation<
        PostDocumentV1ByIdBackgroundExtractApiResponse,
        PostDocumentV1ByIdBackgroundExtractApiArg
      >({
        query: (queryArg) => ({
          url: `/document/v1/${queryArg.id}/background-extract`,
          method: "POST",
          body: queryArg.documentBackgroundExtractionRequest,
        }),
        invalidatesTags: ["documents"],
      }),
      postDocumentV1RetryPartialExtraction: build.mutation<
        PostDocumentV1RetryPartialExtractionApiResponse,
        PostDocumentV1RetryPartialExtractionApiArg
      >({
        query: (queryArg) => ({ url: "/document/v1/retry-partial-extraction", method: "POST", body: queryArg.body }),
      }),
      postDocumentV1ByIdRetryExtraction: build.mutation<
        PostDocumentV1ByIdRetryExtractionApiResponse,
        PostDocumentV1ByIdRetryExtractionApiArg
      >({
        query: (queryArg) => ({ url: `/document/v1/${queryArg.id}/retry-extraction`, method: "POST" }),
        invalidatesTags: ["documents"],
      }),
      postDocumentV1ByIdExtractForm: build.mutation<
        PostDocumentV1ByIdExtractFormApiResponse,
        PostDocumentV1ByIdExtractFormApiArg
      >({
        query: (queryArg) => ({ url: `/document/v1/${queryArg.id}/extract/form`, method: "POST" }),
      }),
      getDocumentV1OwnByIdContent: build.query<
        GetDocumentV1OwnByIdContentApiResponse,
        GetDocumentV1OwnByIdContentApiArg
      >({
        query: (queryArg) => ({ url: `/document/v1/own/${queryArg.id}/content` }),
      }),
      getDocumentV1OwnByIdUrl: build.query<GetDocumentV1OwnByIdUrlApiResponse, GetDocumentV1OwnByIdUrlApiArg>({
        query: (queryArg) => ({ url: `/document/v1/own/${queryArg.id}/url` }),
      }),
      getWorkflowV1ByKindSchema: build.query<GetWorkflowV1ByKindSchemaApiResponse, GetWorkflowV1ByKindSchemaApiArg>({
        query: (queryArg) => ({ url: `/workflow/v1/${queryArg.kind}/schema` }),
        providesTags: ["field-config"],
      }),
      getWorkflowV1DefinitionsById: build.query<
        GetWorkflowV1DefinitionsByIdApiResponse,
        GetWorkflowV1DefinitionsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/workflow/v1/definitions/${queryArg.id}` }),
        providesTags: ["workflow-definitions"],
      }),
      putWorkflowV1DefinitionsById: build.mutation<
        PutWorkflowV1DefinitionsByIdApiResponse,
        PutWorkflowV1DefinitionsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/definitions/${queryArg.id}`,
          method: "PUT",
          body: queryArg.workflowDefUpdate,
        }),
        invalidatesTags: ["workflow-definitions"],
      }),
      getWorkflowV1DefinitionsRenewal: build.query<
        GetWorkflowV1DefinitionsRenewalApiResponse,
        GetWorkflowV1DefinitionsRenewalApiArg
      >({
        query: () => ({ url: "/workflow/v1/definitions/renewal" }),
        providesTags: ["workflow-definitions"],
      }),
      getWorkflowV1DefinitionsPurchase: build.query<
        GetWorkflowV1DefinitionsPurchaseApiResponse,
        GetWorkflowV1DefinitionsPurchaseApiArg
      >({
        query: () => ({ url: "/workflow/v1/definitions/purchase" }),
        providesTags: ["workflow-definitions"],
      }),
      getWorkflowV1Definitions: build.query<GetWorkflowV1DefinitionsApiResponse, GetWorkflowV1DefinitionsApiArg>({
        query: () => ({ url: "/workflow/v1/definitions" }),
        providesTags: ["workflow-definitions"],
      }),
      postWorkflowV1Definitions: build.mutation<PostWorkflowV1DefinitionsApiResponse, PostWorkflowV1DefinitionsApiArg>({
        query: (queryArg) => ({ url: "/workflow/v1/definitions", method: "POST", body: queryArg.workflowDefInput }),
        invalidatesTags: ["workflow-definitions"],
      }),
      getWorkflowV1RunsPickables: build.query<GetWorkflowV1RunsPickablesApiResponse, GetWorkflowV1RunsPickablesApiArg>({
        query: () => ({ url: "/workflow/v1/runs/pickables" }),
      }),
      postWorkflowV1DefinitionsConditional: build.mutation<
        PostWorkflowV1DefinitionsConditionalApiResponse,
        PostWorkflowV1DefinitionsConditionalApiArg
      >({
        query: (queryArg) => ({ url: "/workflow/v1/definitions/conditional", method: "POST", body: queryArg.body }),
      }),
      postWorkflowV1RunsList: build.query<PostWorkflowV1RunsListApiResponse, PostWorkflowV1RunsListApiArg>({
        query: (queryArg) => ({ url: "/workflow/v1/runs-list/", method: "POST", body: queryArg.listQueryStringParams }),
        providesTags: ["workflow-runs"],
      }),
      postWorkflowV1Runs: build.mutation<PostWorkflowV1RunsApiResponse, PostWorkflowV1RunsApiArg>({
        query: (queryArg) => ({ url: "/workflow/v1/runs/", method: "POST", body: queryArg.workflowRunInput }),
        invalidatesTags: ["workflow-runs", "tools"],
      }),
      getWorkflowV1RunsById: build.query<GetWorkflowV1RunsByIdApiResponse, GetWorkflowV1RunsByIdApiArg>({
        query: (queryArg) => ({ url: `/workflow/v1/runs/${queryArg.id}` }),
        providesTags: ["workflow-runs"],
      }),
      patchWorkflowV1RunsById: build.mutation<PatchWorkflowV1RunsByIdApiResponse, PatchWorkflowV1RunsByIdApiArg>({
        query: (queryArg) => ({
          url: `/workflow/v1/runs/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.workflowRunUpdatePatch,
        }),
        invalidatesTags: ["workflow-runs"],
      }),
      deleteWorkflowV1RunsById: build.mutation<DeleteWorkflowV1RunsByIdApiResponse, DeleteWorkflowV1RunsByIdApiArg>({
        query: (queryArg) => ({ url: `/workflow/v1/runs/${queryArg.id}`, method: "DELETE" }),
        invalidatesTags: ["workflow-runs"],
      }),
      putWorkflowV1RunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameGatherer: build.mutation<
        PutWorkflowV1RunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameGathererApiResponse,
        PutWorkflowV1RunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameGathererApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/runs/${queryArg.id}/objects/${queryArg.objectType}/${queryArg.objectId}/fields/${queryArg.fieldName}/gatherer`,
          method: "PUT",
          body: queryArg.setFieldGatherRequest,
        }),
        invalidatesTags: ["workflow-runs"],
      }),
      postWorkflowV1RunsByIdCancel: build.mutation<
        PostWorkflowV1RunsByIdCancelApiResponse,
        PostWorkflowV1RunsByIdCancelApiArg
      >({
        query: (queryArg) => ({ url: `/workflow/v1/runs/${queryArg.id}/cancel`, method: "POST" }),
        invalidatesTags: ["workflow-runs"],
      }),
      patchWorkflowV1RunsByIdSteps: build.mutation<
        PatchWorkflowV1RunsByIdStepsApiResponse,
        PatchWorkflowV1RunsByIdStepsApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/runs/${queryArg.id}/steps`,
          method: "PATCH",
          body: queryArg.workflowRunStepsUpdatePatch,
        }),
        invalidatesTags: ["workflow-runs"],
      }),
      putWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApproval: build.mutation<
        PutWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApprovalApiResponse,
        PutWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApprovalApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/step-runs/${queryArg.id}/objects/${queryArg.objectType}/${queryArg.objectId}/fields/${queryArg.fieldName}/approval`,
          method: "PUT",
          body: queryArg.workflowRunStepFieldApprovalStatus,
        }),
        invalidatesTags: ["workflow-runs"],
      }),
      getWorkflowV1SellerLinks: build.query<GetWorkflowV1SellerLinksApiResponse, GetWorkflowV1SellerLinksApiArg>({
        query: () => ({ url: "/workflow/v1/seller/links" }),
        providesTags: ["workflow-runs"],
      }),
      postWorkflowV1Links: build.mutation<PostWorkflowV1LinksApiResponse, PostWorkflowV1LinksApiArg>({
        query: (queryArg) => ({ url: "/workflow/v1/links", method: "POST", body: queryArg.createBrmLinkInput }),
        invalidatesTags: ["workflow-runs"],
      }),
      getWorkflowV1LinksByCodeWorkflowRun: build.query<
        GetWorkflowV1LinksByCodeWorkflowRunApiResponse,
        GetWorkflowV1LinksByCodeWorkflowRunApiArg
      >({
        query: (queryArg) => ({ url: `/workflow/v1/links/${queryArg.code}/workflow-run` }),
        providesTags: ["workflow-runs"],
      }),
      postWorkflowV1LinksByCodeWorkflowRunStepStatesAndWorkflowRunStepId: build.mutation<
        PostWorkflowV1LinksByCodeWorkflowRunStepStatesAndWorkflowRunStepIdApiResponse,
        PostWorkflowV1LinksByCodeWorkflowRunStepStatesAndWorkflowRunStepIdApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/links/${queryArg.code}/workflow-run-step-states/${queryArg.workflowRunStepId}`,
          method: "POST",
          body: queryArg.workflowRunStepDraftStateUpdateInput,
        }),
        invalidatesTags: ["workflow-runs"],
      }),
      getWorkflowV1LinksByCode: build.query<GetWorkflowV1LinksByCodeApiResponse, GetWorkflowV1LinksByCodeApiArg>({
        query: (queryArg) => ({ url: `/workflow/v1/links/${queryArg.code}` }),
        providesTags: ["workflow-runs"],
      }),
      postWorkflowV1LinksByCode: build.mutation<PostWorkflowV1LinksByCodeApiResponse, PostWorkflowV1LinksByCodeApiArg>({
        query: (queryArg) => ({
          url: `/workflow/v1/links/${queryArg.code}`,
          method: "POST",
          body: queryArg.createBrmLinkInput,
        }),
        invalidatesTags: ["workflow-runs"],
      }),
      postWorkflowV1StepRunsByIdDraft: build.mutation<
        PostWorkflowV1StepRunsByIdDraftApiResponse,
        PostWorkflowV1StepRunsByIdDraftApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/step-runs/${queryArg.id}/draft`,
          method: "POST",
          body: queryArg.workflowRunStepDraftStateUpdateInput,
        }),
        invalidatesTags: ["workflow-runs", "workflow-field-timeline"],
      }),
      postWorkflowV1StepRunsByWorkflowRunStepIdUnapprove: build.mutation<
        PostWorkflowV1StepRunsByWorkflowRunStepIdUnapproveApiResponse,
        PostWorkflowV1StepRunsByWorkflowRunStepIdUnapproveApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/step-runs/${queryArg.workflowRunStepId}/unapprove`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["workflow-runs", "timeline"],
      }),
      postWorkflowV1StepRunsByWorkflowRunStepIdRequestChanges: build.mutation<
        PostWorkflowV1StepRunsByWorkflowRunStepIdRequestChangesApiResponse,
        PostWorkflowV1StepRunsByWorkflowRunStepIdRequestChangesApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/step-runs/${queryArg.workflowRunStepId}/request-changes`,
          method: "POST",
        }),
        invalidatesTags: ["workflow-runs", "timeline"],
      }),
      postWorkflowV1StepRunsByWorkflowRunStepIdSubmission: build.mutation<
        PostWorkflowV1StepRunsByWorkflowRunStepIdSubmissionApiResponse,
        PostWorkflowV1StepRunsByWorkflowRunStepIdSubmissionApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/step-runs/${queryArg.workflowRunStepId}/submission`,
          method: "POST",
          body: queryArg.workflowStepRunSubmission,
        }),
        invalidatesTags: ["workflow-runs", "timeline"],
      }),
      getWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdUrl: build.query<
        GetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdUrlApiResponse,
        GetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdUrlApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/runs/${queryArg.workflowRunId}/documents/${queryArg.documentId}/url`,
        }),
      }),
      getWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdContent: build.query<
        GetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdContentApiResponse,
        GetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdContentApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/runs/${queryArg.workflowRunId}/documents/${queryArg.documentId}/content`,
        }),
      }),
      getTransactionV1ById: build.query<GetTransactionV1ByIdApiResponse, GetTransactionV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/transaction/v1/${queryArg.id}` }),
      }),
      postTransactionV1List: build.query<PostTransactionV1ListApiResponse, PostTransactionV1ListApiArg>({
        query: (queryArg) => ({
          url: "/transaction/v1/list",
          method: "POST",
          body: queryArg.listQueryStringParams,
          params: { tool_id: queryArg.toolId, vendor_id: queryArg.vendorId },
        }),
      }),
      getTransactionV1ByIdReceiptsAndReceiptIdDocumentUrl: build.query<
        GetTransactionV1ByIdReceiptsAndReceiptIdDocumentUrlApiResponse,
        GetTransactionV1ByIdReceiptsAndReceiptIdDocumentUrlApiArg
      >({
        query: (queryArg) => ({ url: `/transaction/v1/${queryArg.id}/receipts/${queryArg.receiptId}/document/url` }),
      }),
      getTransactionV1ByIdReceiptsAndReceiptIdDocumentContent: build.query<
        GetTransactionV1ByIdReceiptsAndReceiptIdDocumentContentApiResponse,
        GetTransactionV1ByIdReceiptsAndReceiptIdDocumentContentApiArg
      >({
        query: (queryArg) => ({
          url: `/transaction/v1/${queryArg.id}/receipts/${queryArg.receiptId}/document/content`,
        }),
      }),
      getSavedViewV1ByTableIdentifier: build.query<
        GetSavedViewV1ByTableIdentifierApiResponse,
        GetSavedViewV1ByTableIdentifierApiArg
      >({
        query: (queryArg) => ({ url: `/saved-view/v1/${queryArg.tableIdentifier}` }),
        providesTags: ["saved-view"],
      }),
      putSavedViewV1ById: build.mutation<PutSavedViewV1ByIdApiResponse, PutSavedViewV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/saved-view/v1/${queryArg.id}`, method: "PUT", body: queryArg.editSavedView }),
        invalidatesTags: ["saved-view"],
      }),
      deleteSavedViewV1ById: build.mutation<DeleteSavedViewV1ByIdApiResponse, DeleteSavedViewV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/saved-view/v1/${queryArg.id}`, method: "DELETE" }),
        invalidatesTags: ["saved-view"],
      }),
      postSavedViewV1Create: build.mutation<PostSavedViewV1CreateApiResponse, PostSavedViewV1CreateApiArg>({
        query: (queryArg) => ({ url: "/saved-view/v1/create", method: "POST", body: queryArg.createSavedView }),
        invalidatesTags: ["saved-view"],
      }),
      postBetsyV1Vector: build.mutation<PostBetsyV1VectorApiResponse, PostBetsyV1VectorApiArg>({
        query: (queryArg) => ({ url: "/betsy/v1/vector", method: "POST", body: queryArg.body }),
      }),
      postBetsyV1Ask: build.mutation<PostBetsyV1AskApiResponse, PostBetsyV1AskApiArg>({
        query: (queryArg) => ({ url: "/betsy/v1/ask", method: "POST", body: queryArg.body }),
      }),
      postBetsyV1SlackEvent: build.mutation<PostBetsyV1SlackEventApiResponse, PostBetsyV1SlackEventApiArg>({
        query: (queryArg) => ({ url: "/betsy/v1/slack/event", method: "POST", body: queryArg.body }),
      }),
      getBetsyV1Conversations: build.query<GetBetsyV1ConversationsApiResponse, GetBetsyV1ConversationsApiArg>({
        query: () => ({ url: "/betsy/v1/conversations" }),
      }),
      getBetsyV1ConversationsById: build.query<
        GetBetsyV1ConversationsByIdApiResponse,
        GetBetsyV1ConversationsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/betsy/v1/conversations/${queryArg.id}` }),
      }),
      putErpAccountV1ById: build.mutation<PutErpAccountV1ByIdApiResponse, PutErpAccountV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/erp-account/v1/${queryArg.id}`, method: "PUT", body: queryArg.body }),
      }),
      getTimelineV1Events: build.query<GetTimelineV1EventsApiResponse, GetTimelineV1EventsApiArg>({
        query: (queryArg) => ({
          url: "/timeline/v1/events",
          params: {
            limit: queryArg.limit,
            workflow_run_id: queryArg.workflowRunId,
            tool_id: queryArg.toolId,
            vendor_id: queryArg.vendorId,
            until: queryArg.until,
            since: queryArg.since,
          },
        }),
        providesTags: ["timeline", "tools", "vendors"],
      }),
      deleteTimelineV1EventsById: build.mutation<
        DeleteTimelineV1EventsByIdApiResponse,
        DeleteTimelineV1EventsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/timeline/v1/events/${queryArg.id}`, method: "DELETE" }),
        invalidatesTags: ["timeline"],
      }),
      putTimelineV1EventsById: build.mutation<PutTimelineV1EventsByIdApiResponse, PutTimelineV1EventsByIdApiArg>({
        query: (queryArg) => ({
          url: `/timeline/v1/events/${queryArg.id}`,
          method: "PUT",
          body: queryArg.timelineUpdate,
        }),
        invalidatesTags: ["timeline"],
      }),
      getTimelineV1WorkflowRunByWorkflowRunId: build.query<
        GetTimelineV1WorkflowRunByWorkflowRunIdApiResponse,
        GetTimelineV1WorkflowRunByWorkflowRunIdApiArg
      >({
        query: (queryArg) => ({ url: `/timeline/v1/workflow_run/${queryArg.workflowRunId}` }),
        providesTags: ["timeline", "workflow-field-timeline"],
      }),
      getTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepId: build.query<
        GetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdApiResponse,
        GetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdApiArg
      >({
        query: (queryArg) => ({
          url: `/timeline/v1/workflow_run/${queryArg.workflowRunId}/workflow_run_step/${queryArg.workflowRunStepId}`,
        }),
        providesTags: ["timeline", "workflow-field-timeline"],
      }),
      getTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldName: build.query<
        GetTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldNameApiResponse,
        GetTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldNameApiArg
      >({
        query: (queryArg) => ({
          url: `/timeline/v1/events/objects/${queryArg.objectType}/${queryArg.objectId}/fields/${queryArg.fieldName}`,
          params: { workflow_run_id: queryArg.workflowRunId },
        }),
        providesTags: ["timeline", "workflow-field-timeline"],
      }),
      getTimelineV1EventsByIdDocumentContent: build.query<
        GetTimelineV1EventsByIdDocumentContentApiResponse,
        GetTimelineV1EventsByIdDocumentContentApiArg
      >({
        query: (queryArg) => ({ url: `/timeline/v1/events/${queryArg.id}/document/content` }),
      }),
      postTimelineV1EventsObjectsByObjectTypeAndObjectId: build.mutation<
        PostTimelineV1EventsObjectsByObjectTypeAndObjectIdApiResponse,
        PostTimelineV1EventsObjectsByObjectTypeAndObjectIdApiArg
      >({
        query: (queryArg) => ({
          url: `/timeline/v1/events/objects/${queryArg.objectType}/${queryArg.objectId}`,
          method: "POST",
          body: queryArg.timelineInput,
          params: {
            field_name: queryArg.fieldName,
            workflow_run_id: queryArg.workflowRunId,
            workflow_run_step_id: queryArg.workflowRunStepId,
          },
        }),
        invalidatesTags: ["timeline"],
      }),
      postTimelineV1EventsByIdReactions: build.mutation<
        PostTimelineV1EventsByIdReactionsApiResponse,
        PostTimelineV1EventsByIdReactionsApiArg
      >({
        query: (queryArg) => ({
          url: `/timeline/v1/events/${queryArg.id}/reactions`,
          method: "POST",
          body: queryArg.reactionInput,
        }),
        invalidatesTags: ["timeline"],
      }),
      deleteTimelineV1EventsByIdReactionsAndReactionId: build.mutation<
        DeleteTimelineV1EventsByIdReactionsAndReactionIdApiResponse,
        DeleteTimelineV1EventsByIdReactionsAndReactionIdApiArg
      >({
        query: (queryArg) => ({
          url: `/timeline/v1/events/${queryArg.id}/reactions/${queryArg.reactionId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["timeline"],
      }),
      getErpTrackingCategoryV1: build.query<GetErpTrackingCategoryV1ApiResponse, GetErpTrackingCategoryV1ApiArg>({
        query: (queryArg) => ({ url: "/erp-tracking-category/v1/", params: { type: queryArg["type"] } }),
      }),
      postImageAssetV1ImageAssets: build.mutation<
        PostImageAssetV1ImageAssetsApiResponse,
        PostImageAssetV1ImageAssetsApiArg
      >({
        query: (queryArg) => ({ url: "/image-asset/v1/image-assets", method: "POST", body: queryArg.imageAssetInput }),
      }),
      getImageAssetV1ByIdContent: build.query<GetImageAssetV1ByIdContentApiResponse, GetImageAssetV1ByIdContentApiArg>({
        query: (queryArg) => ({ url: `/image-asset/v1/${queryArg.id}/content` }),
      }),
      getPlaidV1LinkToken: build.query<GetPlaidV1LinkTokenApiResponse, GetPlaidV1LinkTokenApiArg>({
        query: () => ({ url: "/plaid/v1/link-token" }),
      }),
      postPlaidV1Webhooks: build.mutation<PostPlaidV1WebhooksApiResponse, PostPlaidV1WebhooksApiArg>({
        query: (queryArg) => ({ url: "/plaid/v1/webhooks", method: "POST", body: queryArg.body }),
      }),
      getNotificationV1: build.query<GetNotificationV1ApiResponse, GetNotificationV1ApiArg>({
        query: (queryArg) => ({
          url: "/notification/v1/",
          params: { limit: queryArg.limit, until: queryArg.until, since: queryArg.since },
        }),
        providesTags: ["notifications"],
      }),
      getNotificationV1Tasks: build.query<GetNotificationV1TasksApiResponse, GetNotificationV1TasksApiArg>({
        query: () => ({ url: "/notification/v1/tasks" }),
        providesTags: ["notifications", "workflow-runs"],
      }),
      getNotificationV1Count: build.query<GetNotificationV1CountApiResponse, GetNotificationV1CountApiArg>({
        query: () => ({ url: "/notification/v1/count" }),
        providesTags: ["notifications"],
      }),
      getNotificationV1Unread: build.query<GetNotificationV1UnreadApiResponse, GetNotificationV1UnreadApiArg>({
        query: () => ({ url: "/notification/v1/unread" }),
        providesTags: ["notifications"],
      }),
      getNotificationV1ById: build.query<GetNotificationV1ByIdApiResponse, GetNotificationV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/notification/v1/${queryArg.id}` }),
        providesTags: ["notifications"],
      }),
      putNotificationV1ById: build.mutation<PutNotificationV1ByIdApiResponse, PutNotificationV1ByIdApiArg>({
        query: (queryArg) => ({
          url: `/notification/v1/${queryArg.id}`,
          method: "PUT",
          body: queryArg.notificationUpdate,
        }),
        invalidatesTags: ["notifications"],
      }),
      putNotificationV1Bulk: build.mutation<PutNotificationV1BulkApiResponse, PutNotificationV1BulkApiArg>({
        query: (queryArg) => ({
          url: "/notification/v1/bulk",
          method: "PUT",
          body: queryArg.notificationBulkActionBody,
        }),
        invalidatesTags: ["notifications"],
      }),
      postDynamoV1ByToolListingId: build.mutation<
        PostDynamoV1ByToolListingIdApiResponse,
        PostDynamoV1ByToolListingIdApiArg
      >({
        query: (queryArg) => ({ url: `/dynamo/v1/${queryArg.toolListingId}`, method: "POST" }),
        invalidatesTags: ["dynamo"],
      }),
      getDynamoV1RunByRunId: build.query<GetDynamoV1RunByRunIdApiResponse, GetDynamoV1RunByRunIdApiArg>({
        query: (queryArg) => ({ url: `/dynamo/v1/run/${queryArg.runId}` }),
        providesTags: ["dynamo"],
      }),
      getDynamoV1ByIdIsDynamoRunning: build.query<
        GetDynamoV1ByIdIsDynamoRunningApiResponse,
        GetDynamoV1ByIdIsDynamoRunningApiArg
      >({
        query: (queryArg) => ({ url: `/dynamo/v1/${queryArg.id}/is-dynamo-running` }),
        providesTags: ["dynamo"],
      }),
      postDynamoV1ByIdReportDataTelemetry: build.mutation<
        PostDynamoV1ByIdReportDataTelemetryApiResponse,
        PostDynamoV1ByIdReportDataTelemetryApiArg
      >({
        query: (queryArg) => ({
          url: `/dynamo/v1/${queryArg.id}/report-data/telemetry`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
      postRetoolV1DynamoRunLinearQaAgent: build.mutation<
        PostRetoolV1DynamoRunLinearQaAgentApiResponse,
        PostRetoolV1DynamoRunLinearQaAgentApiArg
      >({
        query: () => ({ url: "/retool/v1/dynamo/run-linear-qa-agent", method: "POST" }),
      }),
      postRetoolV1DynamoRunLinearQaAgentByIssueId: build.mutation<
        PostRetoolV1DynamoRunLinearQaAgentByIssueIdApiResponse,
        PostRetoolV1DynamoRunLinearQaAgentByIssueIdApiArg
      >({
        query: (queryArg) => ({ url: `/retool/v1/dynamo/run-linear-qa-agent/${queryArg.issueId}`, method: "POST" }),
      }),
      postRetoolV1DynamoRunComplianceAgent: build.mutation<
        PostRetoolV1DynamoRunComplianceAgentApiResponse,
        PostRetoolV1DynamoRunComplianceAgentApiArg
      >({
        query: (queryArg) => ({ url: "/retool/v1/dynamo/run-compliance-agent", method: "POST", body: queryArg.body }),
      }),
      postToolListingV1: build.mutation<PostToolListingV1ApiResponse, PostToolListingV1ApiArg>({
        query: (queryArg) => ({ url: "/tool-listing/v1/", method: "POST", body: queryArg.createToolListing }),
      }),
      putToolListingV1ById: build.mutation<PutToolListingV1ByIdApiResponse, PutToolListingV1ByIdApiArg>({
        query: (queryArg) => ({
          url: `/tool-listing/v1/${queryArg.id}`,
          method: "PUT",
          body: queryArg.updateToolListing,
        }),
      }),
      deleteToolListingV1ById: build.mutation<DeleteToolListingV1ByIdApiResponse, DeleteToolListingV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/tool-listing/v1/${queryArg.id}`, method: "DELETE", body: queryArg.body }),
      }),
      postToolListingV1ByIdReportData: build.mutation<
        PostToolListingV1ByIdReportDataApiResponse,
        PostToolListingV1ByIdReportDataApiArg
      >({
        query: (queryArg) => ({
          url: `/tool-listing/v1/${queryArg.id}/report_data`,
          method: "POST",
          body: queryArg.toolUserReport,
        }),
      }),
      postToolListingV1Keys: build.mutation<PostToolListingV1KeysApiResponse, PostToolListingV1KeysApiArg>({
        query: (queryArg) => ({ url: "/tool-listing/v1/keys", method: "POST", body: queryArg.body }),
      }),
      putToolListingV1ReplaceListing: build.mutation<
        PutToolListingV1ReplaceListingApiResponse,
        PutToolListingV1ReplaceListingApiArg
      >({
        query: (queryArg) => ({ url: "/tool-listing/v1/replace-listing", method: "PUT", body: queryArg.body }),
      }),
      putToolListingV1ByIdLogo: build.mutation<PutToolListingV1ByIdLogoApiResponse, PutToolListingV1ByIdLogoApiArg>({
        query: (queryArg) => ({ url: `/tool-listing/v1/${queryArg.id}/logo`, method: "PUT", body: queryArg.body }),
      }),
      postToolListingV1TransferLogin: build.mutation<
        PostToolListingV1TransferLoginApiResponse,
        PostToolListingV1TransferLoginApiArg
      >({
        query: (queryArg) => ({ url: "/tool-listing/v1/transfer-login", method: "POST", body: queryArg.body }),
      }),
      postToolListingV1TransferDescriptor: build.mutation<
        PostToolListingV1TransferDescriptorApiResponse,
        PostToolListingV1TransferDescriptorApiArg
      >({
        query: (queryArg) => ({ url: "/tool-listing/v1/transfer-descriptor", method: "POST", body: queryArg.body }),
      }),
      postToolListingV1UpdateListingLogos: build.mutation<
        PostToolListingV1UpdateListingLogosApiResponse,
        PostToolListingV1UpdateListingLogosApiArg
      >({
        query: () => ({ url: "/tool-listing/v1/update-listing-logos", method: "POST" }),
      }),
      postVendorListingV1: build.mutation<PostVendorListingV1ApiResponse, PostVendorListingV1ApiArg>({
        query: (queryArg) => ({ url: "/vendor-listing/v1/", method: "POST", body: queryArg.createVendorListing }),
      }),
      postVendorListingV1MarkSimilarityChecked: build.mutation<
        PostVendorListingV1MarkSimilarityCheckedApiResponse,
        PostVendorListingV1MarkSimilarityCheckedApiArg
      >({
        query: (queryArg) => ({
          url: "/vendor-listing/v1/mark-similarity-checked",
          method: "POST",
          body: queryArg.body,
        }),
      }),
      postVendorListingV1QueryVectors: build.mutation<
        PostVendorListingV1QueryVectorsApiResponse,
        PostVendorListingV1QueryVectorsApiArg
      >({
        query: (queryArg) => ({ url: "/vendor-listing/v1/query-vectors", method: "POST", body: queryArg.body }),
      }),
      putVendorListingV1ById: build.mutation<PutVendorListingV1ByIdApiResponse, PutVendorListingV1ByIdApiArg>({
        query: (queryArg) => ({
          url: `/vendor-listing/v1/${queryArg.id}`,
          method: "PUT",
          body: queryArg.updateVendorListing,
        }),
      }),
      deleteVendorListingV1ById: build.mutation<DeleteVendorListingV1ByIdApiResponse, DeleteVendorListingV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/vendor-listing/v1/${queryArg.id}`, method: "DELETE", body: queryArg.body }),
      }),
      postVendorListingV1ByIdReportData: build.mutation<
        PostVendorListingV1ByIdReportDataApiResponse,
        PostVendorListingV1ByIdReportDataApiArg
      >({
        query: (queryArg) => ({
          url: `/vendor-listing/v1/${queryArg.id}/report_data`,
          method: "POST",
          body: queryArg.vendorUserReport,
        }),
      }),
      postVendorListingV1Keys: build.mutation<PostVendorListingV1KeysApiResponse, PostVendorListingV1KeysApiArg>({
        query: (queryArg) => ({ url: "/vendor-listing/v1/keys", method: "POST", body: queryArg.body }),
      }),
      putVendorListingV1ReplaceListing: build.mutation<
        PutVendorListingV1ReplaceListingApiResponse,
        PutVendorListingV1ReplaceListingApiArg
      >({
        query: (queryArg) => ({ url: "/vendor-listing/v1/replace-listing", method: "PUT", body: queryArg.body }),
      }),
      putVendorListingV1ByIdLogo: build.mutation<
        PutVendorListingV1ByIdLogoApiResponse,
        PutVendorListingV1ByIdLogoApiArg
      >({
        query: (queryArg) => ({ url: `/vendor-listing/v1/${queryArg.id}/logo`, method: "PUT", body: queryArg.body }),
      }),
      getVendorListingV1FindWebsiteByListingType: build.query<
        GetVendorListingV1FindWebsiteByListingTypeApiResponse,
        GetVendorListingV1FindWebsiteByListingTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/vendor-listing/v1/find-website/${queryArg.listingType}`,
          params: { display_name: queryArg.displayName },
        }),
      }),
      postVendorListingV1TransferTool: build.mutation<
        PostVendorListingV1TransferToolApiResponse,
        PostVendorListingV1TransferToolApiArg
      >({
        query: (queryArg) => ({ url: "/vendor-listing/v1/transfer-tool", method: "POST", body: queryArg.body }),
      }),
      postVendorListingV1TransferDescriptor: build.mutation<
        PostVendorListingV1TransferDescriptorApiResponse,
        PostVendorListingV1TransferDescriptorApiArg
      >({
        query: (queryArg) => ({ url: "/vendor-listing/v1/transfer-descriptor", method: "POST", body: queryArg.body }),
      }),
      postVendorListingV1UpdateListingLogos: build.mutation<
        PostVendorListingV1UpdateListingLogosApiResponse,
        PostVendorListingV1UpdateListingLogosApiArg
      >({
        query: () => ({ url: "/vendor-listing/v1/update-listing-logos", method: "POST" }),
      }),
      getVendorListingV1DraftListingFromUrlByListingType: build.query<
        GetVendorListingV1DraftListingFromUrlByListingTypeApiResponse,
        GetVendorListingV1DraftListingFromUrlByListingTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/vendor-listing/v1/draft-listing-from-url/${queryArg.listingType}`,
          params: { url: queryArg.url },
        }),
      }),
      putTransactionVendorToolListingV1ByMerchantIdentification: build.mutation<
        PutTransactionVendorToolListingV1ByMerchantIdentificationApiResponse,
        PutTransactionVendorToolListingV1ByMerchantIdentificationApiArg
      >({
        query: (queryArg) => ({
          url: `/transaction-vendor-tool-listing/v1/${queryArg.merchantIdentification}`,
          method: "PUT",
          body: queryArg.body,
        }),
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as generatedApi }
export type GetOpenapiApiResponse = unknown
export type GetOpenapiApiArg = void
export type GetSchemaV1ByObjectTypeApiResponse = unknown
export interface GetSchemaV1ByObjectTypeApiArg {
  objectType: ObjectType
}
export type GetSchemaV1ByObjectTypePatchApiResponse = unknown
export interface GetSchemaV1ByObjectTypePatchApiArg {
  objectType: "Tool" | "Vendor"
}
export type GetSchemaV1ByObjectTypeInputApiResponse = unknown
export interface GetSchemaV1ByObjectTypeInputApiArg {
  objectType: "LegalAgreement"
}
export type GetSchemaV1ByObjectTypeFieldsApiResponse = /** status 200 Default Response */ FieldConfigsResponse
export interface GetSchemaV1ByObjectTypeFieldsApiArg {
  objectType: CustomizableObjectType
}
export type PostSchemaV1FieldsDuplicatesApiResponse = /** status 200 Default Response */ SimilarFieldResponse
export interface PostSchemaV1FieldsDuplicatesApiArg {
  similarFieldQuery: SimilarFieldQuery
}
export type PutSchemaV1ByObjectTypeFieldsAndFieldNameApiResponse = /** status 200 Default Response */ FieldConfigMinimal
export interface PutSchemaV1ByObjectTypeFieldsAndFieldNameApiArg {
  objectType: CustomizableObjectType
  fieldName: FieldName
  fieldConfigInput: FieldConfigInput
}
export type DeleteSchemaV1ByObjectTypeFieldsAndFieldNameApiResponse = unknown
export interface DeleteSchemaV1ByObjectTypeFieldsAndFieldNameApiArg {
  objectType: CustomizableObjectType
  fieldName: FieldName
}
export interface PostSchemaV1FieldsTextToFieldApiResponse {
  value: null | any
}
export interface PostSchemaV1FieldsTextToFieldApiArg {
  body: {
    source_text: NonEmptyString
    object_type: ObjectType
    field_name: string
    is_custom: boolean
  }
}
export type PostSchemaV1MapApiResponse = unknown
export interface PostSchemaV1MapApiArg {
  body: {
    object_types: ObjectType[]
  }
}
export type PostEmailV1InboundApiResponse = unknown
export interface PostEmailV1InboundApiArg {
  postmarkInboundMessage: PostmarkInboundMessage
}
export type GetUserV1WhoamiApiResponse = /** status 200 Default Response */ UserWithOrganization
export type GetUserV1WhoamiApiArg = void
export type GetUserV1SettingsApiResponse = /** status 200 Default Response */ UserSettings
export type GetUserV1SettingsApiArg = void
export type GetUserV1SettingsTotpQrApiResponse = unknown
export type GetUserV1SettingsTotpQrApiArg = void
export type GetUserV1EmailDelegatesApiResponse = /** status 200 Default Response */ UserMinimal[]
export type GetUserV1EmailDelegatesApiArg = void
export type PostUserV1EmailDelegatesApiResponse = unknown
export interface PostUserV1EmailDelegatesApiArg {
  body: {
    delegate_ids: string[]
  }
}
export type PostUserV1SettingsTotpEnableApiResponse = /** status 200 Default Response */ ApiResult
export interface PostUserV1SettingsTotpEnableApiArg {
  body: {
    token: NonEmptyTrimmedString
  }
}
export type PostUserV1SettingsTotpDisableApiResponse = /** status 200 Default Response */ ApiResult
export interface PostUserV1SettingsTotpDisableApiArg {
  body: {
    password: NonEmptyString
  }
}
export interface GetUserV1NotificationSettingsApiResponse {
  activeChannels: NotificationChannel[]
  settings: NotificationSetting[]
}
export type GetUserV1NotificationSettingsApiArg = void
export type PutUserV1NotificationSettingsApiResponse = unknown
export interface PutUserV1NotificationSettingsApiArg {
  body: UpdateNotificationSetting[]
}
export type PostUserV1DisableNotificationChannelApiResponse = unknown
export interface PostUserV1DisableNotificationChannelApiArg {
  body: {
    channel: NotificationChannel
  }
}
export type GetUserV1ByIdApiResponse = /** status 200 Default Response */ User
export interface GetUserV1ByIdApiArg {
  id: string
}
export type DeleteUserV1ByIdApiResponse = /** status 200 Default Response */ ApiResult
export interface DeleteUserV1ByIdApiArg {
  id: string
  body: {
    newOwnerId?: string
  }
}
export type PutUserV1ByIdApiResponse = /** status 200 Default Response */ User
export interface PutUserV1ByIdApiArg {
  id: string
  updateUser: UpdateUser
}
export interface GetUserV1DeletionRequirementsByIdApiResponse {
  requirement: null | ("replacement_owner" | "replacement_approver")
}
export interface GetUserV1DeletionRequirementsByIdApiArg {
  id: string
}
export type PostUserV1OrganizationApiResponse = /** status 200 Default Response */ UserOrganizationSummaryResponse
export interface PostUserV1OrganizationApiArg {
  body: {
    limit?: number
    offset?: number
    sort?: {
      by: UserOrganizationSummaryColumn
      direction: SortDirection
      nulls?: SortNulls
    }
    /**
        The inner array is an OR, the outer array is an AND so if we get passed in filter `A`, `B`, `C`:
        
        - `[[A, B], [C]]` is `(A OR B) AND C`
        - `[[A], [B], [C]]` is `(A) AND (B) AND (C)`
        - `[[A, B, C]]` is `(A OR B OR C)`
        
        TODO make this more flexible.
         */
    filter?: {
      /** The dot-separated path of the property to filter on.
            
            For arrays, a path segment can also be a wildcard `*` (e.g. `categories.*.id`), in which case the expression being filtered on is calculated by mapping over each element of the element (in the example, an array of IDs). */
      column: UserOrganizationSummaryColumn
      fields: FilterField
    }[][]
  }
}
export type PostUserV1CreateApiResponse = /** status 200 Default Response */ ApiResult
export interface PostUserV1CreateApiArg {
  body: {
    email: EmailString
    roles: Role[]
    first_name: NonEmptyTrimmedString
    last_name: NonEmptyTrimmedString
    organization_id: string
    admin_email: EmailString
  }
}
export type PostUserV1UpdateRolesApiResponse = /** status 200 Default Response */ ApiResult
export interface PostUserV1UpdateRolesApiArg {
  body: {
    id: string
    organization_id: string & string
    roles: Role[]
  }
}
export type PostUserV1InviteApiResponse = /** status 201 Default Response */ User
export interface PostUserV1InviteApiArg {
  inviteUser: InviteUser
}
export type PostUserV1ResendInviteApiResponse = /** status 200 Default Response */ ApiResult
export interface PostUserV1ResendInviteApiArg {
  body: {
    userId: string
  }
}
export type PostUserV1LoginApiResponse = /** status 200 Default Response */ LoginResult
export interface PostUserV1LoginApiArg {
  loginInput: LoginInput
}
export interface PostUserV1SigninOktaInitiateApiResponse {
  oktaDomain: HttpUrlString
}
export interface PostUserV1SigninOktaInitiateApiArg {
  body: {
    email: EmailString
  }
}
export type PostUserV1ForgotPasswordApiResponse = /** status 200 Default Response */ ApiResult
export interface PostUserV1ForgotPasswordApiArg {
  body: {
    email: string
  }
}
export type PostUserV1ChangePasswordApiResponse = /** status 200 Default Response */ ApiResult
export interface PostUserV1ChangePasswordApiArg {
  changePasswordInput: ChangePasswordInput
}
export type GetUserV1LogoutApiResponse = /** status 200 Default Response */ ApiResult
export type GetUserV1LogoutApiArg = void
export type PostUserV1CreateUserApiResponse = /** status 201 Default Response */ ApiResult
export interface PostUserV1CreateUserApiArg {
  createUserInput: CreateUserInput
}
export type GetOrganizationV1CurrentApiResponse = /** status 200 Default Response */ Organization
export type GetOrganizationV1CurrentApiArg = void
export type GetOrganizationV1CurrentOktaConfigApiResponse = /** status 200 Default Response */ OrganizationOktaSsoConfig
export type GetOrganizationV1CurrentOktaConfigApiArg = void
export type PutOrganizationV1UpdateApiResponse = /** status 200 Default Response */ Organization
export interface PutOrganizationV1UpdateApiArg {
  updateOrganization: UpdateOrganization
}
export type PostOrganizationV1CreateApiResponse = /** status 200 Default Response */ Organization
export interface PostOrganizationV1CreateApiArg {
  createOrganization: CreateOrganization
}
export type PostOrganizationV1ClearOrganizationApiResponse = /** status 200 Default Response */ ApiResult
export interface PostOrganizationV1ClearOrganizationApiArg {
  body: {
    organization_id: string
    delete_after?: boolean
    admin_email: EmailString
  }
}
export type PostOrganizationV1PickableEntitiesApiResponse = /** status 200 Default Response */ PickableEntity[]
export interface PostOrganizationV1PickableEntitiesApiArg {
  body: {
    filter: PickableEntityFilter
    limit?: LimitNumber
  }
}
export type PostOrganizationV1PermissionedPickableEntitiesApiResponse =
  /** status 200 Default Response */ PermissionedPickableEntity[]
export interface PostOrganizationV1PermissionedPickableEntitiesApiArg {
  body: {
    filter: PermissionedPickableEntityFilter
    limit?: LimitNumber
  }
}
export type GetSpendV1ByTypeAndIdApiResponse = /** status 200 Default Response */ SpendResponse
export interface GetSpendV1ByTypeAndIdApiArg {
  startDate: DateString
  endDate?: DateString
  /** Interval to group spend by, e.g. P1M for monthly, P1W for weekly, P1D for daily. Only accepts months, weeks, and days. */
  interval?: DateDurationString
  currencyCode: CurrencyCodeString
  id: string
  type: SpendQueryKind
}
export type GetStatisticsV1EmailIntegrationsApiResponse =
  /** status 200 Default Response */ AllEmailIntegrationStatistic
export type GetStatisticsV1EmailIntegrationsApiArg = void
export type GetStatisticsV1AgreementsApiResponse = /** status 200 Default Response */ AllAgreementStatistic
export type GetStatisticsV1AgreementsApiArg = void
export type GetStatisticsV1PersonsApiResponse = /** status 200 Default Response */ AllPersonStatistic
export type GetStatisticsV1PersonsApiArg = void
export type GetStatisticsV1ToolsApiResponse = /** status 200 Default Response */ AllToolStatistic
export type GetStatisticsV1ToolsApiArg = void
export type PostToolV1ListApiResponse = /** status 200 Default Response */ ToolListItemResponse
export interface PostToolV1ListApiArg {
  listQueryStringParams: ListQueryStringParams
}
export interface PostToolV1CsvApiResponse {
  url: string
}
export interface PostToolV1CsvApiArg {
  body: {
    limit?: number
    offset?: number
    sort?: {
      by: PropertyPathString
      direction: SortDirection
      nulls?: SortNulls
    }
    /**
        The inner array is an OR, the outer array is an AND so if we get passed in filter `A`, `B`, `C`:
        
        - `[[A, B], [C]]` is `(A OR B) AND C`
        - `[[A], [B], [C]]` is `(A) AND (B) AND (C)`
        - `[[A, B, C]]` is `(A OR B OR C)`
        
        TODO make this more flexible.
         */
    filter?: {
      /** The dot-separated path of the property to filter on.
            
            For arrays, a path segment can also be a wildcard `*` (e.g. `categories.*.id`), in which case the expression being filtered on is calculated by mapping over each element of the element (in the example, an array of IDs). */
      column: FilterPathString
      fields: FilterField
    }[][]
    columns?: FilterPathString[]
  }
}
export type PostToolV1AiFilterApiResponse = /** status 200 Default Response */ {
  /** The dot-separated path of the property to filter on.
    
    For arrays, a path segment can also be a wildcard `*` (e.g. `categories.*.id`), in which case the expression being filtered on is calculated by mapping over each element of the element (in the example, an array of IDs). */
  column: FilterPathString
  fields: FilterField
}[]
export interface PostToolV1AiFilterApiArg {
  body: {
    query: string
  }
}
export type GetToolV1PickerOptionsApiResponse = /** status 200 Default Response */ ToolOptionWithVendor[]
export interface GetToolV1PickerOptionsApiArg {
  search: string
  linkCode?: NonEmptyTrimmedString
}
export type PutToolV1ByIdFollowingApiResponse = unknown
export interface PutToolV1ByIdFollowingApiArg {
  id: string
  body: {
    following: boolean
  }
}
export type PatchToolV1ByIdApiResponse = /** status 200 Default Response */ Tool
export interface PatchToolV1ByIdApiArg {
  id: string
  toolPatch: ToolPatch
}
export type GetToolV1ByIdApiResponse = /** status 200 Default Response */ Tool2
export interface GetToolV1ByIdApiArg {
  id: string
}
export type PostToolV1ByIdLoginsListApiResponse = /** status 200 Default Response */ LoginSummaryResponse
export interface PostToolV1ByIdLoginsListApiArg {
  id: string
  listQueryStringParams: ListQueryStringParams
}
export type PostToolV1ByIdPersonsListApiResponse = /** status 200 Default Response */ PersonListItemResponse
export interface PostToolV1ByIdPersonsListApiArg {
  id: string
  listQueryStringParams: ListQueryStringParams
}
export interface PostToolV1ByIdPersonsCsvApiResponse {
  url: HttpUrlString
}
export interface PostToolV1ByIdPersonsCsvApiArg {
  id: string
  body: {
    limit?: number
    offset?: number
    sort?: {
      by: PropertyPathString
      direction: SortDirection
      nulls?: SortNulls
    }
    /**
        The inner array is an OR, the outer array is an AND so if we get passed in filter `A`, `B`, `C`:
        
        - `[[A, B], [C]]` is `(A OR B) AND C`
        - `[[A], [B], [C]]` is `(A) AND (B) AND (C)`
        - `[[A, B, C]]` is `(A OR B OR C)`
        
        TODO make this more flexible.
         */
    filter?: {
      /** The dot-separated path of the property to filter on.
            
            For arrays, a path segment can also be a wildcard `*` (e.g. `categories.*.id`), in which case the expression being filtered on is calculated by mapping over each element of the element (in the example, an array of IDs). */
      column: FilterPathString
      fields: FilterField
    }[][]
    columns?: PropertyPathString[]
  }
}
export type PostToolV1ApiResponse = /** status 200 Default Response */ ToolOptionWithVendor
export interface PostToolV1ApiArg {
  body: {
    user_provided_url: HttpUrlString
    user_provided_display_name: NonEmptyTrimmedString
  }
}
export type GetToolV1CategoriesApiResponse = /** status 200 Default Response */ ToolCategory[]
export type GetToolV1CategoriesApiArg = void
export type GetToolV1ByIdAndApiResponse = unknown
export interface GetToolV1ByIdAndApiArg {
  id: string
  "*": string
}
export type PostLegalV1AgreementsListApiResponse = /** status 200 Default Response */ LegalAgreementListResponse
export interface PostLegalV1AgreementsListApiArg {
  listQueryStringParams: ListQueryStringParams
}
export type GetLegalV1AgreementsPickerOptionsByQueryApiResponse =
  /** status 200 Default Response */ LegalAgreementWithToolsList
export interface GetLegalV1AgreementsPickerOptionsByQueryApiArg {
  query: string
}
export type GetLegalV1AgreementsByIdApiResponse = /** status 200 Default Response */ LegalAgreementDetails
export interface GetLegalV1AgreementsByIdApiArg {
  id: string
}
export type DeleteLegalV1AgreementsByIdApiResponse = unknown
export interface DeleteLegalV1AgreementsByIdApiArg {
  id: string
}
export type PostLegalV1AgreementsByIdVerificationApiResponse = /** status 200 Default Response */ LegalAgreement
export interface PostLegalV1AgreementsByIdVerificationApiArg {
  id: string
  agreementVerificationRequest: AgreementVerificationRequest
}
export type PostLegalV1DetectLegalAgreementsByOrganizationIdApiResponse = unknown
export interface PostLegalV1DetectLegalAgreementsByOrganizationIdApiArg {
  organizationId: string
}
export interface GetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlApiResponse {
  download_url: string
}
export interface GetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlApiArg {
  id: string
  documentId: string
}
export type GetLegalV1AgreementsByIdDocumentsAndDocumentIdContentApiResponse = unknown
export interface GetLegalV1AgreementsByIdDocumentsAndDocumentIdContentApiArg {
  id: string
  documentId: string
}
export type PostLegalV1AgreementsApiResponse = /** status 201 Default Response */ LegalAgreementDetails
export interface PostLegalV1AgreementsApiArg {
  legalAgreementInput: LegalAgreementInput
}
export type GetLegalV1AgreementsByIdAndApiResponse = unknown
export interface GetLegalV1AgreementsByIdAndApiArg {
  id: string
  "*": string
}
export type PostVendorV1ListApiResponse = /** status 200 Default Response */ VendorListItemResponse
export interface PostVendorV1ListApiArg {
  listQueryStringParams: ListQueryStringParams
}
export interface PostVendorV1CsvApiResponse {
  url: HttpUrlString
}
export interface PostVendorV1CsvApiArg {
  body: {
    limit?: number
    offset?: number
    sort?: {
      by: PropertyPathString
      direction: SortDirection
      nulls?: SortNulls
    }
    /**
        The inner array is an OR, the outer array is an AND so if we get passed in filter `A`, `B`, `C`:
        
        - `[[A, B], [C]]` is `(A OR B) AND C`
        - `[[A], [B], [C]]` is `(A) AND (B) AND (C)`
        - `[[A, B, C]]` is `(A OR B OR C)`
        
        TODO make this more flexible.
         */
    filter?: {
      /** The dot-separated path of the property to filter on.
            
            For arrays, a path segment can also be a wildcard `*` (e.g. `categories.*.id`), in which case the expression being filtered on is calculated by mapping over each element of the element (in the example, an array of IDs). */
      column: FilterPathString
      fields: FilterField
    }[][]
    columns?: PropertyPathString[]
  }
}
export type PostVendorV1AiFilterApiResponse = /** status 200 Default Response */ {
  /** The dot-separated path of the property to filter on.
    
    For arrays, a path segment can also be a wildcard `*` (e.g. `categories.*.id`), in which case the expression being filtered on is calculated by mapping over each element of the element (in the example, an array of IDs). */
  column: FilterPathString
  fields: FilterField
}[]
export interface PostVendorV1AiFilterApiArg {
  body: {
    query: string
  }
}
export type GetVendorV1PickerOptionsApiResponse = /** status 200 Default Response */ VendorOptionWithTools[]
export interface GetVendorV1PickerOptionsApiArg {
  search: string
}
export type PutVendorV1ByIdFollowingApiResponse = unknown
export interface PutVendorV1ByIdFollowingApiArg {
  id: string
  body: {
    following: boolean
  }
}
export type PatchVendorV1ByIdApiResponse = /** status 200 Default Response */ Vendor
export interface PatchVendorV1ByIdApiArg {
  id: string
  vendorPatch: VendorPatch
}
export type GetVendorV1ByIdApiResponse = /** status 200 Default Response */ VendorDetails
export interface GetVendorV1ByIdApiArg {
  id: string
}
export interface GetVendorV1ByIdSpendBreakdownApiResponse {
  tool_spend: {
    tool: {
      /** The name of the tool */
      display_name: NonEmptyTrimmedString
      id: string
    }
    spend: CurrencyAmount
  }[]
  non_tool_spend: null | CurrencyAmount
}
export interface GetVendorV1ByIdSpendBreakdownApiArg {
  currencyCode: CurrencyCodeString
  startDate: DateString
  endDate: DateString
  id: string
}
export type GetVendorV1ByIdAndApiResponse = unknown
export interface GetVendorV1ByIdAndApiArg {
  id: string
  "*": string
}
export type GetOauthV1SigninByProviderApiResponse = unknown
export interface GetOauthV1SigninByProviderApiArg {
  linkAuthCode?: NonEmptyTrimmedString
  iss?: HttpUrlString
  provider: "google" | "okta"
}
export type GetOauthV1SigninByProviderCallbackApiResponse = unknown
export interface GetOauthV1SigninByProviderCallbackApiArg {
  state: string
  code: string
  provider: "google" | "okta"
}
export type GetOauthV1AuthByProviderApiResponse = unknown
export interface GetOauthV1AuthByProviderApiArg {
  provider: "ramp_oauth" | "google_oauth" | "gmail_oauth" | "brex_oauth" | "quickbooks_oauth" | "slack_oauth"
}
export type GetOauthV1AuthByProviderCallbackApiResponse = unknown
export interface GetOauthV1AuthByProviderCallbackApiArg {
  state: string
  code?: string
  provider: "ramp_oauth" | "google_oauth" | "gmail_oauth" | "brex_oauth" | "quickbooks_oauth" | "slack_oauth"
}
export type GetOauthV1ConnectByProviderApiResponse = unknown
export interface GetOauthV1ConnectByProviderApiArg {
  integrationId?: string
  provider: "ramp_oauth" | "google_oauth" | "gmail_oauth" | "brex_oauth" | "quickbooks_oauth" | "slack_oauth"
}
export type GetOauthV1ConnectByProviderCallbackApiResponse = unknown
export interface GetOauthV1ConnectByProviderCallbackApiArg {
  state: string
  code?: string
  integrationId?: string
  realmId?: string
  provider: "ramp_oauth" | "google_oauth" | "gmail_oauth" | "brex_oauth" | "quickbooks_oauth" | "slack_oauth"
}
export type GetIntegrationV1ApiResponse = /** status 200 Default Response */ {
  id: string
  object_type: "Integration"
  organization_id: string
  created_at: DateTimeString
  display_name: NonEmptyTrimmedProseString
  provider: IntegrationProvider
  external_id: null | NonEmptyString
  merge_hris_type: null | MergeIntegrationHrisType
  merge_accounting_type: null | MergeIntegrationAccountingType
  logo: null | NonEmptyString
  email: null | NonEmptyString
  crawler_last_success: null | DateTimeString
  crawler_state?: null | {
    oldest_crawled_activity_date?: DateTimeString
    incremental_crawl_start_date?: DateTimeString
  }
  /** Text with statistics on the integration. For example, how many documents have been imported from an email integration. */
  detail_text?: string
  status: IntegrationStatus
  disabled_at: null | DateTimeString
}[]
export type GetIntegrationV1ApiArg = void
export type GetIntegrationV1PrivateApiResponse = /** status 200 Default Response */ {
  id: string
  object_type: "Integration"
  organization_id: string
  created_at: DateTimeString
  display_name: NonEmptyTrimmedProseString
  provider: IntegrationProvider
  external_id: null | NonEmptyString
  merge_hris_type: null | MergeIntegrationHrisType
  merge_accounting_type: null | MergeIntegrationAccountingType
  logo: null | NonEmptyString
  email: null | NonEmptyString
  crawler_last_success: null | DateTimeString
  crawler_state?: null | {
    oldest_crawled_activity_date?: DateTimeString
    incremental_crawl_start_date?: DateTimeString
  }
  /** Text with statistics on the integration. For example, how many documents have been imported from an email integration. */
  detail_text?: string
  status: IntegrationStatus
  disabled_at: null | DateTimeString
}[]
export type GetIntegrationV1PrivateApiArg = void
export type DeleteIntegrationV1ByIdApiResponse = /** status 200 Default Response */ ApiResult
export interface DeleteIntegrationV1ByIdApiArg {
  data?: "DELETE"
  id: string
}
export type PostIntegrationV1ClearDataApiResponse = /** status 200 Default Response */ ApiResult
export interface PostIntegrationV1ClearDataApiArg {
  body: {
    organization_id: string
    integration_id: string
    delete_after?: boolean
    admin_email: EmailString
  }
}
export type PostIntegrationV1CreateApiResponse = /** status 200 Default Response */ ApiResult
export interface PostIntegrationV1CreateApiArg {
  createIntegration: CreateIntegration
}
export type PostIntegrationV1ByIdDisableApiResponse = /** status 200 Default Response */ ApiResult
export interface PostIntegrationV1ByIdDisableApiArg {
  id: string
}
export type PostIntegrationV1ByIdEnableApiResponse = /** status 200 Default Response */ ApiResult
export interface PostIntegrationV1ByIdEnableApiArg {
  id: string
}
export type PostIntegrationV1UpdateApiResponse = /** status 200 Default Response */ ApiResult
export interface PostIntegrationV1UpdateApiArg {
  updateIntegration: UpdateIntegration
}
export type PostIntegrationV1CrawlByIdApiResponse = unknown
export interface PostIntegrationV1CrawlByIdApiArg {
  id: string
}
export type GetIntegrationV1HrisBySourceLinkTokenApiResponse = /** status 200 Default Response */ LinkTokenResponse
export interface GetIntegrationV1HrisBySourceLinkTokenApiArg {
  source:
    | "bamboohr"
    | "justworks"
    | "gusto"
    | "rippling"
    | "hibob"
    | "workday"
    | "paylocity"
    | "ukg-pro"
    | "ukg-ready"
    | "ukg-pro-workforce-management"
    | "adp-workforce-now"
    | "deel"
    | "namely"
    | "zenefits"
    | "charthop"
}
export type GetIntegrationV1AccountingBySourceLinkTokenApiResponse =
  /** status 200 Default Response */ LinkTokenResponse
export interface GetIntegrationV1AccountingBySourceLinkTokenApiArg {
  source: "netsuite" | "quickbooks-online" | "sage-intacct"
}
export type PostIntegrationV1MergeSyncApiResponse = unknown
export interface PostIntegrationV1MergeSyncApiArg {
  mergeWebhookPayload: MergeWebhookPayload
}
export type PostPersonV1ApiResponse = /** status 200 Default Response */ PersonListItemResponse
export interface PostPersonV1ApiArg {
  listQueryStringParams: ListQueryStringParams
}
export type PostPersonV1MergeApiResponse = unknown
export interface PostPersonV1MergeApiArg {
  body: {
    merged_person_id: string
    replacement_person_id: string
    organization_id: string
  }
}
export type GetPersonV1ByIdApiResponse = /** status 200 Default Response */ PersonDetails
export interface GetPersonV1ByIdApiArg {
  id: string
}
export type GetPersonV1ByIdToolsApiResponse = /** status 200 Default Response */ {
  id: string
  display_name: NonEmptyTrimmedString
  last_login: null | DateTimeString
  image_asset: null | ImageAsset
}[]
export interface GetPersonV1ByIdToolsApiArg {
  id: string
}
export type GetLoginV1SummaryApiResponse = /** status 200 Default Response */ LoginScopeSummary
export interface GetLoginV1SummaryApiArg {
  personId?: string
}
export type GetLoginV1UserActivityApiResponse = /** status 200 Default Response */ LoginUserActivityResponse
export interface GetLoginV1UserActivityApiArg {
  toolListingId: string
  startDate: DateString
  endDate?: DateString
  /** Interval to group by, e.g. P1M for monthly, P1W for weekly, P1D for daily. Only accepts months, weeks, and days. */
  interval: DateDurationString
}
export type GetSearchV1TextApiResponse = /** status 200 Default Response */ SearchResponse
export interface GetSearchV1TextApiArg {
  q: string
}
export type GetEmployeeGroupV1ApiResponse = /** status 200 Default Response */ {
  id: string
  object_type: "EmployeeGroup"
  display_name: null | NonEmptyTrimmedString
  group_type: null | EmployeeGroupType
}[]
export interface GetEmployeeGroupV1ApiArg {
  groupType?: "team" | "department" | "cost_center" | "business_unit" | "group"
}
export type PostDocumentV1ApiResponse = /** status 201 Default Response */ CreateDocumentResponse
export interface PostDocumentV1ApiArg {
  documentInput: DocumentInput
}
export type PutDocumentV1ByIdApiResponse = /** status 200 Default Response */ DocumentMinimal
export interface PutDocumentV1ByIdApiArg {
  id: string
  body: {
    status: "uploaded"
    link_code?: NonEmptyTrimmedString
  }
}
export type GetDocumentV1ExtractingApiResponse = /** status 200 Default Response */ ExtractingDocumentResponse
export type GetDocumentV1ExtractingApiArg = void
export type PostDocumentV1BackgroundExtractApiResponse = unknown
export interface PostDocumentV1BackgroundExtractApiArg {
  body: {
    document_ids: string[]
    legal_agreement_id?: string
    extraction_type: DocumentExtractionType
  }
}
export type PostDocumentV1MoveToAgreementApiResponse = /** status 200 Default Response */ LegalAgreementMinimal
export interface PostDocumentV1MoveToAgreementApiArg {
  moveDocumentRequest: MoveDocumentRequest
}
export type PostDocumentV1ByIdBackgroundExtractApiResponse =
  /** status 200 Default Response */ DocumentBackgroundExtractionResponse
export interface PostDocumentV1ByIdBackgroundExtractApiArg {
  id: string
  documentBackgroundExtractionRequest: DocumentBackgroundExtractionRequest
}
export type PostDocumentV1RetryPartialExtractionApiResponse = unknown
export interface PostDocumentV1RetryPartialExtractionApiArg {
  body: {
    target_extraction_status: DocumentExtractionStatus
    organization_ids?: string[]
    extraction_type: DocumentExtractionType[]
    document_ids?: string[]
  }
}
export type PostDocumentV1ByIdRetryExtractionApiResponse =
  /** status 200 Default Response */ DocumentBackgroundExtractionResponse
export interface PostDocumentV1ByIdRetryExtractionApiArg {
  id: string
}
export type PostDocumentV1ByIdExtractFormApiResponse = /** status 200 Default Response */ ExtractedFieldConfigsResponse
export interface PostDocumentV1ByIdExtractFormApiArg {
  id: string
}
export type GetDocumentV1OwnByIdContentApiResponse = unknown
export interface GetDocumentV1OwnByIdContentApiArg {
  id: string
}
export interface GetDocumentV1OwnByIdUrlApiResponse {
  download_url: string
}
export interface GetDocumentV1OwnByIdUrlApiArg {
  id: string
}
export type GetWorkflowV1ByKindSchemaApiResponse = unknown
export interface GetWorkflowV1ByKindSchemaApiArg {
  kind: WorkflowKind
}
export type GetWorkflowV1DefinitionsByIdApiResponse = /** status 200 Default Response */ WorkflowDef
export interface GetWorkflowV1DefinitionsByIdApiArg {
  id: string
}
export type PutWorkflowV1DefinitionsByIdApiResponse = /** status 200 Default Response */ WorkflowDef
export interface PutWorkflowV1DefinitionsByIdApiArg {
  id: string
  workflowDefUpdate: WorkflowDefUpdate
}
export type GetWorkflowV1DefinitionsRenewalApiResponse = /** status 200 Default Response */ WorkflowDef
export type GetWorkflowV1DefinitionsRenewalApiArg = void
export type GetWorkflowV1DefinitionsPurchaseApiResponse = /** status 200 Default Response */ WorkflowDef
export type GetWorkflowV1DefinitionsPurchaseApiArg = void
export type GetWorkflowV1DefinitionsApiResponse = /** status 200 Default Response */ WorkflowDef[]
export type GetWorkflowV1DefinitionsApiArg = void
export type PostWorkflowV1DefinitionsApiResponse = /** status 201 Default Response */ WorkflowDef
export interface PostWorkflowV1DefinitionsApiArg {
  workflowDefInput: WorkflowDefInput
}
export type GetWorkflowV1RunsPickablesApiResponse = /** status 200 Default Response */ WorkflowRunMinimal[]
export type GetWorkflowV1RunsPickablesApiArg = void
export type PostWorkflowV1DefinitionsConditionalApiResponse =
  /** status 200 Default Response */ WorkflowConditionGroupInput
export interface PostWorkflowV1DefinitionsConditionalApiArg {
  body: {
    prompt: NonEmptyTrimmedProseString
    eligible_fields: FormFieldConfig[]
  }
}
export type PostWorkflowV1RunsListApiResponse = /** status 200 Default Response */ WorkflowRunListResponse
export interface PostWorkflowV1RunsListApiArg {
  listQueryStringParams: ListQueryStringParams
}
export type PostWorkflowV1RunsApiResponse = /** status 200 Default Response */ WorkflowRun
export interface PostWorkflowV1RunsApiArg {
  workflowRunInput: WorkflowRunInput
}
export type GetWorkflowV1RunsByIdApiResponse = /** status 200 Default Response */ WorkflowRun
export interface GetWorkflowV1RunsByIdApiArg {
  id: string
}
export type PatchWorkflowV1RunsByIdApiResponse = /** status 200 Default Response */ WorkflowRun
export interface PatchWorkflowV1RunsByIdApiArg {
  id: string
  workflowRunUpdatePatch: WorkflowRunUpdatePatch
}
export type DeleteWorkflowV1RunsByIdApiResponse = unknown
export interface DeleteWorkflowV1RunsByIdApiArg {
  id: string
}
export type PutWorkflowV1RunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameGathererApiResponse =
  /** status 200 Default Response */ WorkflowRun
export interface PutWorkflowV1RunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameGathererApiArg {
  id: string
  objectType:
    | "Document"
    | "EmployeeGroup"
    | "ErpTrackingCategory"
    | "Integration"
    | "LegalAgreement"
    | "Link"
    | "Login"
    | "Person"
    | "Receipt"
    | "ReconciledTransaction"
    | "Tool"
    | "ToolCategory"
    | "ToolListing"
    | "User"
    | "Vendor"
    | "VendorListing"
    | "WorkflowRun"
    | "WorkflowRunApprover"
    | "WorkflowRunStep"
  objectId: string
  fieldName: string
  setFieldGatherRequest: SetFieldGatherRequest
}
export type PostWorkflowV1RunsByIdCancelApiResponse = unknown
export interface PostWorkflowV1RunsByIdCancelApiArg {
  id: string
}
export type PatchWorkflowV1RunsByIdStepsApiResponse = /** status 200 Default Response */ WorkflowRunSteps
export interface PatchWorkflowV1RunsByIdStepsApiArg {
  id: string
  workflowRunStepsUpdatePatch: WorkflowRunStepsUpdatePatch
}
export type PutWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApprovalApiResponse =
  /** status 200 Default Response */ WorkflowRunStepWithContext
export interface PutWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApprovalApiArg {
  id: string
  objectType: ObjectType
  objectId: string
  fieldName: NonEmptyTrimmedString
  workflowRunStepFieldApprovalStatus: WorkflowRunStepFieldApprovalStatus
}
export type GetWorkflowV1SellerLinksApiResponse = /** status 200 Default Response */ SellerLinksResponse
export type GetWorkflowV1SellerLinksApiArg = void
export type PostWorkflowV1LinksApiResponse = /** status 201 Default Response */ BrmLinkMinimal
export interface PostWorkflowV1LinksApiArg {
  createBrmLinkInput: CreateBrmLinkInput
}
export type GetWorkflowV1LinksByCodeWorkflowRunApiResponse = /** status 200 Default Response */ WorkflowRun
export interface GetWorkflowV1LinksByCodeWorkflowRunApiArg {
  code: NonEmptyTrimmedString
}
export type PostWorkflowV1LinksByCodeWorkflowRunStepStatesAndWorkflowRunStepIdApiResponse =
  /** status 200 Default Response */ WorkflowRun
export interface PostWorkflowV1LinksByCodeWorkflowRunStepStatesAndWorkflowRunStepIdApiArg {
  code: NonEmptyTrimmedString
  workflowRunStepId: string
  workflowRunStepDraftStateUpdateInput: WorkflowRunStepDraftStateUpdateInput
}
export type GetWorkflowV1LinksByCodeApiResponse = /** status 200 Default Response */ BrmLink
export interface GetWorkflowV1LinksByCodeApiArg {
  code: NonEmptyTrimmedString
}
export type PostWorkflowV1LinksByCodeApiResponse = /** status 201 Default Response */ BrmLinkMinimal
export interface PostWorkflowV1LinksByCodeApiArg {
  code: NonEmptyTrimmedString
  createBrmLinkInput: CreateBrmLinkInput
}
export type PostWorkflowV1StepRunsByIdDraftApiResponse = /** status 200 Default Response */ ApiResult
export interface PostWorkflowV1StepRunsByIdDraftApiArg {
  id: string
  workflowRunStepDraftStateUpdateInput: WorkflowRunStepDraftStateUpdateInput
}
export interface PostWorkflowV1StepRunsByWorkflowRunStepIdUnapproveApiResponse {
  id: string
  status: WorkflowRunStepStatus
  display_name: NonEmptyTrimmedProseString
}
export interface PostWorkflowV1StepRunsByWorkflowRunStepIdUnapproveApiArg {
  workflowRunStepId: string
  body: {
    reason: RichText
  }
}
export interface PostWorkflowV1StepRunsByWorkflowRunStepIdRequestChangesApiResponse {
  id: string
  status: WorkflowRunStepStatus
  display_name: NonEmptyTrimmedProseString
}
export interface PostWorkflowV1StepRunsByWorkflowRunStepIdRequestChangesApiArg {
  workflowRunStepId: string
}
export interface PostWorkflowV1StepRunsByWorkflowRunStepIdSubmissionApiResponse {
  workflow_run: {
    id: string
    display_name: NonEmptyTrimmedProseString
    status: WorkflowRunStatus
  }
  workflow_run_step: {
    id: string
    status: WorkflowRunStepStatus
    display_name: NonEmptyTrimmedProseString
    approval_steps: WorkflowRunApprovalStep[]
  }
}
export interface PostWorkflowV1StepRunsByWorkflowRunStepIdSubmissionApiArg {
  workflowRunStepId: string
  workflowStepRunSubmission: WorkflowStepRunSubmission
}
export interface GetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdUrlApiResponse {
  download_url: string
}
export interface GetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdUrlApiArg {
  workflowRunId: string
  documentId: string
}
export type GetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdContentApiResponse = unknown
export interface GetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdContentApiArg {
  workflowRunId: string
  documentId: NonEmptyTrimmedString
}
export type GetTransactionV1ByIdApiResponse = /** status 200 Default Response */ Transaction
export interface GetTransactionV1ByIdApiArg {
  id: string
}
export type PostTransactionV1ListApiResponse = /** status 200 Default Response */ TransactionSummaryResponse
export interface PostTransactionV1ListApiArg {
  toolId?: string
  vendorId?: string
  listQueryStringParams: ListQueryStringParams
}
export interface GetTransactionV1ByIdReceiptsAndReceiptIdDocumentUrlApiResponse {
  download_url: string
}
export interface GetTransactionV1ByIdReceiptsAndReceiptIdDocumentUrlApiArg {
  id: string
  receiptId: string
}
export type GetTransactionV1ByIdReceiptsAndReceiptIdDocumentContentApiResponse = unknown
export interface GetTransactionV1ByIdReceiptsAndReceiptIdDocumentContentApiArg {
  id: string
  receiptId: string
}
export type GetSavedViewV1ByTableIdentifierApiResponse = /** status 200 Default Response */ SavedView[]
export interface GetSavedViewV1ByTableIdentifierApiArg {
  tableIdentifier: TableIdentifier
}
export type PutSavedViewV1ByIdApiResponse = /** status 200 Default Response */ SavedView
export interface PutSavedViewV1ByIdApiArg {
  id: string
  editSavedView: EditSavedView
}
export type DeleteSavedViewV1ByIdApiResponse = /** status 200 Default Response */ SavedView
export interface DeleteSavedViewV1ByIdApiArg {
  id: string
}
export type PostSavedViewV1CreateApiResponse = /** status 200 Default Response */ SavedView
export interface PostSavedViewV1CreateApiArg {
  createSavedView: CreateSavedView
}
export type PostBetsyV1VectorApiResponse = /** status 200 Default Response */ {
  id: string
  text: string
  tool?: any
  vendor?: any
  transaction?: any
  person?: any
  user?: any
  employee_group?: any
  similarity: number
}[]
export interface PostBetsyV1VectorApiArg {
  body: {
    query: string
  }
}
export type PostBetsyV1AskApiResponse = unknown
export interface PostBetsyV1AskApiArg {
  body: {
    query: RichText
    messages: Message[]
    conversation_id: string
    log_message?: boolean
    new_betsy?: boolean
  }
}
export interface PostBetsyV1SlackEventApiResponse {
  challenge: string
}
export interface PostBetsyV1SlackEventApiArg {
  body: {
    type: "url_verification" | "event_callback"
    challenge?: string
    event?: {
      type: "app_mention" | "message"
      subtype?: "message_changed"
      channel: string
      bot_id?: string
      user?: string
      text?: string
      ts: string
      thread_ts?: string
    }
  }
}
export type GetBetsyV1ConversationsApiResponse = /** status 200 Default Response */ {
  id: string
  display_name: string
  created_at: DateTimeString
}[]
export type GetBetsyV1ConversationsApiArg = void
export interface GetBetsyV1ConversationsByIdApiResponse {
  id: string
  user_id: string
  user: UserMinimal
  display_name: string
  created_at: DateTimeString
  messages: Message[]
}
export interface GetBetsyV1ConversationsByIdApiArg {
  id: string
}
export type PutErpAccountV1ByIdApiResponse = /** status 200 Default Response */ ApiResult
export interface PutErpAccountV1ByIdApiArg {
  id: string
  body: {
    hidden: boolean
  }
}
export type GetTimelineV1EventsApiResponse = /** status 200 Default Response */ PaginatedTimelineResponseSchema
export interface GetTimelineV1EventsApiArg {
  limit?: LimitNumber
  workflowRunId?: string
  toolId?: string
  vendorId?: string
  /** ISO datetime string to return events until (i.e. `created_at < until`). Cannot be used in combination with `since`. */
  until?: string
  /** ISO datetime string to return events since (i.e. `created_at > since`). Cannot be used in combination with `until`. */
  since?: string
}
export type DeleteTimelineV1EventsByIdApiResponse = unknown
export interface DeleteTimelineV1EventsByIdApiArg {
  id: string
}
export type PutTimelineV1EventsByIdApiResponse = unknown
export interface PutTimelineV1EventsByIdApiArg {
  id: string
  timelineUpdate: TimelineUpdate
}
export type GetTimelineV1WorkflowRunByWorkflowRunIdApiResponse =
  /** status 200 Default Response */ GetWorkflowRunFieldTimelineResponse
export interface GetTimelineV1WorkflowRunByWorkflowRunIdApiArg {
  workflowRunId: string
}
export type GetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdApiResponse =
  /** status 200 Default Response */ GetWorkflowRunFieldTimelineResponse
export interface GetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdApiArg {
  workflowRunStepId: string
  workflowRunId: string
}
export type GetTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldNameApiResponse =
  /** status 200 Default Response */ GetWorkflowRunFieldTimelineResponse
export interface GetTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldNameApiArg {
  workflowRunId?: string
  objectId: string
  objectType: ObjectType
  fieldName: NonEmptyTrimmedString
}
export type GetTimelineV1EventsByIdDocumentContentApiResponse = unknown
export interface GetTimelineV1EventsByIdDocumentContentApiArg {
  id: string
}
export type PostTimelineV1EventsObjectsByObjectTypeAndObjectIdApiResponse = unknown
export interface PostTimelineV1EventsObjectsByObjectTypeAndObjectIdApiArg {
  fieldName?: NonEmptyTrimmedString
  workflowRunId?: string
  workflowRunStepId?: string
  objectId: string
  objectType: ObjectType
  timelineInput: TimelineInput
}
export type PostTimelineV1EventsByIdReactionsApiResponse = unknown
export interface PostTimelineV1EventsByIdReactionsApiArg {
  id: string
  reactionInput: ReactionInput
}
export type DeleteTimelineV1EventsByIdReactionsAndReactionIdApiResponse = unknown
export interface DeleteTimelineV1EventsByIdReactionsAndReactionIdApiArg {
  id: string
  reactionId: string
}
export type GetErpTrackingCategoryV1ApiResponse = /** status 200 Default Response */ ErpTrackingCategory[]
export interface GetErpTrackingCategoryV1ApiArg {
  type?: "department" | "class"
}
export type PostImageAssetV1ImageAssetsApiResponse = /** status 201 Default Response */ UploadableImageAsset
export interface PostImageAssetV1ImageAssetsApiArg {
  imageAssetInput: ImageAssetInput
}
export type GetImageAssetV1ByIdContentApiResponse = unknown
export interface GetImageAssetV1ByIdContentApiArg {
  id: string
}
export interface GetPlaidV1LinkTokenApiResponse {
  link_token: string
}
export type GetPlaidV1LinkTokenApiArg = void
export type PostPlaidV1WebhooksApiResponse = /** status 200 Default Response */ ApiResult
export interface PostPlaidV1WebhooksApiArg {
  body: {
    webhook_type: "AUTH" | "HOLDINGS" | "INVESTMENTS_TRANSACTIONS" | "ITEM" | "LIABILITIES" | "TRANSACTIONS" | "ASSETS"
    item_id: string
    webhook_code: string
    environment: string
  }
}
export type GetNotificationV1ApiResponse = /** status 200 Default Response */ NotificationListResponse
export interface GetNotificationV1ApiArg {
  limit: LimitNumber
  /** ISO datetime string to return events until (i.e. `created_at < until`). Cannot be used in combination with `since`. */
  until?: string
  /** ISO datetime string to return events since (i.e. `created_at > since`). Cannot be used in combination with `until`. */
  since?: string
}
export type GetNotificationV1TasksApiResponse = /** status 200 Default Response */ TaskListResponse
export type GetNotificationV1TasksApiArg = void
export type GetNotificationV1CountApiResponse = /** status 200 Default Response */ NotificationCountResponse
export type GetNotificationV1CountApiArg = void
export type GetNotificationV1UnreadApiResponse = /** status 200 Default Response */ NotificationUnreadResponse
export type GetNotificationV1UnreadApiArg = void
export type GetNotificationV1ByIdApiResponse = /** status 200 Default Response */ InboxNotification
export interface GetNotificationV1ByIdApiArg {
  id: string
}
export type PutNotificationV1ByIdApiResponse = /** status 200 Default Response */ InboxNotification
export interface PutNotificationV1ByIdApiArg {
  id: string
  notificationUpdate: NotificationUpdate
}
export type PutNotificationV1BulkApiResponse = /** status 200 Default Response */ ApiResult
export interface PutNotificationV1BulkApiArg {
  notificationBulkActionBody: NotificationBulkActionBody
}
export interface PostDynamoV1ByToolListingIdApiResponse {
  run_id: string
}
export interface PostDynamoV1ByToolListingIdApiArg {
  toolListingId: string
}
export type GetDynamoV1RunByRunIdApiResponse = /** status 200 Default Response */ DynamoRun
export interface GetDynamoV1RunByRunIdApiArg {
  runId: string
}
export type GetDynamoV1ByIdIsDynamoRunningApiResponse = /** status 200 Default Response */ IsDynamoRunning
export interface GetDynamoV1ByIdIsDynamoRunningApiArg {
  id: string
}
export type PostDynamoV1ByIdReportDataTelemetryApiResponse = unknown
export interface PostDynamoV1ByIdReportDataTelemetryApiArg {
  id: string
  body: {
    report_body: string
    dynamo_run_id: string
  }
}
export type PostRetoolV1DynamoRunLinearQaAgentApiResponse = unknown
export type PostRetoolV1DynamoRunLinearQaAgentApiArg = void
export type PostRetoolV1DynamoRunLinearQaAgentByIssueIdApiResponse = unknown
export interface PostRetoolV1DynamoRunLinearQaAgentByIssueIdApiArg {
  issueId: NonEmptyString
}
export type PostRetoolV1DynamoRunComplianceAgentApiResponse = unknown
export interface PostRetoolV1DynamoRunComplianceAgentApiArg {
  body: {
    tool_listing_id: string
  }[]
}
export type PostToolListingV1ApiResponse = /** status 200 Default Response */ ApiResult
export interface PostToolListingV1ApiArg {
  createToolListing: CreateToolListing
}
export type PutToolListingV1ByIdApiResponse = /** status 200 Default Response */ ApiResult
export interface PutToolListingV1ByIdApiArg {
  id: string
  updateToolListing: UpdateToolListing
}
export type DeleteToolListingV1ByIdApiResponse = /** status 200 Default Response */ ApiResult
export interface DeleteToolListingV1ByIdApiArg {
  id: string
  body: {
    admin_email: EmailString
  }
}
export type PostToolListingV1ByIdReportDataApiResponse = unknown
export interface PostToolListingV1ByIdReportDataApiArg {
  id: string
  toolUserReport: ToolUserReport
}
export type PostToolListingV1KeysApiResponse = /** status 201 Default Response */ ApiResult
export interface PostToolListingV1KeysApiArg {
  body: {
    key: string
    tool_listing_id: string
    admin_email: EmailString
  }
}
export type PutToolListingV1ReplaceListingApiResponse = /** status 200 Default Response */ ApiResult
export interface PutToolListingV1ReplaceListingApiArg {
  body: {
    gobbler_tool_listing_id: string
    food_tool_listing_id: string
    admin_email: EmailString
  }
}
export type PutToolListingV1ByIdLogoApiResponse = /** status 200 Default Response */ ApiResult
export interface PutToolListingV1ByIdLogoApiArg {
  id: string
  body: {
    file_data: string
    mime_type: string
    admin_email: EmailString
  }
}
export type PostToolListingV1TransferLoginApiResponse = /** status 200 Default Response */ ApiResult
export interface PostToolListingV1TransferLoginApiArg {
  body: {
    gobblerId: string
    foodId: string
    login: LoginIdentifier
    admin_email: EmailString
  }
}
export type PostToolListingV1TransferDescriptorApiResponse = /** status 200 Default Response */ ApiResult
export interface PostToolListingV1TransferDescriptorApiArg {
  body: {
    gobblerId: string
    foodId: string
    descriptor: string
    admin_email: EmailString
  }
}
export type PostToolListingV1UpdateListingLogosApiResponse = /** status 200 Default Response */ ApiResult
export type PostToolListingV1UpdateListingLogosApiArg = void
export type PostVendorListingV1ApiResponse = /** status 200 Default Response */ ApiResult
export interface PostVendorListingV1ApiArg {
  createVendorListing: CreateVendorListing
}
export type PostVendorListingV1MarkSimilarityCheckedApiResponse = unknown
export interface PostVendorListingV1MarkSimilarityCheckedApiArg {
  body: {
    primary_vendor_listing_id: string
    similar_vendor_listing_id: string
    admin_email: EmailString
  }
}
export type PostVendorListingV1QueryVectorsApiResponse = unknown
export interface PostVendorListingV1QueryVectorsApiArg {
  body: {
    query: string
    admin_email: EmailString
  }
}
export type PutVendorListingV1ByIdApiResponse = /** status 200 Default Response */ ApiResult
export interface PutVendorListingV1ByIdApiArg {
  id: string
  updateVendorListing: UpdateVendorListing
}
export type DeleteVendorListingV1ByIdApiResponse = /** status 200 Default Response */ ApiResult
export interface DeleteVendorListingV1ByIdApiArg {
  id: string
  body: {
    admin_email: EmailString
  }
}
export type PostVendorListingV1ByIdReportDataApiResponse = unknown
export interface PostVendorListingV1ByIdReportDataApiArg {
  id: string
  vendorUserReport: VendorUserReport
}
export type PostVendorListingV1KeysApiResponse = /** status 201 Default Response */ ApiResult
export interface PostVendorListingV1KeysApiArg {
  body: {
    key: string
    vendor_listing_id: string
    admin_email: EmailString
  }
}
export type PutVendorListingV1ReplaceListingApiResponse = /** status 200 Default Response */ ApiResult
export interface PutVendorListingV1ReplaceListingApiArg {
  body: {
    gobbler_vendor_listing_id: string
    food_vendor_listing_id: string
    admin_email: EmailString
  }
}
export type PutVendorListingV1ByIdLogoApiResponse = /** status 200 Default Response */ ApiResult
export interface PutVendorListingV1ByIdLogoApiArg {
  id: string
  body: {
    file_data: string
    mime_type: string
    admin_email: EmailString
  }
}
export type GetVendorListingV1FindWebsiteByListingTypeApiResponse = unknown
export interface GetVendorListingV1FindWebsiteByListingTypeApiArg {
  displayName: NonEmptyString
  listingType: ToolOrVendorString
}
export type PostVendorListingV1TransferToolApiResponse = /** status 200 Default Response */ ApiResult
export interface PostVendorListingV1TransferToolApiArg {
  body: {
    gobblerId: string
    foodId: string
    toolListingId: string
    admin_email: EmailString
  }
}
export type PostVendorListingV1TransferDescriptorApiResponse = /** status 200 Default Response */ ApiResult
export interface PostVendorListingV1TransferDescriptorApiArg {
  body: {
    gobblerId: string
    foodId: string
    descriptor: string
    admin_email: EmailString
  }
}
export type PostVendorListingV1UpdateListingLogosApiResponse = /** status 200 Default Response */ ApiResult
export type PostVendorListingV1UpdateListingLogosApiArg = void
export type GetVendorListingV1DraftListingFromUrlByListingTypeApiResponse = unknown
export interface GetVendorListingV1DraftListingFromUrlByListingTypeApiArg {
  url: HttpUrlString
  listingType: ToolOrVendorString
}
export type PutTransactionVendorToolListingV1ByMerchantIdentificationApiResponse =
  /** status 200 Default Response */ ApiResult
export interface PutTransactionVendorToolListingV1ByMerchantIdentificationApiArg {
  merchantIdentification: string
  body: {
    status: ListingStatus
    admin_email: EmailString
    tool_listing_id?: null | string
  }
}
export type ObjectType =
  | "Document"
  | "EmployeeGroup"
  | "ErpTrackingCategory"
  | "Integration"
  | "LegalAgreement"
  | "Link"
  | "Login"
  | "Person"
  | "Receipt"
  | "ReconciledTransaction"
  | "Tool"
  | "ToolCategory"
  | "ToolListing"
  | "User"
  | "Vendor"
  | "VendorListing"
  | "WorkflowRun"
  | "WorkflowRunApprover"
  | "WorkflowRunStep"
export type CustomizableObjectType = "Tool" | "Vendor" | "LegalAgreement"
export type FieldName = string
export type FieldCategory = "it" | "compliance" | "finance" | "legal"
export type DateTimeString = string
export type WorkflowKind = "renewal" | "purchase"
export type NonEmptyTrimmedProseString = string
export interface WorkflowDefMinimal {
  id: string
  created_at: DateTimeString
  kind: WorkflowKind
  display_name: NonEmptyTrimmedProseString
  description: null | NonEmptyTrimmedProseString
  updated_at: DateTimeString
}
export type NonEmptyTrimmedString = string
export type EmailString = string
export type UserStatus = "pending" | "active" | "deleted"
export interface ImageAsset {
  id: string
  gcs_file_name: NonEmptyTrimmedString
  created_at: DateTimeString
  file_name: null | NonEmptyTrimmedString
  mime_type: null | NonEmptyTrimmedString
}
export interface UserMinimal {
  id: string
  object_type: "User"
  organization_id: string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: EmailString
  status: UserStatus
  profile_image: null | ImageAsset
  created_at: DateTimeString
}
export interface FieldConfig {
  object_type: CustomizableObjectType
  field_name: FieldName
  is_custom: boolean
  /** The data type of the field as a JSON schema */
  field_schema: null | any
  is_enabled: boolean
  category: null | FieldCategory
  /** The workflow definitions this field is used in. */
  workflow_defs: WorkflowDefMinimal[]
  updated_at: null | DateTimeString
  created_at: null | DateTimeString
  created_by: null | UserMinimal
  updated_by: null | UserMinimal
}
export interface FieldConfigsResponse {
  /** Schemas referenced by the field schemas. See the $defs key in the JSON Schema spec. */
  $defs: {
    [key: string]: any
  }
  fields: FieldConfig[]
}
export interface SimilarField {
  object_type: ObjectType
  field_name: FieldName
  /** True if the field is a custom field. */
  is_custom: boolean
  field_schema: any
}
export type SimilarFieldResponse = SimilarField[]
export interface SimilarFieldQuery {
  object_type?: CustomizableObjectType
  field_name?: FieldName
  is_custom?: boolean
  /** The data type of the field as a JSON schema */
  field_schema?: null | any
  is_enabled?: boolean
  category?: null | FieldCategory
}
export interface FieldConfigMinimal {
  object_type: CustomizableObjectType
  field_name: FieldName
  is_custom: boolean
  /** The data type of the field as a JSON schema */
  field_schema: null | any
  is_enabled: boolean
  category: null | FieldCategory
}
export interface FieldConfigInput {
  object_type: CustomizableObjectType
  field_name: FieldName
  is_custom: boolean
  /** The data type of the field as a JSON schema */
  field_schema: null | any
  is_enabled: boolean
  category: null | FieldCategory
}
export type NonEmptyString = string
export interface PostmarkMailbox {
  Email: string
  Name: string
  MailboxHash: string
}
export interface PostmarkInboundMessage {
  FromName: string
  MessageStream: string
  From: string
  FromFull: PostmarkMailbox
  To: string
  ToFull: PostmarkMailbox[]
  Cc: string
  CcFull: PostmarkMailbox[]
  Bcc: string
  BccFull: PostmarkMailbox[]
  OriginalRecipient: string
  Subject: string
  MessageID: string
  ReplyTo: string
  MailboxHash: string
  Date: string
  TextBody: string
  HtmlBody: string
  StrippedTextReply: string
  Tag: string
  Headers: {
    Name: string
    Value: string
  }[]
  Attachments: {
    Name: string
    Content: string
    ContentType: string
    ContentID: string
  }[]
}
export interface Person {
  id: string
  object_type: "Person"
  display_name: NonEmptyTrimmedString
  organization_id: string
  user_id: null | string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: null | EmailString
  profile_image: null | ImageAsset
  employment_status: null | ("active" | "inactive" | "pending")
}
export type Role =
  | "super_admin"
  | "admin"
  | "compliance"
  | "compliance_approver"
  | "finance"
  | "finance_approver"
  | "it"
  | "it_approver"
  | "legal"
  | "legal_approver"
export interface Organization {
  id: string
  display_name: NonEmptyTrimmedProseString
  email_domain: null | string
  allow_signups: boolean
  sso_only: boolean
  locale: NonEmptyTrimmedProseString
  time_zone: NonEmptyTrimmedProseString
  is_seller: boolean
  is_buyer: boolean
  trial_expires_at: null | DateTimeString
}
export interface UserWithOrganization {
  id: string
  object_type: "User"
  organization_id: string
  email: EmailString
  profile_image: null | ImageAsset
  created_at: DateTimeString
  person: Person
  roles: Role[]
  first_name: NonEmptyTrimmedString
  last_name: NonEmptyTrimmedString
  status: "active" | "deleted"
  totp_enabled: boolean
  organization: Organization
}
export interface UserSettings {
  totp_enabled: boolean
}
export interface ApiResult {
  status: "success" | "failure"
}
export interface ApiError {
  message: NonEmptyString
}
export type NotificationChannel = "email" | "slack" | "inbox"
export type NotificationType =
  | "mention"
  | "workflow_run.started"
  | "workflow_run.input_needed"
  | "workflow_run.completed"
  | "workflow_run.timeline_comment"
  | "workflow_run_step.approved"
  | "workflow_run_step.approval_requested"
  | "workflow_run_step.unapproved"
  | "workflow_run_step.changes_requested"
  | "workflow_run_step_field.reopened"
  | "workflow_run_field.gatherer_assigned"
  | "tool.timeline_comment"
  | "tool_digest"
  | "vendor.timeline_comment"
  | "vendor_digest"
  | "renewal_reminder.30d"
  | "renewal_reminder.60d"
  | "renewal_reminder.90d"
  | "opt_out_reminder.30d"
  | "opt_out_reminder.60d"
  | "opt_out_reminder.90d"
  | "tool_owned.timeline_comment"
  | "tool_owned_renewal_reminder.30d"
  | "tool_owned_renewal_reminder.60d"
  | "tool_owned_renewal_reminder.90d"
  | "tool_owned_opt_out_reminder.30d"
  | "tool_owned_opt_out_reminder.60d"
  | "tool_owned_opt_out_reminder.90d"
  | "vendor_owned.timeline_comment"
  | "vendor_owned_renewal_reminder.30d"
  | "vendor_owned_renewal_reminder.60d"
  | "vendor_owned_renewal_reminder.90d"
  | "vendor_owned_opt_out_reminder.30d"
  | "vendor_owned_opt_out_reminder.60d"
  | "vendor_owned_opt_out_reminder.90d"
export type NotificationFrequency = "daily" | "weekly"
export interface NotificationSetting {
  type: NotificationType
  channel: NotificationChannel
  enabled: boolean
  frequency: null | NotificationFrequency
}
export interface UpdateNotificationSetting {
  type: NotificationType
  channel: NotificationChannel
  enabled?: boolean
  frequency?: NotificationFrequency
}
export interface User {
  id: string
  object_type: "User"
  organization_id: string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: EmailString
  status: UserStatus
  profile_image: null | ImageAsset
  created_at: DateTimeString
  person: Person
  roles: Role[]
}
export interface UpdateUser {
  roles?: Role[]
  first_name?: NonEmptyTrimmedString
  last_name?: NonEmptyTrimmedString
  transferred_user_id?: string
}
export interface UserOrganizationSummaryResponse {
  users: {
    id: string
    object_type: "User"
    organization_id: string
    first_name: null | NonEmptyTrimmedString
    last_name: null | NonEmptyTrimmedString
    email: EmailString
    status: UserStatus
    profile_image: null | ImageAsset
    created_at: DateTimeString
    person: Person
    roles: Role[]
    totp_enabled: boolean
  }[]
  total: number
}
export type UserOrganizationSummaryColumn = "email" | "status" | "created_at" | "full_name"
export type SortDirection = "ASC" | "DESC"
export type SortNulls = "FIRST" | "LAST"
export interface StartsWithFilter {
  comparator: "starts_with"
  value: NonEmptyString
}
export interface ContainsFilter {
  comparator: "contains"
  value: NonEmptyString
}
export interface EqFilter {
  comparator: "eq"
  value: NonEmptyString
}
export interface NeFilter {
  comparator: "ne"
  value: NonEmptyString
}
export interface GtFilter {
  comparator: "gt"
  value: NonEmptyString
}
export interface LtFilter {
  comparator: "lt"
  value: NonEmptyString
}
export interface GteFilter {
  comparator: "gte"
  value: NonEmptyString
}
export interface LteFilter {
  comparator: "lte"
  value: NonEmptyString
}
export interface BetweenFilter {
  comparator: "between"
  /** The minimum value, inclusive. Can be any comparable scalar, such as a decimal number or a date. */
  minValue: NonEmptyString
  /** The maximum value, inclusive. Can be any comparable scalar, such as a decimal number or a date. */
  maxValue: NonEmptyString
}
export interface AnyFilter {
  comparator: "any"
  values: NonEmptyString[]
  /** If true, this filter will return all values that match the values in the array or are null. If false, it will only return values that match the ones in the array */
  includeNull?: boolean
}
export interface ArrContainsFilter {
  comparator: "arr_contains"
  values: NonEmptyString[]
  /** If true, this filter will return all values that match the values in the array or are null. If false, it will only return values that match the ones in the array */
  includeNull?: boolean
}
export interface ExistsFilter {
  comparator: "exists"
  value: boolean
}
export interface RangeWithinFilter {
  comparator: "range_within"
  minValue: NonEmptyString
  maxValue: NonEmptyString
}
export interface RangeGteFilter {
  comparator: "range_gte"
  value: NonEmptyString
}
export type FilterField =
  | ({
      comparator: "starts_with"
    } & StartsWithFilter)
  | ({
      comparator: "contains"
    } & ContainsFilter)
  | ({
      comparator: "eq"
    } & EqFilter)
  | ({
      comparator: "ne"
    } & NeFilter)
  | ({
      comparator: "gt"
    } & GtFilter)
  | ({
      comparator: "lt"
    } & LtFilter)
  | ({
      comparator: "gte"
    } & GteFilter)
  | ({
      comparator: "lte"
    } & LteFilter)
  | ({
      comparator: "between"
    } & BetweenFilter)
  | ({
      comparator: "any"
    } & AnyFilter)
  | ({
      comparator: "arr_contains"
    } & ArrContainsFilter)
  | ({
      comparator: "exists"
    } & ExistsFilter)
  | ({
      comparator: "range_within"
    } & RangeWithinFilter)
  | ({
      comparator: "range_gte"
    } & RangeGteFilter)
export interface InviteUser {
  email: EmailString
  roles: Role[]
  first_name: NonEmptyTrimmedString
  last_name: NonEmptyTrimmedString
}
export interface LoginResult {
  state: "success" | "failure" | "2fa_required" | "okta_sso_required"
  message?: NonEmptyString
}
export interface LoginInput {
  email: string
  password: NonEmptyString
  token?: NonEmptyString
}
export type HttpUrlString = string
export interface ChangePasswordInput {
  passwordResetCode?: NonEmptyString
  newPassword: NonEmptyString
}
export interface CreateUserInput {
  email: EmailString
  firstName: NonEmptyTrimmedString
  lastName: NonEmptyTrimmedString
  linkCode: NonEmptyString
}
export interface OrganizationOktaSsoConfig {
  okta_client_id: null | NonEmptyTrimmedString
  okta_client_secret: null | NonEmptyTrimmedString
  okta_domain: null | HttpUrlString
}
export interface UpdateOrganization {
  id: string
  allow_signups?: boolean
  sso_only?: boolean
  okta_client_id?: NonEmptyTrimmedString
  okta_client_secret?: NonEmptyTrimmedString
  okta_domain?: HttpUrlString
}
export interface CreateOrganization {
  display_name: NonEmptyTrimmedProseString
  email_domain: null | string
  allow_signups: boolean
  sso_only: boolean
  locale: NonEmptyTrimmedProseString
  time_zone: NonEmptyTrimmedProseString
  admin_email: EmailString
}
export interface UserPicker {
  type: "user"
  object_type: "User"
  organization_id: string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: EmailString
  roles: Role[]
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export interface PersonPicker {
  type: "person"
  object_type: "Person"
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: null | EmailString
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export interface PersonVariablePicker {
  type: "variable"
  variable: "manager"
}
export interface FieldTagPicker {
  type: "field"
  field_name: NonEmptyTrimmedString
  is_custom: boolean
  object_type: ObjectType
  display_name: NonEmptyTrimmedString
}
export type WorkflowRunStatus = "in_progress" | ("approved" | "rejected" | "cancelled")
export interface WorkflowRunPicker {
  type: "workflow_run"
  object_type: "WorkflowRun"
  status: WorkflowRunStatus
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export interface LegalAgreementPicker {
  type: "legal_agreement"
  object_type: "LegalAgreement"
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export interface ToolPicker {
  type: "tool"
  object_type: "Tool"
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export interface VendorPicker {
  type: "vendor"
  object_type: "Vendor"
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export interface ToolListingPicker {
  type: "tool_listing"
  object_type: "ToolListing"
  website?: null | HttpUrlString
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export interface VendorListingPicker {
  type: "vendor_listing"
  object_type: "VendorListing"
  website?: null | HttpUrlString
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export interface WorkflowBuyerPicker {
  type: "workflow_buyer"
  object_type: "User"
  organization_id: string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: EmailString
  roles: Role[]
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export interface WorkflowSellerPicker {
  type: "workflow_seller"
  object_type: "User"
  organization_id: string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: EmailString
  roles: Role[]
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export type PickableEntity =
  | ({
      type: "user"
    } & UserPicker)
  | ({
      type: "person"
    } & PersonPicker)
  | ({
      type: "variable"
    } & PersonVariablePicker)
  | ({
      type: "field"
    } & FieldTagPicker)
  | ({
      type: "workflow_run"
    } & WorkflowRunPicker)
  | ({
      type: "legal_agreement"
    } & LegalAgreementPicker)
  | ({
      type: "tool"
    } & ToolPicker)
  | ({
      type: "vendor"
    } & VendorPicker)
  | ({
      type: "tool_listing"
    } & ToolListingPicker)
  | ({
      type: "vendor_listing"
    } & VendorListingPicker)
  | ({
      type: "workflow_buyer"
    } & WorkflowBuyerPicker)
  | ({
      type: "workflow_seller"
    } & WorkflowSellerPicker)
export type PickableEntityType =
  | "user"
  | "person"
  | "variable"
  | "workflow_seller"
  | "workflow_buyer"
  | "legal_agreement"
  | "workflow_run"
  | "tool"
  | "vendor"
  | "field"
  | "tool_listing"
  | "vendor_listing"
export interface PickableEntityFilter {
  name?: string
  entities?: PickableEntityType[]
  /** The entity that is requesting the pickable entities. Used to filter pickable entities that the requesting entity is allowed to see. */
  requesting_entity?: {
    object_type: ObjectType
    object_id?: string
    workflow_step_id?: string
  }
}
export type LimitNumber = number
export type PermissionedPickableEntity =
  | ({
      type: "user"
    } & UserPicker)
  | ({
      type: "person"
    } & PersonPicker)
  | ({
      type: "variable"
    } & PersonVariablePicker)
export type PermissionedEntityType = "user" | "person" | "variable" | "workflow_seller" | "workflow_buyer"
export type Permission =
  | "transaction:read"
  | "spend:read"
  | "saved_view.shared:create"
  | "saved_view.shared:update"
  | "saved_view.shared:delete"
  | "login:read"
  | "user:update"
  | "user:delete"
  | "admin_role:create"
  | "admin_role:update"
  | "admin_role:delete"
  | "super_admin_role:create"
  | "super_admin_role:update"
  | "super_admin_role:delete"
  | "compliance_role:create"
  | "compliance_approver_role:create"
  | "finance_role:create"
  | "finance_approver_role:create"
  | "it_role:create"
  | "it_approver_role:create"
  | "legal_role:create"
  | "legal_approver_role:create"
  | "organization:update"
  | "organization_okta_sso_config:read"
  | "field_config:read"
  | "field_config:create"
  | "field_config:update"
  | "field_config:delete"
  | "integration:read"
  | "integration:create"
  | "integration:update"
  | "integration:delete"
  | "tool:update"
  | "vendor:update"
  | "tool:hide"
  | "vendor:hide"
  | "legal:read"
  | "legal:create"
  | "legal:update"
  | "legal:delete"
  | "legal:read:private"
  | "legal:update:private"
  | "legal:delete:private"
  | "finance:update"
  | "compliance:read"
  | "compliance:create"
  | "compliance:update"
  | "compliance:delete"
  | "it:read"
  | "it:create"
  | "it:update"
  | "it:delete"
  | "workflow:approve"
  | "compliance:approve"
  | "finance:approve"
  | "it:approve"
  | "legal:approve"
  | "workflow:approve:any"
  | "workflow_def:read"
  | "workflow_def:create"
  | "workflow_def:update"
  | "workflow_def:delete"
  | "workflow_run:create"
  | "workflow_run:update"
  | "workflow_run:delete"
  | "workflow_run:view:any"
  | "workflow_run_step:submit:intake"
  | "workflow_run_step:update"
export interface PermissionedPickableEntityFilter {
  name?: string
  /** The entity that is requesting the pickable entities. Used to filter pickable entities that the requesting entity is allowed to see. */
  requesting_entity?: {
    object_type: ObjectType
    object_id?: string
    workflow_step_id?: string
  }
  entities?: PermissionedEntityType[]
  permission?: {
    value: Permission
    includeAll: boolean
  }
}
export type CurrencyCodeString = string
export type NumericString = string
export interface CurrencyAmount {
  amount: NumericString
  currency_code: CurrencyCodeString
}
export type DateString = string
export interface SpendInterval {
  start_date: DateString
  currency_amount: CurrencyAmount
  bill_payment_currency_amount: CurrencyAmount
  credit_card_currency_amount: CurrencyAmount
  other_currency_amount: CurrencyAmount
}
export interface SpendResponse {
  currency_code: CurrencyCodeString
  total: CurrencyAmount
  intervals?: SpendInterval[]
}
export type DateDurationString = string
export type SpendQueryKind = "vendor" | "tool" | "organization"
export type TimePeriod = "month_to_date" | "year_to_date" | "last_twelve_months" | "last_thirty_days"
export interface NumberStatistic {
  value: number
  increase_value: number
  time_period: TimePeriod
}
export type IntegrationProvider =
  | "ramp_oauth"
  | "google_oauth"
  | "gmail_oauth"
  | "brex_oauth"
  | "quickbooks_oauth"
  | "slack_oauth"
  | "ramp_client_creds"
  | "brex_apikey"
  | "mercury_apikey"
  | "okta_apikey"
  | "merge_hris_link_token"
  | "merge_accounting_link_token"
  | "okta_oauth"
  | "plaid_access_token"
export interface UserWithProvider {
  id: string
  object_type: "User"
  organization_id: string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: EmailString
  status: UserStatus
  profile_image: null | ImageAsset
  created_at: DateTimeString
  person: Person
  roles: Role[]
  integration_provider: IntegrationProvider
}
export interface AllEmailIntegrationStatistic {
  linked_agreement_count: NumberStatistic
  user_integrations: UserWithProvider[]
  current_user_connected: boolean
}
export interface AllAgreementStatistic {
  total_count: number
  verified_count: number
  unverified_agreement_id?: string
}
export interface AllPersonStatistic {
  employed_count: NumberStatistic
}
export interface SpendStatistic {
  currency_amount: CurrencyAmount
  increase_percent: null | NonEmptyString
}
export interface AllToolStatistic {
  count: NumberStatistic
  paid_count: NumberStatistic
  mtd_spend_statistic?: null | SpendStatistic
  ltm_spend_statistic?: null | SpendStatistic
  ytd_spend_statistic?: null | SpendStatistic
}
export type ToolStatus = "approved" | "in_review" | "unknown" | "canceling" | "warning" | "churned" | "rejected"
export type ToolFinanceStatus = "paid" | "unpaid" | "in_review" | "unknown" | "paid_unapproved" | "rejected"
export type ToolComplianceStatus = "approved" | "in_review" | "unknown" | "rejected"
export type ToolItStatus = "approved" | "in_review" | "unknown" | "rejected"
export type FieldSourceType = "sor" | "user" | "transaction" | "document" | "link" | "listing" | "derived"
export type DurationString = string
export interface Source {
  text: string[]
  page_number?: number
}
export interface AssignedByMetadataOutput {
  /** The source from the document assigned by the user as provenance. */
  source: Source
  object_field_source: {
    id: string
    type: "document" | "transaction"
    /** The display name for drop down menus */
    source_display_name?: NonEmptyTrimmedString
  }
}
export interface FieldSourceOutputProperties {
  /** The field source provenance type. */
  type?: FieldSourceType
  /** Unique identifier associated to the field source type. (eg. type = 'user' and id = user_id) */
  id?: null | string
  updated_at?: null | DateTimeString
  derived_from?: NonEmptyTrimmedString[]
  /** Only used when type = 'listing' */
  rationale?: NonEmptyTrimmedString
  links?: HttpUrlString[]
  /** The display name for drop down menus */
  source_display_name?: NonEmptyTrimmedString
  /** Only used when type = 'listing' */
  read_time_estimate?: DurationString
  source_updated_at?: null | DateTimeString
  assigned_by_metadata?: null | AssignedByMetadataOutput
}
export interface Suggestion {
  value: any
  field_sources: FieldSourceOutputProperties[]
}
export interface FieldMetadataWithSuggestions {
  /** The field source provenance type. */
  type?: FieldSourceType
  /** Unique identifier associated to the field source type. (eg. type = 'user' and id = user_id) */
  id?: null | string
  updated_at?: null | DateTimeString
  derived_from?: NonEmptyTrimmedString[]
  /** Only used when type = 'listing' */
  rationale?: NonEmptyTrimmedString
  links?: HttpUrlString[]
  /** The display name for drop down menus */
  source_display_name?: NonEmptyTrimmedString
  /** Only used when type = 'listing' */
  read_time_estimate?: DurationString
  source_updated_at?: null | DateTimeString
  assigned_by_metadata?: null | AssignedByMetadataOutput
  verified?: boolean
  verified_at?: null | DateTimeString
  verified_by?: null | string
  suggestions?: Suggestion[]
}
export interface WorkflowFieldsMetadata {
  [key: string]:
    | FieldMetadataWithSuggestions
    | {
        [key: string]: FieldMetadataWithSuggestions
      }
}
export interface ToolCategory {
  id: string
  object_type: "ToolCategory"
  display_name: NonEmptyTrimmedString
  parent_id: null | string
}
export interface CustomFields {
  [key: string]: any
}
export type DocumentExtractionStatus =
  | "running_text_ocr"
  | "deduping_documents"
  | "categorizing"
  | "extracting_fields"
  | "populating_objects"
  | "failed"
  | "completed"
  | "duplicated"
export interface DocumentMinimal {
  id: string
  object_type: "Document"
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  /** The original file basename as uploaded by the user. */
  file_name: null | NonEmptyTrimmedString
  status: "pending" | "uploaded" | "scanned" | "quarantined"
  extraction_status?: null | DocumentExtractionStatus
  /** The file size in bytes */
  file_size: null | number
  /** MIME type like `application/pdf` or `text/plain; charset=utf-8` */
  mime_type: null | NonEmptyTrimmedString
  /** Users who have email integrations which have found this document */
  user_email_integrations?: UserWithProvider[]
}
export type DocumentOrUrlString = DocumentMinimal | HttpUrlString
export type CustomerSecurityImpact = "none" | "low" | "medium" | "high"
export interface CompliantWithDocument {
  /** Whether the tool is compliant */
  compliant: null | boolean
  document: null | DocumentOrUrlString
}
export interface ApplicableWithDocument {
  /** Whether the standard is applicable */
  applicable: null | boolean
  document: null | DocumentOrUrlString
}
export type PiiDataType =
  | "name"
  | "home_phone_number"
  | "mobile_phone_number"
  | "personal_account_number"
  | "home_address"
  | "personal_reference_number"
  | "work_address"
  | "employee_number"
  | "ip_address"
  | "device_identifier"
  | "niss_identification_number"
  | "personal_email_address"
  | "bank_account_information"
  | "work_email_address"
  | "trade_union_membership"
export type PiiPersonType = "employees" | "clients" | "prospective_employees" | "contractors"
export type PiiRegion = "north_america" | "european_union" | "asia"
export type ComplianceData =
  | "customer_data"
  | "financial_data"
  | "employee_data"
  | "operational_data"
  | "technical_data"
  | "legal_data"
  | "intellectual_property"
  | "marketing_data"
  | "health_data"
  | "biometric_data"
export type SsoType =
  | "okta_oauth"
  | "google_oauth"
  | "okta_saml"
  | "google_saml"
  | "one_login_saml"
  | "entra_id_saml"
  | "rippling_saml"
  | "saml"
export type MfaType = "sms" | "email" | "otp_software" | "otp_hardware"
export type AccessControlType = "rbac" | "abac" | "acl"
export type SoftwareDeploymentType = "cloud" | "on_prem" | "hybrid" | "private_cloud"
export interface ToolListing {
  id: string
  object_type: "ToolListing"
  display_name: NonEmptyTrimmedString
  image_asset: null | {
    gcs_file_name: NonEmptyTrimmedString
  }
}
export type OneTimeFrequency = "one_time"
export type FrequencyOrOneTime = OneTimeFrequency | DateDurationString
export type ElementTypes =
  | "block-quote"
  | "bulleted-list"
  | "numbered-list"
  | "heading-one"
  | "heading-two"
  | "image"
  | "link"
  | "list-item"
  | "mention"
  | "paragraph"
  | "field-tag"
  | "standard-object"
export interface RichTextNode {
  text?: string & string
  bold?: boolean
  italic?: boolean
  underline?: boolean
  type?: ElementTypes
  children?: RichTextNode[]
  file_name?: NonEmptyTrimmedString
  url?: string
  user_id?: string
  display_name?: NonEmptyTrimmedString
  organization_id?: string
  field_name?: NonEmptyTrimmedString
  object_type?: ObjectType
  is_custom?: boolean
  id?: string
}
export type RichText = RichTextNode[]
export interface VendorMinimal {
  /** The company name of the vendor */
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
  id: string
  object_type: "Vendor"
  vendor_listing_id: string
  created_at: DateTimeString
}
export type EmployeeGroupType = "team" | "department" | "cost_center" | "business_unit" | "group"
export interface EmployeeGroup {
  id: string
  object_type: "EmployeeGroup"
  display_name: null | NonEmptyTrimmedString
  group_type: null | EmployeeGroupType
}
export interface LegalClauses {
  /** Whether the contract has an arbitration clause. The arbitration clause requires the parties to the contract to resolve any dispute arising out of the contract through an arbitration process, as opposed to litigation or mediation. */
  arbitration: null | boolean
  /** Whether the contract has an assignment clause. The assignment clause indicates whether and when a party to the contract can assign their rights and obligations under the contract to someone else. */
  assignment: null | boolean
  /** Whether the contract has a confidentiality clause. The confidentiality clause prevents one party (or both parties) from disclosing information related to the agreement, or other sensitive information. */
  confidentiality: null | boolean
  /** Whether the contract has a data privacy clause. The data privacy clause may explain what private data will be collected, what the collecting party will do with the data, and/or how the data will be protected. */
  data_privacy: null | boolean
  /** Whether the contract has a data processing clause. The data processing clause outlines the roles and responsibilities of the parties involved with regards to processing of personal data. It details the methods, purposes, and scope of the data processing activities carried out under the agreement. It may specify the rights and obligations of the data controller and data processor, and the safeguards and measures that must be in place to ensure data security and compliance with relevant data protection laws and regulations. */
  data_processing: null | boolean
  /** Whether the contract has a data protection clause. The data protection clause ensures that the data given by one party to another is safeguarded from unlawful access by unauthorized users or entities. */
  data_protection: null | boolean
  /** Whether the contract has a dispute resolution clause. The dispute resolution clause sets forth the circumstances under which any disputes between the parties should be resolved (e.g., whether the dispute should be resolved via arbitration, mediation, or litigation; jurisdiction, venue, etc.) */
  dispute_resolution: null | boolean
  /** Whether the contract has a force majeure clause. The force majeure clause frees both parties from obligation if an extraordinary event directly prevents one or both parties from performing their obligations under the contract. */
  force_majeure: null | boolean
  /** Whether the contract has a governing law clause. The governing law clause specifies which jurisdiction’s law will apply in the event of a dispute. */
  governing_law: null | boolean
  /** Whether the contract has an indemnification clause. The indemnification clause obligates one party to compensate another party for certain costs and expenses, usually stemming from third party claims. */
  indemnification: null | boolean
  /** Whether the contract has an intellectual property clause. The intellectual property clause sets forth ownership, permitted uses, and use restrictions of intellectual property. */
  intellectual_property: null | boolean
  /** Whether the contract has a license clause. The license clause gives one party the right to use the product or services of the other party under certain conditions. */
  license: null | boolean
  /** Whether the contract has a limitation of liability clause. The limitation of liability clause limits the amount and types of compensation one party can recover from the other party when a claim is made or lawsuit is filed in response to a breach of the contract. */
  limitation_of_liability: null | boolean
  /** Whether the contract has a non disclosure clause. The non-disclosure clause prohibits the parties to the contract from releasing certain information to other parties. */
  non_disclosure: null | boolean
  /** Whether the contract has a non solicitation clause. The non-solicitation clause prohibits a party from offering employment to the counterparty's employees, for a specified period of time. */
  non_solicitation: null | boolean
  /** Whether the contract has a representation clause. The representations clause states that the representations contained in the contract are true and accurate as of the date of the agreement. */
  representation: null | boolean
  /** Whether the contract has a termination clause. The termination clause explains what will happen if either party terminates the contract. The clause may also set forth the circumstances in which one party may terminate the contract, such as required notice. */
  termination: null | boolean
  /** Whether the contract has a warranty clause. The warranty clause sets forth what promises, if any, are being made regarding the accuracy, completeness, or reliability of the subject matter of the contract, whether data, services, software, or otherwise. Warranty clauses can also specifically state that no such promises are being made or relied upon. */
  warranty: null | boolean
}
export type AgreementEffectiveStatus = "upcoming" | "active" | "unknown" | "expired"
export type AgreementType = "subscription" | "enterprise"
export type LegalClausesFieldsMetadata = any | any
export interface LegalAgreementFieldsMetadata {
  total_contract_value?: FieldMetadataWithSuggestions
  start_date?: FieldMetadataWithSuggestions
  end_date?: FieldMetadataWithSuggestions
  first_invoice_date?: FieldMetadataWithSuggestions
  invoice_interval?: FieldMetadataWithSuggestions
  net_payment_term?: FieldMetadataWithSuggestions
  auto_renews?: FieldMetadataWithSuggestions
  auto_renewal_opt_out_period?: FieldMetadataWithSuggestions
  decision_date?: FieldMetadataWithSuggestions
  is_signed?: FieldMetadataWithSuggestions
  buyer_signer_name?: FieldMetadataWithSuggestions
  buyer_signer_title?: FieldMetadataWithSuggestions
  vendor_signer_name?: FieldMetadataWithSuggestions
  vendor_signer_title?: FieldMetadataWithSuggestions
  vendor?: FieldMetadataWithSuggestions
  tools?: FieldMetadataWithSuggestions
  display_name?: FieldMetadataWithSuggestions
  agreement_type?: FieldMetadataWithSuggestions
  clauses?: LegalClausesFieldsMetadata
  custom?: {
    [key: string]: FieldMetadataWithSuggestions
  }
}
export type AgreementVerificationStatus = "draft" | "verified" | "in_review"
export type AgreementVisibilityStatus = "hidden" | "private" | "draft" | "visible"
export interface LegalAgreementMinimal {
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to NOT renew before the next auto-renewal as an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  /** The legal clauses found in the contract. */
  clauses?: LegalClauses
  custom: CustomFields
  id: string
  object_type: "LegalAgreement"
  /** Whether the Legal Agreement is active, expired, or upcoming. Will be unknown if the agreement has not been verified or is missing start/end dates. */
  effective_status: AgreementEffectiveStatus
  /** The type of agreement (enterprise or subscription) */
  agreement_type: AgreementType
  /** The name of the agreement. */
  display_name: NonEmptyTrimmedProseString
  /** The next occurrence of the billing interval (based on the first invoice date) */
  next_invoice_date?: null | DateString
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  fields_metadata: LegalAgreementFieldsMetadata
  /** Legal agreements that enter through certain sources, like bulk upload or email, need to have their extracted fields verified. Manually created agreements always start verified. Verified agreements may become unverified when more documents are added. */
  verification_status: AgreementVerificationStatus
  visibility_status: AgreementVisibilityStatus
}
export type GrowthRate = number
export interface SpendSummary {
  currency_amount: CurrencyAmount
  growth: null | GrowthRate
}
export interface AggregatedSpend {
  mtd: null | SpendSummary
  ytd: null | SpendSummary
  ltm: null | SpendSummary
  total: null | CurrencyAmount
}
export type TransactionPaymentMethod = "credit_card" | "bill_payment"
export interface ErpDepartment {
  id: string
  object_type: "ErpTrackingCategory"
  display_name: NonEmptyTrimmedString
  type: "department"
}
export interface ErpClass {
  id: string
  object_type: "ErpTrackingCategory"
  display_name: NonEmptyTrimmedString
  type: "class"
}
export interface ErpTrackingCategoryGroups {
  departments: ErpDepartment[]
  classes: ErpClass[]
}
export interface ToolListItem {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the tool's website */
  website: null | HttpUrlString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAsset
  id: string
  object_type: "Tool"
  tool_listing_id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  /** The overall approval status of the tool */
  status: ToolStatus
  /** The financial approval status of the tool */
  finance_status?: ToolFinanceStatus
  /** The compliance approval status of the tool */
  compliance_status?: ToolComplianceStatus
  /** The IT approval status of the tool */
  it_status?: ToolItStatus
  /** The person in the organization responsible for the tool */
  owner: null | Person
  fields_metadata?: WorkflowFieldsMetadata
  organization_id: string
  /** The tool categories that the tool falls into */
  categories: null | ToolCategory[]
  hidden?: boolean
  custom: CustomFields
  /** URL or Document containing the service’s privacy policy */
  privacy_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service’s terms of service */
  terms_of_service?: null | DocumentOrUrlString
  /** Risk level can be categorized as:
    
    - **High**: Vendor handles sensitive data or security services; critical business impact if they fail.
    - **Moderate**: Vendor doesn’t handle sensitive data or security; moderate business impact if they fail.
    - **Low**: Vendor has minimal involvement and little to no business impact if they fail.
    - **None**: Vendor has no impact on business operations. */
  customer_security_impact_level?: null | CustomerSecurityImpact
  /** A critical vendor has one or more of the following characteristics:
    
    1) Handles customer data,
    2) Is responsible for our infrastructure or code security,
    3) Has cloud services that affect our system’s security or availability,
    4) Whose failure would result in disruption to our service availability. */
  is_critical?: null | boolean
  /** Whether the product or service is a data subprocessor */
  is_subprocessor?: null | boolean
  /** Link to a vendor’s list of third-party subprocessors */
  subprocessors?: null | HttpUrlString
  /** Whether the product or service has SOC 2 compliance certification */
  soc2?: null | CompliantWithDocument
  /** Whether the product or service has GDPR compliance certification */
  gdpr?: null | CompliantWithDocument
  /** Whether the product or service has HIPAA compliance certification */
  hipaa?: null | CompliantWithDocument
  /** Whether the product or service has ISO 27001 compliance certification */
  iso_27001?: null | CompliantWithDocument
  /** Whether the product or service has PCI DSS compliance certification */
  pci_dss?: null | CompliantWithDocument
  /** Whether the product or service has CCPA compliance certification */
  ccpa?: null | CompliantWithDocument
  /** Whether the product or service has CDPA compliance certification */
  cdpa?: null | CompliantWithDocument
  /** Whether the product or service has CMMC 2.0 compliance certification */
  cmmc_2?: null | CompliantWithDocument
  /** Whether the product or service has CPA compliance certification */
  cpa?: null | CompliantWithDocument
  /** Whether the product or service has CPRA compliance certification */
  cpra?: null | CompliantWithDocument
  /** Whether the product or service has CTDPA compliance certification */
  ctdpa?: null | CompliantWithDocument
  /** Whether the product or service has EU Whistleblower compliance certification */
  eu_whistleblower?: null | CompliantWithDocument
  /** Whether the product or service has Nevada Privacy Law compliance certification */
  nevada_privacy_law?: null | CompliantWithDocument
  /** Whether the product or service has SOX compliance certification */
  sox?: null | CompliantWithDocument
  /** Whether the product or service has UCPA compliance certification */
  ucpa?: null | CompliantWithDocument
  /** Whether the product or service is SEA Rule 17(a)4f compliant */
  sea_rule_17a4f?: null | boolean
  /** Whether the product or service stores data in WORM format */
  worm?: null | ApplicableWithDocument
  /** URL or Document containing the service's cybersecurity policy */
  cybersecurity_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's information security policy */
  information_security_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's Anti-Money Laundering policy */
  aml_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's financial stability evidence */
  financial_stability?: null | DocumentOrUrlString
  /** The Data Processing Agreement for a tool */
  dpa?: null | DocumentMinimal
  /** Whether the product or service stores personal identifying information (PII) */
  stores_pii?: null | boolean
  /** The types of personal identifiable information (PII) the product or service collects or retains */
  pii_data_types?: null | PiiDataType[]
  /** The types of people whose personal identifiable information (PII) the product or service collects or retains */
  pii_person_types?: null | PiiPersonType[]
  /** The regions whose personal identifiable information (PII) the product or service collects or retains */
  pii_regions?: null | PiiRegion[]
  /** The types of data the product or service collects or retains */
  data_stored?: null | ComplianceData[]
  /** Lists the specific Single Sign-On (SSO) methods the software supports */
  sso_types?: null | SsoType[]
  /** Lists the specific Multi-Factor Authentication methods the software supports */
  mfa_types?: null | MfaType[]
  /** Describes the specific methods used by the software to regulate data access */
  access_control_types?: null | AccessControlType[]
  /** Indicates how the software is deployed and accessed */
  deployment_types?: null | SoftwareDeploymentType[]
  /** Determines if the software provides System for Cross-domain Identity Management (SCIM) functionality */
  scim?: null | boolean
  /** Which other tools does this product integrate with? */
  integrates_with?: null | ToolListing[]
  /** The requested spend for the tool */
  requested_spend?: null | CurrencyAmount
  /** The frequency of spend requested for the tool */
  requested_spend_interval?: null | FrequencyOrOneTime
  /** What problem(s) will this tool solve and how? */
  overview: null | RichText
  /** Why are you choosing this product over alternative tools and services? */
  selection_rationale: null | RichText
  /** What are the areas of concern or gaps this tool has? */
  risks_limitations: null | RichText
  /** What’s the estimated return on investment (ROI) for this tool? */
  roi_analysis: null | RichText
  /** How long will it take to implement and fully deploy? */
  implementation_details: null | RichText
  /** What level of support is provided by the vendor for the selected plan? */
  support_from_vendor: null | RichText
  /** What other tools and services were considered during the evaluation of this tool? */
  tools_considered: ToolListing[]
  /** The vendor offering the tool */
  vendor: VendorMinimal
  /** The number of users that have accessed the tool */
  total_people: null | number
  /** The department classification for the tool owner */
  owner_groups: EmployeeGroup[]
  /** The primary legal agreement with the tool’s vendor for the tool */
  primary_legal_agreement: null | LegalAgreementMinimal
  /** The number of active legal agreements on the tool */
  active_legal_agreement_count: number
  /** The aggregated spent on the tool/vendor over various time periods */
  spend?: AggregatedSpend
  /** The method used to pay (e.g. bill pay or credit card) */
  payment_methods?: null | TransactionPaymentMethod[]
  /** The department or class categorization for the tool from your accounting system */
  erp_tracking_categories: ErpTrackingCategoryGroups
}
export interface ToolListItemResponse {
  tools: ToolListItem[]
  total: number
}
export type PropertyPathString = string
export type FilterPathString = string
export interface ListQueryStringParams {
  limit?: number
  offset?: number
  sort?: {
    by: PropertyPathString
    direction: SortDirection
    nulls?: SortNulls
  }
  /**
    The inner array is an OR, the outer array is an AND so if we get passed in filter `A`, `B`, `C`:
    
    - `[[A, B], [C]]` is `(A OR B) AND C`
    - `[[A], [B], [C]]` is `(A) AND (B) AND (C)`
    - `[[A, B, C]]` is `(A OR B OR C)`
    
    TODO make this more flexible.
     */
  filter?: {
    /** The dot-separated path of the property to filter on.
        
        For arrays, a path segment can also be a wildcard `*` (e.g. `categories.*.id`), in which case the expression being filtered on is calculated by mapping over each element of the element (in the example, an array of IDs). */
    column: FilterPathString
    fields: FilterField
  }[][]
}
export interface VendorOption {
  /** The company name of the vendor */
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
  id: null | string
  object_type: "Vendor"
  vendor_listing_id: string
}
export interface ToolOptionWithVendor {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the tool's website */
  website: null | HttpUrlString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAsset
  id: null | string
  object_type: "Tool"
  tool_listing_id: string
  vendor?: VendorOption
}
export interface Tool {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the tool's website */
  website: null | HttpUrlString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAsset
  id: string
  object_type: "Tool"
  tool_listing_id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  /** The overall approval status of the tool */
  status: ToolStatus
  /** The financial approval status of the tool */
  finance_status?: ToolFinanceStatus
  /** The compliance approval status of the tool */
  compliance_status?: ToolComplianceStatus
  /** The IT approval status of the tool */
  it_status?: ToolItStatus
  /** The person in the organization responsible for the tool */
  owner: null | Person
  fields_metadata?: WorkflowFieldsMetadata
  organization_id: string
  /** The tool categories that the tool falls into */
  categories: null | ToolCategory[]
  hidden?: boolean
  custom: CustomFields
}
export interface ToolListingPatch {
  id: string
  object_type: "ToolListing"
  display_name?: NonEmptyTrimmedString
  image_asset?: null | {
    gcs_file_name: NonEmptyTrimmedString
  }
}
export interface CompliantWithDocumentPatch {
  /** Whether the tool is compliant */
  compliant?: null | boolean
  document?: null | DocumentOrUrlString
}
export interface ApplicableWithDocumentPatch {
  /** Whether the standard is applicable */
  applicable?: null | boolean
  document?: null | DocumentOrUrlString
}
export interface ToolPatch {
  id: string
  object_type: "Tool"
  /** The overall approval status of the tool */
  status?: ToolStatus
  /** The financial approval status of the tool */
  finance_status?: ToolFinanceStatus
  /** The compliance approval status of the tool */
  compliance_status?: ToolComplianceStatus
  /** The IT approval status of the tool */
  it_status?: ToolItStatus
  fields_metadata?: WorkflowFieldsMetadata
  hidden?: boolean
  custom?: CustomFields
  /** Lists the specific Single Sign-On (SSO) methods the software supports */
  sso_types?: null | SsoType[]
  /** Lists the specific Multi-Factor Authentication methods the software supports */
  mfa_types?: null | MfaType[]
  /** Describes the specific methods used by the software to regulate data access */
  access_control_types?: null | AccessControlType[]
  /** Indicates how the software is deployed and accessed */
  deployment_types?: null | SoftwareDeploymentType[]
  /** Determines if the software provides System for Cross-domain Identity Management (SCIM) functionality */
  scim?: null | boolean
  /** Which other tools does this product integrate with? */
  integrates_with?: null | ToolListingPatch[]
  /** URL or Document containing the service’s privacy policy */
  privacy_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service’s terms of service */
  terms_of_service?: null | DocumentOrUrlString
  /** Risk level can be categorized as:
    
    - **High**: Vendor handles sensitive data or security services; critical business impact if they fail.
    - **Moderate**: Vendor doesn’t handle sensitive data or security; moderate business impact if they fail.
    - **Low**: Vendor has minimal involvement and little to no business impact if they fail.
    - **None**: Vendor has no impact on business operations. */
  customer_security_impact_level?: null | CustomerSecurityImpact
  /** A critical vendor has one or more of the following characteristics:
    
    1) Handles customer data,
    2) Is responsible for our infrastructure or code security,
    3) Has cloud services that affect our system’s security or availability,
    4) Whose failure would result in disruption to our service availability. */
  is_critical?: null | boolean
  /** Whether the product or service is a data subprocessor */
  is_subprocessor?: null | boolean
  /** Link to a vendor’s list of third-party subprocessors */
  subprocessors?: null | HttpUrlString
  /** Whether the product or service is SEA Rule 17(a)4f compliant */
  sea_rule_17a4f?: null | boolean
  /** URL or Document containing the service's cybersecurity policy */
  cybersecurity_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's information security policy */
  information_security_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's Anti-Money Laundering policy */
  aml_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's financial stability evidence */
  financial_stability?: null | DocumentOrUrlString
  /** Whether the product or service stores personal identifying information (PII) */
  stores_pii?: null | boolean
  /** The types of personal identifiable information (PII) the product or service collects or retains */
  pii_data_types?: null | PiiDataType[]
  /** The types of people whose personal identifiable information (PII) the product or service collects or retains */
  pii_person_types?: null | PiiPersonType[]
  /** The regions whose personal identifiable information (PII) the product or service collects or retains */
  pii_regions?: null | PiiRegion[]
  /** The types of data the product or service collects or retains */
  data_stored?: null | ComplianceData[]
  /** Whether the product or service has SOC 2 compliance certification */
  soc2?: null | CompliantWithDocumentPatch
  /** Whether the product or service has HIPAA compliance certification */
  hipaa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has GDPR compliance certification */
  gdpr?: null | CompliantWithDocumentPatch
  /** Whether the product or service has ISO 27001 compliance certification */
  iso_27001?: null | CompliantWithDocumentPatch
  /** Whether the product or service has PCI DSS compliance certification */
  pci_dss?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CCPA compliance certification */
  ccpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CDPA compliance certification */
  cdpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CMMC 2.0 compliance certification */
  cmmc_2?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CPA compliance certification */
  cpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CPRA compliance certification */
  cpra?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CTDPA compliance certification */
  ctdpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has EU Whistleblower compliance certification */
  eu_whistleblower?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Nevada Privacy Law compliance certification */
  nevada_privacy_law?: null | CompliantWithDocumentPatch
  /** Whether the product or service has SOX compliance certification */
  sox?: null | CompliantWithDocumentPatch
  /** Whether the product or service has UCPA compliance certification */
  ucpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service stores data in WORM format */
  worm?: null | ApplicableWithDocumentPatch
  /** The Data Processing Agreement for a tool */
  dpa?: null | DocumentMinimal
  /** The requested spend for the tool */
  requested_spend?: null | CurrencyAmount
  /** The frequency of spend requested for the tool */
  requested_spend_interval?: null | FrequencyOrOneTime
  /** What problem(s) will this tool solve and how? */
  overview?: null | RichText
  /** Why are you choosing this product over alternative tools and services? */
  selection_rationale?: null | RichText
  /** What are the areas of concern or gaps this tool has? */
  risks_limitations?: null | RichText
  /** What’s the estimated return on investment (ROI) for this tool? */
  roi_analysis?: null | RichText
  /** How long will it take to implement and fully deploy? */
  implementation_details?: null | RichText
  /** What level of support is provided by the vendor for the selected plan? */
  support_from_vendor?: null | RichText
  /** What other tools and services were considered during the evaluation of this tool? */
  tools_considered?: ToolListingPatch[]
  owner?: null | {
    id: string
  }
}
export interface ListingComplianceDocument {
  /** Whether the tool is compliant */
  compliant: null | boolean
}
export interface LegalClausesExtractedSource {
  arbitration?: Source
  assignment?: Source
  confidentiality?: Source
  data_privacy?: Source
  data_processing?: Source
  data_protection?: Source
  dispute_resolution?: Source
  force_majeure?: Source
  governing_law?: Source
  indemnification?: Source
  intellectual_property?: Source
  license?: Source
  limitation_of_liability?: Source
  non_disclosure?: Source
  non_solicitation?: Source
  representation?: Source
  termination?: Source
  warranty?: Source
}
export interface ExtractedSource {
  total_contract_value?: Source
  start_date?: Source
  end_date?: Source
  first_invoice_date?: Source
  invoice_interval?: Source
  net_payment_term?: Source
  auto_renews?: Source
  auto_renewal_opt_out_period?: Source
  decision_date?: Source
  is_signed?: Source
  buyer_signer_name?: Source
  buyer_signer_title?: Source
  vendor_signer_name?: Source
  vendor_signer_title?: Source
  vendor?: Source
  tools?: Source
  clauses?: LegalClausesExtractedSource
  custom?: {
    [key: string]: Source
  }
}
export type DocumentType =
  | "OrderForm"
  | "MSA"
  | "DPA"
  | "SLA"
  | "NDA"
  | "MNDA"
  | "BAA"
  | "EULA"
  | "Addendum"
  | "TOS"
  | "POC"
  | "SOW"
  | "PLA"
  | "Quote"
export interface ToolMinimal {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the tool's website */
  website: null | HttpUrlString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAsset
  id: string
  object_type: "Tool"
  tool_listing_id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
}
export interface ExtractionResult {
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date?: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date?: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to NOT renew before the next auto-renewal as an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed?: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  custom?: CustomFields
  document_type?: null | DocumentType
  domain?: null | NonEmptyTrimmedString
  tools?: ToolMinimal[]
  vendor?: null | VendorMinimal
  clauses?: {
    /** Whether the contract has an arbitration clause. The arbitration clause requires the parties to the contract to resolve any dispute arising out of the contract through an arbitration process, as opposed to litigation or mediation. */
    arbitration?: null | boolean
    /** Whether the contract has an assignment clause. The assignment clause indicates whether and when a party to the contract can assign their rights and obligations under the contract to someone else. */
    assignment?: null | boolean
    /** Whether the contract has a confidentiality clause. The confidentiality clause prevents one party (or both parties) from disclosing information related to the agreement, or other sensitive information. */
    confidentiality?: null | boolean
    /** Whether the contract has a data privacy clause. The data privacy clause may explain what private data will be collected, what the collecting party will do with the data, and/or how the data will be protected. */
    data_privacy?: null | boolean
    /** Whether the contract has a data processing clause. The data processing clause outlines the roles and responsibilities of the parties involved with regards to processing of personal data. It details the methods, purposes, and scope of the data processing activities carried out under the agreement. It may specify the rights and obligations of the data controller and data processor, and the safeguards and measures that must be in place to ensure data security and compliance with relevant data protection laws and regulations. */
    data_processing?: null | boolean
    /** Whether the contract has a data protection clause. The data protection clause ensures that the data given by one party to another is safeguarded from unlawful access by unauthorized users or entities. */
    data_protection?: null | boolean
    /** Whether the contract has a dispute resolution clause. The dispute resolution clause sets forth the circumstances under which any disputes between the parties should be resolved (e.g., whether the dispute should be resolved via arbitration, mediation, or litigation; jurisdiction, venue, etc.) */
    dispute_resolution?: null | boolean
    /** Whether the contract has a force majeure clause. The force majeure clause frees both parties from obligation if an extraordinary event directly prevents one or both parties from performing their obligations under the contract. */
    force_majeure?: null | boolean
    /** Whether the contract has a governing law clause. The governing law clause specifies which jurisdiction’s law will apply in the event of a dispute. */
    governing_law?: null | boolean
    /** Whether the contract has an indemnification clause. The indemnification clause obligates one party to compensate another party for certain costs and expenses, usually stemming from third party claims. */
    indemnification?: null | boolean
    /** Whether the contract has an intellectual property clause. The intellectual property clause sets forth ownership, permitted uses, and use restrictions of intellectual property. */
    intellectual_property?: null | boolean
    /** Whether the contract has a license clause. The license clause gives one party the right to use the product or services of the other party under certain conditions. */
    license?: null | boolean
    /** Whether the contract has a limitation of liability clause. The limitation of liability clause limits the amount and types of compensation one party can recover from the other party when a claim is made or lawsuit is filed in response to a breach of the contract. */
    limitation_of_liability?: null | boolean
    /** Whether the contract has a non disclosure clause. The non-disclosure clause prohibits the parties to the contract from releasing certain information to other parties. */
    non_disclosure?: null | boolean
    /** Whether the contract has a non solicitation clause. The non-solicitation clause prohibits a party from offering employment to the counterparty's employees, for a specified period of time. */
    non_solicitation?: null | boolean
    /** Whether the contract has a representation clause. The representations clause states that the representations contained in the contract are true and accurate as of the date of the agreement. */
    representation?: null | boolean
    /** Whether the contract has a termination clause. The termination clause explains what will happen if either party terminates the contract. The clause may also set forth the circumstances in which one party may terminate the contract, such as required notice. */
    termination?: null | boolean
    /** Whether the contract has a warranty clause. The warranty clause sets forth what promises, if any, are being made regarding the accuracy, completeness, or reliability of the subject matter of the contract, whether data, services, software, or otherwise. Warranty clauses can also specifically state that no such promises are being made or relied upon. */
    warranty?: null | boolean
  }
}
export interface DocumentWithExtraction {
  id: string
  object_type: "Document"
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  /** The original file basename as uploaded by the user. */
  file_name: null | NonEmptyTrimmedString
  status: "pending" | "uploaded" | "scanned" | "quarantined"
  extraction_status?: null | DocumentExtractionStatus
  /** The file size in bytes */
  file_size: null | number
  /** MIME type like `application/pdf` or `text/plain; charset=utf-8` */
  mime_type: null | NonEmptyTrimmedString
  /** Users who have email integrations which have found this document */
  user_email_integrations?: UserWithProvider[]
  extracted_source?: null | ExtractedSource
  extraction_result?: null | ExtractionResult
  summary?: null | NonEmptyTrimmedString
  associated_at?: null | DateTimeString
}
export interface WorkflowRunMinimal {
  id: string
  object_type: "WorkflowRun"
  display_name: NonEmptyTrimmedProseString
  created_at: DateTimeString
  kind: WorkflowKind
  status: WorkflowRunStatus
  completed_at: null | DateTimeString
  target_date: DateString
}
export type LegalAgreementSource = "workflow_run" | "email" | "manual" | "transaction"
export interface LegalAgreementListItem {
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to NOT renew before the next auto-renewal as an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  /** The legal clauses found in the contract. */
  clauses?: LegalClauses
  custom: CustomFields
  id: string
  object_type: "LegalAgreement"
  /** Whether the Legal Agreement is active, expired, or upcoming. Will be unknown if the agreement has not been verified or is missing start/end dates. */
  effective_status: AgreementEffectiveStatus
  /** The type of agreement (enterprise or subscription) */
  agreement_type: AgreementType
  /** The name of the agreement. */
  display_name: NonEmptyTrimmedProseString
  /** The next occurrence of the billing interval (based on the first invoice date) */
  next_invoice_date?: null | DateString
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  fields_metadata: LegalAgreementFieldsMetadata
  /** Legal agreements that enter through certain sources, like bulk upload or email, need to have their extracted fields verified. Manually created agreements always start verified. Verified agreements may become unverified when more documents are added. */
  verification_status: AgreementVerificationStatus
  visibility_status: AgreementVisibilityStatus
  /** Any documents related to this requests. Documents can include an Order Form, Statement of Work, a Quote, Master Services Agreements, etc. */
  documents: DocumentWithExtraction[]
  /** The vendor that the legal agreement is associated with */
  vendor: null | VendorMinimal
  /** The tools purchased with the legal agreement */
  tools: ToolMinimal[]
  /** The renewal started using this legal agreement as its 'active legal agreement' */
  renewal_successor?: null | WorkflowRunMinimal
  /** The user who added the legal agreement */
  created_by: null | User
  /** The cumulative list of sources which have provided information to this agreement */
  source: LegalAgreementSource[]
  /** Links to the purchase or renewal request if the agreement was created from one */
  workflow_run_source: null | WorkflowRunMinimal
  /** Email integrations that have found documents related to this agreement. */
  email_source: UserWithProvider[]
  /** The number of documents associated with this agreement */
  document_count: number
}
export type VendorStatus = "approved" | "in_review" | "unknown" | "canceling" | "churned" | "rejected"
export type VendorFinanceStatus = "approved" | "in_review" | "unknown" | "rejected"
export type VendorComplianceStatus = "approved" | "in_review" | "unknown" | "rejected"
export type VendorItStatus = "approved" | "in_review" | "unknown" | "rejected"
export type EmploymentIdentificationNumber = string
export type DunsNumber = string
export interface Vendor {
  /** The company name of the vendor */
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
  id: string
  object_type: "Vendor"
  vendor_listing_id: string
  created_at: DateTimeString
  /** The overall approval status of the vendor */
  status: VendorStatus
  /** The financial approval status of the vendor */
  finance_status?: VendorFinanceStatus
  /** The compliance approval status of the vendor */
  compliance_status?: VendorComplianceStatus
  /** The IT approval status of the vendor */
  it_status?: VendorItStatus
  /** The person in the organization responsible for the vendor */
  owner: null | Person
  hidden?: boolean
  organization_id: string
  /** The description of the vendor */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the vendor’s website */
  website: null | HttpUrlString
  custom: CustomFields
  /** The year the company was founded */
  founded_year: null | number
  /** Is the vendor a broker dealer? */
  is_broker_dealer: null | boolean
  /** The unique number assigned to a business entity by the Internal Revenue Service (IRS). Used for tax filing and reporting purposes */
  ein: null | EmploymentIdentificationNumber
  /** A nine-digit number assigned by Dun & Bradstreet for business identification and credit reporting */
  duns: null | DunsNumber
  /** Details on cybersecurity breaches that the company has experienced, if any. */
  cybersecurity_breaches: null | RichText
  /** Details of any ongoing or pending litigation involving the company, if applicable. */
  litigations: null | RichText
  /** The Non-Disclosure Agreement document for the vendor */
  nda: null | DocumentMinimal
  /** The vendor's bank information */
  bank_info: null | DocumentMinimal
  /** The vendor's W-9 */
  w9: null | DocumentMinimal
}
export interface Tool3 {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the tool's website */
  website: null | HttpUrlString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAsset
  id: string
  object_type: "Tool"
  tool_listing_id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  /** The overall approval status of the tool */
  status: ToolStatus
  /** The financial approval status of the tool */
  finance_status?: ToolFinanceStatus
  /** The compliance approval status of the tool */
  compliance_status?: ToolComplianceStatus
  /** The IT approval status of the tool */
  it_status?: ToolItStatus
  /** The person in the organization responsible for the tool */
  owner: null | Person
  fields_metadata?: WorkflowFieldsMetadata
  organization_id: string
  /** The tool categories that the tool falls into */
  categories: null | ToolCategory[]
  hidden?: boolean
  custom: CustomFields
  /** URL or Document containing the service’s privacy policy */
  privacy_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service’s terms of service */
  terms_of_service?: null | DocumentOrUrlString
  /** Risk level can be categorized as:
    
    - **High**: Vendor handles sensitive data or security services; critical business impact if they fail.
    - **Moderate**: Vendor doesn’t handle sensitive data or security; moderate business impact if they fail.
    - **Low**: Vendor has minimal involvement and little to no business impact if they fail.
    - **None**: Vendor has no impact on business operations. */
  customer_security_impact_level?: null | CustomerSecurityImpact
  /** A critical vendor has one or more of the following characteristics:
    
    1) Handles customer data,
    2) Is responsible for our infrastructure or code security,
    3) Has cloud services that affect our system’s security or availability,
    4) Whose failure would result in disruption to our service availability. */
  is_critical?: null | boolean
  /** Whether the product or service is a data subprocessor */
  is_subprocessor?: null | boolean
  /** Link to a vendor’s list of third-party subprocessors */
  subprocessors?: null | HttpUrlString
  /** Whether the product or service has SOC 2 compliance certification */
  soc2?: null | CompliantWithDocument
  /** Whether the product or service has GDPR compliance certification */
  gdpr?: null | CompliantWithDocument
  /** Whether the product or service has HIPAA compliance certification */
  hipaa?: null | CompliantWithDocument
  /** Whether the product or service has ISO 27001 compliance certification */
  iso_27001?: null | CompliantWithDocument
  /** Whether the product or service has PCI DSS compliance certification */
  pci_dss?: null | CompliantWithDocument
  /** Whether the product or service has CCPA compliance certification */
  ccpa?: null | CompliantWithDocument
  /** Whether the product or service has CDPA compliance certification */
  cdpa?: null | CompliantWithDocument
  /** Whether the product or service has CMMC 2.0 compliance certification */
  cmmc_2?: null | CompliantWithDocument
  /** Whether the product or service has CPA compliance certification */
  cpa?: null | CompliantWithDocument
  /** Whether the product or service has CPRA compliance certification */
  cpra?: null | CompliantWithDocument
  /** Whether the product or service has CTDPA compliance certification */
  ctdpa?: null | CompliantWithDocument
  /** Whether the product or service has EU Whistleblower compliance certification */
  eu_whistleblower?: null | CompliantWithDocument
  /** Whether the product or service has Nevada Privacy Law compliance certification */
  nevada_privacy_law?: null | CompliantWithDocument
  /** Whether the product or service has SOX compliance certification */
  sox?: null | CompliantWithDocument
  /** Whether the product or service has UCPA compliance certification */
  ucpa?: null | CompliantWithDocument
  /** Whether the product or service is SEA Rule 17(a)4f compliant */
  sea_rule_17a4f?: null | boolean
  /** Whether the product or service stores data in WORM format */
  worm?: null | ApplicableWithDocument
  /** URL or Document containing the service's cybersecurity policy */
  cybersecurity_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's information security policy */
  information_security_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's Anti-Money Laundering policy */
  aml_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's financial stability evidence */
  financial_stability?: null | DocumentOrUrlString
  /** The Data Processing Agreement for a tool */
  dpa?: null | DocumentMinimal
  /** Whether the product or service stores personal identifying information (PII) */
  stores_pii?: null | boolean
  /** The types of personal identifiable information (PII) the product or service collects or retains */
  pii_data_types?: null | PiiDataType[]
  /** The types of people whose personal identifiable information (PII) the product or service collects or retains */
  pii_person_types?: null | PiiPersonType[]
  /** The regions whose personal identifiable information (PII) the product or service collects or retains */
  pii_regions?: null | PiiRegion[]
  /** The types of data the product or service collects or retains */
  data_stored?: null | ComplianceData[]
  /** Lists the specific Single Sign-On (SSO) methods the software supports */
  sso_types?: null | SsoType[]
  /** Lists the specific Multi-Factor Authentication methods the software supports */
  mfa_types?: null | MfaType[]
  /** Describes the specific methods used by the software to regulate data access */
  access_control_types?: null | AccessControlType[]
  /** Indicates how the software is deployed and accessed */
  deployment_types?: null | SoftwareDeploymentType[]
  /** Determines if the software provides System for Cross-domain Identity Management (SCIM) functionality */
  scim?: null | boolean
  /** Which other tools does this product integrate with? */
  integrates_with?: null | ToolListing[]
  /** The requested spend for the tool */
  requested_spend?: null | CurrencyAmount
  /** The frequency of spend requested for the tool */
  requested_spend_interval?: null | FrequencyOrOneTime
  /** What problem(s) will this tool solve and how? */
  overview: null | RichText
  /** Why are you choosing this product over alternative tools and services? */
  selection_rationale: null | RichText
  /** What are the areas of concern or gaps this tool has? */
  risks_limitations: null | RichText
  /** What’s the estimated return on investment (ROI) for this tool? */
  roi_analysis: null | RichText
  /** How long will it take to implement and fully deploy? */
  implementation_details: null | RichText
  /** What level of support is provided by the vendor for the selected plan? */
  support_from_vendor: null | RichText
  /** What other tools and services were considered during the evaluation of this tool? */
  tools_considered: ToolListing[]
}
export interface WorkflowRunProgress {
  status: WorkflowRunStatus
  approvals_required: number
  approvals_given: number
}
export interface WorkflowRunListItem {
  id: string
  object_type: "WorkflowRun"
  display_name: NonEmptyTrimmedProseString
  created_at: DateTimeString
  kind: WorkflowKind
  status: WorkflowRunStatus
  completed_at: null | DateTimeString
  target_date: DateString
  created_by: User
  assigned_to: User[]
  owner: User
  employee_groups: EmployeeGroup[]
  /** The vendor that the request is for. Note: Since requests do not directly connect to vendors, the vendor comes from the purchase or renewal's new_legal_agreement. */
  vendor: null | Vendor
  /** The tools that the request is for. Note: Since requests do not directly connect to tools, the tools come from the purchase or renewal's new_legal_agreement. */
  tools: Tool3[]
  progress: WorkflowRunProgress
  /** What problem(s) will this tool solve and how? */
  overview: null | RichText
}
export interface OktaUsersCount {
  l30d: number
  l60d: number
  l90d: number
  total: number
}
export interface Scope {
  scope: NonEmptyString
  description: null | NonEmptyTrimmedProseString
  provider: NonEmptyString
  resource_group: null | NonEmptyString
  risk_score: number
}
export type MergeIntegrationHrisType =
  | "bamboohr"
  | "justworks"
  | "gusto"
  | "rippling"
  | "hibob"
  | "workday"
  | "paylocity"
  | "ukg-pro"
  | "ukg-ready"
  | "ukg-pro-workforce-management"
  | "adp-workforce-now"
  | "deel"
  | "namely"
  | "zenefits"
  | "charthop"
export type MergeIntegrationAccountingType = "netsuite" | "quickbooks-online" | "sage-intacct"
export interface IntegrationPublicFields {
  id: string
  object_type: "Integration"
  organization_id: string
  created_at: DateTimeString
  display_name: NonEmptyTrimmedProseString
  provider: IntegrationProvider
  external_id: null | NonEmptyString
  merge_hris_type: null | MergeIntegrationHrisType
  merge_accounting_type: null | MergeIntegrationAccountingType
  logo: null | NonEmptyString
  email: null | NonEmptyString
}
export interface Tool2 {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the tool's website */
  website: null | HttpUrlString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAsset
  id: string
  object_type: "Tool"
  tool_listing_id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  /** The overall approval status of the tool */
  status: ToolStatus
  /** The financial approval status of the tool */
  finance_status?: ToolFinanceStatus
  /** The compliance approval status of the tool */
  compliance_status?: ToolComplianceStatus
  /** The IT approval status of the tool */
  it_status?: ToolItStatus
  /** The person in the organization responsible for the tool */
  owner: null | Person
  fields_metadata?: WorkflowFieldsMetadata
  organization_id: string
  /** The tool categories that the tool falls into */
  categories: null | ToolCategory[]
  hidden?: boolean
  custom: CustomFields
  /** URL or Document containing the service’s privacy policy */
  privacy_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service’s terms of service */
  terms_of_service?: null | DocumentOrUrlString
  /** Risk level can be categorized as:
    
    - **High**: Vendor handles sensitive data or security services; critical business impact if they fail.
    - **Moderate**: Vendor doesn’t handle sensitive data or security; moderate business impact if they fail.
    - **Low**: Vendor has minimal involvement and little to no business impact if they fail.
    - **None**: Vendor has no impact on business operations. */
  customer_security_impact_level?: null | CustomerSecurityImpact
  /** A critical vendor has one or more of the following characteristics:
    
    1) Handles customer data,
    2) Is responsible for our infrastructure or code security,
    3) Has cloud services that affect our system’s security or availability,
    4) Whose failure would result in disruption to our service availability. */
  is_critical?: null | boolean
  /** Whether the product or service is a data subprocessor */
  is_subprocessor?: null | boolean
  /** Link to a vendor’s list of third-party subprocessors */
  subprocessors?: null | HttpUrlString
  /** Whether the product or service has SOC 2 compliance certification */
  soc2?: null | CompliantWithDocument
  /** Whether the product or service has GDPR compliance certification */
  gdpr?: null | CompliantWithDocument
  /** Whether the product or service has HIPAA compliance certification */
  hipaa?: null | CompliantWithDocument
  /** Whether the product or service has ISO 27001 compliance certification */
  iso_27001?: null | CompliantWithDocument
  /** Whether the product or service has PCI DSS compliance certification */
  pci_dss?: null | CompliantWithDocument
  /** Whether the product or service has CCPA compliance certification */
  ccpa?: null | CompliantWithDocument
  /** Whether the product or service has CDPA compliance certification */
  cdpa?: null | CompliantWithDocument
  /** Whether the product or service has CMMC 2.0 compliance certification */
  cmmc_2?: null | CompliantWithDocument
  /** Whether the product or service has CPA compliance certification */
  cpa?: null | CompliantWithDocument
  /** Whether the product or service has CPRA compliance certification */
  cpra?: null | CompliantWithDocument
  /** Whether the product or service has CTDPA compliance certification */
  ctdpa?: null | CompliantWithDocument
  /** Whether the product or service has EU Whistleblower compliance certification */
  eu_whistleblower?: null | CompliantWithDocument
  /** Whether the product or service has Nevada Privacy Law compliance certification */
  nevada_privacy_law?: null | CompliantWithDocument
  /** Whether the product or service has SOX compliance certification */
  sox?: null | CompliantWithDocument
  /** Whether the product or service has UCPA compliance certification */
  ucpa?: null | CompliantWithDocument
  /** Whether the product or service is SEA Rule 17(a)4f compliant */
  sea_rule_17a4f?: null | boolean
  /** Whether the product or service stores data in WORM format */
  worm?: null | ApplicableWithDocument
  /** URL or Document containing the service's cybersecurity policy */
  cybersecurity_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's information security policy */
  information_security_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's Anti-Money Laundering policy */
  aml_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's financial stability evidence */
  financial_stability?: null | DocumentOrUrlString
  /** The Data Processing Agreement for a tool */
  dpa?: null | DocumentMinimal
  /** Whether the product or service stores personal identifying information (PII) */
  stores_pii?: null | boolean
  /** The types of personal identifiable information (PII) the product or service collects or retains */
  pii_data_types?: null | PiiDataType[]
  /** The types of people whose personal identifiable information (PII) the product or service collects or retains */
  pii_person_types?: null | PiiPersonType[]
  /** The regions whose personal identifiable information (PII) the product or service collects or retains */
  pii_regions?: null | PiiRegion[]
  /** The types of data the product or service collects or retains */
  data_stored?: null | ComplianceData[]
  /** Lists the specific Single Sign-On (SSO) methods the software supports */
  sso_types?: null | SsoType[]
  /** Lists the specific Multi-Factor Authentication methods the software supports */
  mfa_types?: null | MfaType[]
  /** Describes the specific methods used by the software to regulate data access */
  access_control_types?: null | AccessControlType[]
  /** Indicates how the software is deployed and accessed */
  deployment_types?: null | SoftwareDeploymentType[]
  /** Determines if the software provides System for Cross-domain Identity Management (SCIM) functionality */
  scim?: null | boolean
  /** Which other tools does this product integrate with? */
  integrates_with?: null | ToolListing[]
  /** The requested spend for the tool */
  requested_spend?: null | CurrencyAmount
  /** The frequency of spend requested for the tool */
  requested_spend_interval?: null | FrequencyOrOneTime
  /** What problem(s) will this tool solve and how? */
  overview: null | RichText
  /** Why are you choosing this product over alternative tools and services? */
  selection_rationale: null | RichText
  /** What are the areas of concern or gaps this tool has? */
  risks_limitations: null | RichText
  /** What’s the estimated return on investment (ROI) for this tool? */
  roi_analysis: null | RichText
  /** How long will it take to implement and fully deploy? */
  implementation_details: null | RichText
  /** What level of support is provided by the vendor for the selected plan? */
  support_from_vendor: null | RichText
  /** What other tools and services were considered during the evaluation of this tool? */
  tools_considered: ToolListing[]
  /** The vendor offering the tool */
  vendor: VendorMinimal
  /** The number of users that have accessed the tool */
  total_people: null | number
  /** The department classification for the tool owner */
  owner_groups: EmployeeGroup[]
  /** The primary legal agreement with the tool’s vendor for the tool */
  primary_legal_agreement: null | LegalAgreementMinimal
  /** The number of active legal agreements on the tool */
  active_legal_agreement_count: number
  /** The aggregated spent on the tool/vendor over various time periods */
  spend?: AggregatedSpend
  /** The method used to pay (e.g. bill pay or credit card) */
  payment_methods?: null | TransactionPaymentMethod[]
  /** The department or class categorization for the tool from your accounting system */
  erp_tracking_categories: ErpTrackingCategoryGroups
  listing: {
    /** URL or Document containing the service’s privacy policy */
    privacy_policy?: null | DocumentOrUrlString
    /** URL or Document containing the service’s terms of service */
    terms_of_service?: null | DocumentOrUrlString
    /** Link to a vendor’s list of third-party subprocessors */
    subprocessors?: null | HttpUrlString
    /** Whether the product or service is SEA Rule 17(a)4f compliant */
    sea_rule_17a4f?: null | boolean
    /** URL or Document containing the service's cybersecurity policy */
    cybersecurity_policy?: null | DocumentOrUrlString
    /** URL or Document containing the service's information security policy */
    information_security_policy?: null | DocumentOrUrlString
    /** URL or Document containing the service's Anti-Money Laundering policy */
    aml_policy?: null | DocumentOrUrlString
    /** URL or Document containing the service's financial stability evidence */
    financial_stability?: null | DocumentOrUrlString
    /** The Data Processing Agreement for a tool */
    dpa?: null | DocumentMinimal
    /** Whether the product or service stores personal identifying information (PII) */
    stores_pii?: null | boolean
    /** The types of personal identifiable information (PII) the product or service collects or retains */
    pii_data_types?: null | PiiDataType[]
    /** The types of people whose personal identifiable information (PII) the product or service collects or retains */
    pii_person_types?: null | PiiPersonType[]
    /** The regions whose personal identifiable information (PII) the product or service collects or retains */
    pii_regions?: null | PiiRegion[]
    /** The types of data the product or service collects or retains */
    data_stored?: null | ComplianceData[]
    soc2: null | ListingComplianceDocument
    gdpr: null | ListingComplianceDocument
    hipaa: null | ListingComplianceDocument
    iso_27001: null | ListingComplianceDocument
    pci_dss: null | ListingComplianceDocument
    ccpa: null | ListingComplianceDocument
    cdpa: null | ListingComplianceDocument
    cmmc_2: null | ListingComplianceDocument
    cpa: null | ListingComplianceDocument
    cpra: null | ListingComplianceDocument
    ctdpa: null | ListingComplianceDocument
    eu_whistleblower: null | ListingComplianceDocument
    nevada_privacy_law: null | ListingComplianceDocument
    sox: null | ListingComplianceDocument
    ucpa: null | ListingComplianceDocument
    worm: null | {
      /** Whether the standard is applicable */
      applicable: null | boolean
    }
    /** Lists the specific Single Sign-On (SSO) methods the software supports */
    sso_types?: null | SsoType[]
    /** Lists the specific Multi-Factor Authentication methods the software supports */
    mfa_types?: null | MfaType[]
    /** Describes the specific methods used by the software to regulate data access */
    access_control_types?: null | AccessControlType[]
    /** Indicates how the software is deployed and accessed */
    deployment_types?: null | SoftwareDeploymentType[]
    /** Determines if the software provides System for Cross-domain Identity Management (SCIM) functionality */
    scim?: null | boolean
    /** Which other tools does this product integrate with? */
    integrates_with?: null | ToolListing[]
  }
  /** The legal agreements with the tool’s vendor for this tool */
  legal_agreements: LegalAgreementListItem[]
  workflow_runs: WorkflowRunListItem[]
  following: boolean
  okta_users_count: OktaUsersCount
  scope_summary: {
    [key: string]: Scope[]
  }
  /** The integrations that this tool was detected through */
  integrations: IntegrationPublicFields[]
  department_users: {
    [key: string]: {
      id: string
      object_type: "EmployeeGroup"
      display_name: null | NonEmptyTrimmedString
      group_type: null | EmployeeGroupType
      count: number
    }
  }
}
export type LoginEventType = "authorize" | "revoke" | "authenticate"
export interface LoginScope {
  /** The same as `scope` */
  display_name: NonEmptyString
  scope: NonEmptyString
}
export interface LoginMinimal {
  object_type: "Login"
  organization_id: string
  created_at: DateTimeString
  authorized_at: DateTimeString
  event_type: LoginEventType
  app_name: NonEmptyString
  integration: IntegrationPublicFields
  person: Person
  external_event_id: NonEmptyString
  scopes: LoginScope[]
}
export interface LoginSummaryResponse {
  logins: LoginMinimal[]
  total: number
}
export interface HrisGroups {
  department: {
    id: string
    object_type: "EmployeeGroup"
    display_name: null | NonEmptyTrimmedString
    group_type: "department"
  }[]
  team: {
    id: string
    object_type: "EmployeeGroup"
    display_name: null | NonEmptyTrimmedString
    group_type: "team"
  }[]
}
export interface PersonListItem {
  id: string
  object_type: "Person"
  display_name: NonEmptyTrimmedString
  organization_id: string
  user_id: null | string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: null | EmailString
  profile_image: null | ImageAsset
  employment_status: null | ("active" | "inactive" | "pending")
  created_at: DateTimeString
  tool_count: number
  sources: IntegrationPublicFields[]
  last_login: null | {
    authorized_at: DateTimeString
    event_type: LoginEventType
  }
  hris_groups: HrisGroups
  user: null | User
  manager: null | Person
}
export interface PersonListItemResponse {
  total: number
  persons: PersonListItem[]
}
export interface LegalAgreementListResponse {
  items: LegalAgreementListItem[]
  total: number
}
export type LegalAgreementWithToolsList = {
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to NOT renew before the next auto-renewal as an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  /** The legal clauses found in the contract. */
  clauses?: LegalClauses
  custom: CustomFields
  id: string
  object_type: "LegalAgreement"
  /** Whether the Legal Agreement is active, expired, or upcoming. Will be unknown if the agreement has not been verified or is missing start/end dates. */
  effective_status: AgreementEffectiveStatus
  /** The type of agreement (enterprise or subscription) */
  agreement_type: AgreementType
  /** The name of the agreement. */
  display_name: NonEmptyTrimmedProseString
  /** The next occurrence of the billing interval (based on the first invoice date) */
  next_invoice_date?: null | DateString
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  fields_metadata: LegalAgreementFieldsMetadata
  /** Legal agreements that enter through certain sources, like bulk upload or email, need to have their extracted fields verified. Manually created agreements always start verified. Verified agreements may become unverified when more documents are added. */
  verification_status: AgreementVerificationStatus
  visibility_status: AgreementVisibilityStatus
  tools: Tool3[]
}[]
export interface Receipt {
  id: string
  object_type: "Receipt"
  document: DocumentMinimal
}
export type TransactionCategory =
  | "advertising"
  | "airlines"
  | "alcohol_and_bars"
  | "books_and_newspapers"
  | "car_rental"
  | "car_services"
  | "charitable_donations"
  | "clothing"
  | "clubs_and_memberships"
  | "education"
  | "electronics"
  | "entertainment"
  | "fees_and_financial_institutions"
  | "fines"
  | "freight_moving_and_delivery_services"
  | "fuel_and_gas"
  | "gambling"
  | "general_merchandise"
  | "government_services"
  | "insurance"
  | "internet_and_phone"
  | "legal"
  | "lodging"
  | "medical"
  | "office"
  | "office_supplies_and_cleaning"
  | "other"
  | "parking"
  | "pet"
  | "political_organizations"
  | "professional_services"
  | "religious_organizations"
  | "restaurants"
  | "saas_software"
  | "shipping"
  | "streaming_services"
  | "supermarkets_and_grocery_stores"
  | "taxes_and_tax_preparation"
  | "taxi_and_rideshare"
  | "travel"
  | "utilities"
export interface TransactionMinimal {
  object_type: "ReconciledTransaction"
  id: string
  organization_id: string
  created_at: DateTimeString
  currency_amount: CurrencyAmount
  transacted_at: DateTimeString
  merchant_identification: NonEmptyTrimmedString
  merchant_name: NonEmptyString
  memo: null | NonEmptyTrimmedString
  person: null | Person
  vendor: VendorMinimal
  tool: null | ToolMinimal
  receipts: Receipt[]
  transaction_category: null | TransactionCategory
  payment_method: null | TransactionPaymentMethod
  integrations: IntegrationPublicFields[]
}
export interface LegalAgreementDetails {
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to NOT renew before the next auto-renewal as an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  /** The legal clauses found in the contract. */
  clauses?: LegalClauses
  custom: CustomFields
  id: string
  object_type: "LegalAgreement"
  /** Whether the Legal Agreement is active, expired, or upcoming. Will be unknown if the agreement has not been verified or is missing start/end dates. */
  effective_status: AgreementEffectiveStatus
  /** The type of agreement (enterprise or subscription) */
  agreement_type: AgreementType
  /** The name of the agreement. */
  display_name: NonEmptyTrimmedProseString
  /** The next occurrence of the billing interval (based on the first invoice date) */
  next_invoice_date?: null | DateString
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  fields_metadata: LegalAgreementFieldsMetadata
  /** Legal agreements that enter through certain sources, like bulk upload or email, need to have their extracted fields verified. Manually created agreements always start verified. Verified agreements may become unverified when more documents are added. */
  verification_status: AgreementVerificationStatus
  visibility_status: AgreementVisibilityStatus
  /** Any documents related to this requests. Documents can include an Order Form, Statement of Work, a Quote, Master Services Agreements, etc. */
  documents: DocumentWithExtraction[]
  /** The vendor that the legal agreement is associated with */
  vendor: null | VendorMinimal
  /** The tools purchased with the legal agreement */
  tools: ToolMinimal[]
  /** The renewal started using this legal agreement as its 'active legal agreement' */
  renewal_successor?: null | WorkflowRunMinimal
  /** The user who added the legal agreement */
  created_by: null | User
  /** The cumulative list of sources which have provided information to this agreement */
  source: LegalAgreementSource[]
  /** Links to the purchase or renewal request if the agreement was created from one */
  workflow_run_source: null | WorkflowRunMinimal
  /** Email integrations that have found documents related to this agreement. */
  email_source: UserWithProvider[]
  /** The number of documents associated with this agreement */
  document_count: number
  transactions?: TransactionMinimal[]
}
export interface LegalAgreement {
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to NOT renew before the next auto-renewal as an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  /** The legal clauses found in the contract. */
  clauses?: LegalClauses
  custom: CustomFields
  id: string
  object_type: "LegalAgreement"
  /** Whether the Legal Agreement is active, expired, or upcoming. Will be unknown if the agreement has not been verified or is missing start/end dates. */
  effective_status: AgreementEffectiveStatus
  /** The type of agreement (enterprise or subscription) */
  agreement_type: AgreementType
  /** The name of the agreement. */
  display_name: NonEmptyTrimmedProseString
  /** The next occurrence of the billing interval (based on the first invoice date) */
  next_invoice_date?: null | DateString
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  fields_metadata: LegalAgreementFieldsMetadata
  /** Legal agreements that enter through certain sources, like bulk upload or email, need to have their extracted fields verified. Manually created agreements always start verified. Verified agreements may become unverified when more documents are added. */
  verification_status: AgreementVerificationStatus
  visibility_status: AgreementVisibilityStatus
  /** Any documents related to this requests. Documents can include an Order Form, Statement of Work, a Quote, Master Services Agreements, etc. */
  documents: DocumentWithExtraction[]
  /** The vendor the legal agreement is with */
  vendor: null | Vendor
  /** The tools purchased with the legal agreement */
  tools: Tool3[]
  transactions?: TransactionMinimal[]
}
export interface AssignedByMetadataInput {
  /** The source from the document assigned by the user as provenance. */
  source: Source
  object_field_source: {
    id: string
    type: "document" | "transaction"
  }
}
export interface FieldMetadata {
  /** The field source provenance type. */
  type?: FieldSourceType
  /** Unique identifier associated to the field source type. (eg. type = 'user' and id = user_id) */
  id?: null | string
  updated_at?: null | DateTimeString
  derived_from?: NonEmptyTrimmedString[]
  /** Only used when type = 'listing' */
  rationale?: NonEmptyTrimmedString
  links?: HttpUrlString[]
  assigned_by_metadata?: null | AssignedByMetadataInput
  verified?: boolean
  verified_at?: null | DateTimeString
  verified_by?: null | string
}
export type LegalClausesInputFieldsMetadata = any | any
export interface LegalAgreementInputFieldsMetadata {
  total_contract_value?: FieldMetadata
  start_date?: FieldMetadata
  end_date?: FieldMetadata
  first_invoice_date?: FieldMetadata
  invoice_interval?: FieldMetadata
  net_payment_term?: FieldMetadata
  auto_renews?: FieldMetadata
  auto_renewal_opt_out_period?: FieldMetadata
  decision_date?: FieldMetadata
  is_signed?: FieldMetadata
  buyer_signer_name?: FieldMetadata
  buyer_signer_title?: FieldMetadata
  vendor_signer_name?: FieldMetadata
  vendor_signer_title?: FieldMetadata
  vendor?: FieldMetadata
  tools?: FieldMetadata
  display_name?: FieldMetadata
  agreement_type?: FieldMetadata
  clauses?: LegalClausesInputFieldsMetadata
  custom?: {
    [key: string]: FieldMetadata
  }
}
export interface ToolOption {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the tool's website */
  website: null | HttpUrlString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAsset
  id: null | string
  object_type: "Tool"
  tool_listing_id: string
}
export interface DocumentReference {
  id: string
  object_type: "Document"
}
export interface SubscriptionLegalAgreementInput {
  agreement_type: "subscription"
  object_type: "LegalAgreement"
  display_name: NonEmptyTrimmedProseString
  fields_metadata: LegalAgreementInputFieldsMetadata
  tools?: ToolOption[]
  vendor?: null | VendorOption
  custom: CustomFields
  documents: DocumentReference[]
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date?: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date?: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to NOT renew before the next auto-renewal as an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
}
export interface EnterpriseLegalAgreementInput {
  agreement_type: "enterprise"
  object_type: "LegalAgreement"
  display_name: NonEmptyTrimmedProseString
  fields_metadata: LegalAgreementInputFieldsMetadata
  tools?: ToolOption[]
  vendor?: null | VendorOption
  custom: CustomFields & CustomFields
  documents: DocumentReference[]
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date?: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date?: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to NOT renew before the next auto-renewal as an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed?: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  /** The legal clauses found in the contract. */
  clauses?: LegalClauses
}
export type LegalAgreementInput =
  | ({
      agreement_type: "subscription"
    } & SubscriptionLegalAgreementInput)
  | ({
      agreement_type: "enterprise"
    } & EnterpriseLegalAgreementInput)
export interface AgreementVerificationRequest {
  input: LegalAgreementInput
  field_path?: (string | number)[]
}
export interface VendorListItem {
  /** The company name of the vendor */
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
  id: string
  object_type: "Vendor"
  vendor_listing_id: string
  created_at: DateTimeString
  /** The overall approval status of the vendor */
  status: VendorStatus
  /** The financial approval status of the vendor */
  finance_status?: VendorFinanceStatus
  /** The compliance approval status of the vendor */
  compliance_status?: VendorComplianceStatus
  /** The IT approval status of the vendor */
  it_status?: VendorItStatus
  /** The person in the organization responsible for the vendor */
  owner: null | Person
  hidden?: boolean
  organization_id: string
  /** The description of the vendor */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the vendor’s website */
  website: null | HttpUrlString
  custom: CustomFields
  /** The year the company was founded */
  founded_year: null | number
  /** Is the vendor a broker dealer? */
  is_broker_dealer: null | boolean
  /** The unique number assigned to a business entity by the Internal Revenue Service (IRS). Used for tax filing and reporting purposes */
  ein: null | EmploymentIdentificationNumber
  /** A nine-digit number assigned by Dun & Bradstreet for business identification and credit reporting */
  duns: null | DunsNumber
  /** Details on cybersecurity breaches that the company has experienced, if any. */
  cybersecurity_breaches: null | RichText
  /** Details of any ongoing or pending litigation involving the company, if applicable. */
  litigations: null | RichText
  /** The Non-Disclosure Agreement document for the vendor */
  nda: null | DocumentMinimal
  /** The vendor's bank information */
  bank_info: null | DocumentMinimal
  /** The vendor's W-9 */
  w9: null | DocumentMinimal
  /** The number of tools used by the organization from the vendor */
  tool_count: number
  /** The number of active legal agreements on the vendor */
  active_legal_agreement_count: number
  /** The department classification for the vendor owner */
  owner_groups: EmployeeGroup[]
  /** The primary legal agreement between the organization and the vendor */
  primary_legal_agreement: null | LegalAgreementMinimal
  /** The vendor categories that the vendor falls into */
  vendor_categories: null | TransactionCategory[]
  /** The aggregated spent on the tool/vendor over various time periods */
  spend?: AggregatedSpend
  /** The method used to pay (e.g. bill pay or credit card) */
  payment_methods?: null | TransactionPaymentMethod[]
  /** The department or class categorization for the tool from your accounting system */
  erp_tracking_categories: ErpTrackingCategoryGroups
}
export interface VendorListItemResponse {
  vendors: VendorListItem[]
  total: number
}
export interface VendorOptionWithTools {
  /** The company name of the vendor */
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
  id: null | string
  object_type: "Vendor"
  vendor_listing_id: string
  /** The tools array is optional because on the initial load of the form, the saved VendorOption does not get returned with the tools array. */
  tools?: ToolOption[]
}
export interface VendorPatch {
  id: string
  object_type: "Vendor"
  /** The overall approval status of the vendor */
  status?: VendorStatus
  /** The financial approval status of the vendor */
  finance_status?: VendorFinanceStatus
  /** The compliance approval status of the vendor */
  compliance_status?: VendorComplianceStatus
  /** The IT approval status of the vendor */
  it_status?: VendorItStatus
  hidden?: boolean
  custom?: CustomFields
  /** The year the company was founded */
  founded_year?: null | number
  /** Is the vendor a broker dealer? */
  is_broker_dealer?: null | boolean
  /** The unique number assigned to a business entity by the Internal Revenue Service (IRS). Used for tax filing and reporting purposes */
  ein?: null | EmploymentIdentificationNumber
  /** A nine-digit number assigned by Dun & Bradstreet for business identification and credit reporting */
  duns?: null | DunsNumber
  /** Details on cybersecurity breaches that the company has experienced, if any. */
  cybersecurity_breaches?: null | RichText
  /** Details of any ongoing or pending litigation involving the company, if applicable. */
  litigations?: null | RichText
  /** The Non-Disclosure Agreement document for the vendor */
  nda?: null | DocumentMinimal
  /** The vendor's bank information */
  bank_info?: null | DocumentMinimal
  /** The vendor's W-9 */
  w9?: null | DocumentMinimal
  owner?: null | {
    id: string
  }
}
export interface VendorTool {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAsset
  id: string
  /** The person in the organization responsible for the tool */
  owner: null | Person
}
export interface VendorDetails {
  /** The company name of the vendor */
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
  id: string
  object_type: "Vendor"
  vendor_listing_id: string
  created_at: DateTimeString
  /** The overall approval status of the vendor */
  status: VendorStatus
  /** The financial approval status of the vendor */
  finance_status?: VendorFinanceStatus
  /** The compliance approval status of the vendor */
  compliance_status?: VendorComplianceStatus
  /** The IT approval status of the vendor */
  it_status?: VendorItStatus
  /** The person in the organization responsible for the vendor */
  owner: null | Person
  hidden?: boolean
  organization_id: string
  /** The description of the vendor */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the vendor’s website */
  website: null | HttpUrlString
  custom: CustomFields
  /** The year the company was founded */
  founded_year: null | number
  /** Is the vendor a broker dealer? */
  is_broker_dealer: null | boolean
  /** The unique number assigned to a business entity by the Internal Revenue Service (IRS). Used for tax filing and reporting purposes */
  ein: null | EmploymentIdentificationNumber
  /** A nine-digit number assigned by Dun & Bradstreet for business identification and credit reporting */
  duns: null | DunsNumber
  /** Details on cybersecurity breaches that the company has experienced, if any. */
  cybersecurity_breaches: null | RichText
  /** Details of any ongoing or pending litigation involving the company, if applicable. */
  litigations: null | RichText
  /** The Non-Disclosure Agreement document for the vendor */
  nda: null | DocumentMinimal
  /** The vendor's bank information */
  bank_info: null | DocumentMinimal
  /** The vendor's W-9 */
  w9: null | DocumentMinimal
  /** The number of tools used by the organization from the vendor */
  tool_count: number
  /** The number of active legal agreements on the vendor */
  active_legal_agreement_count: number
  /** The department classification for the vendor owner */
  owner_groups: EmployeeGroup[]
  /** The primary legal agreement between the organization and the vendor */
  primary_legal_agreement: null | LegalAgreementMinimal
  /** The vendor categories that the vendor falls into */
  vendor_categories: null | TransactionCategory[]
  /** The aggregated spent on the tool/vendor over various time periods */
  spend?: AggregatedSpend
  /** The method used to pay (e.g. bill pay or credit card) */
  payment_methods?: null | TransactionPaymentMethod[]
  /** The department or class categorization for the tool from your accounting system */
  erp_tracking_categories: ErpTrackingCategoryGroups
  workflow_runs: WorkflowRunListItem[]
  legal_agreements: LegalAgreementListItem[]
  following: boolean
  tools: VendorTool[]
  integrations: IntegrationPublicFields[]
}
export type IntegrationStatus =
  | "connected"
  | "disconnected"
  | "connecting"
  | "failing"
  | "deleting"
  | "resetting"
  | "disabled"
export interface CreateApiKeyIntegration {
  auth_type: "apikey"
  api_key: NonEmptyString
  display_name: NonEmptyTrimmedProseString
  provider: "brex_apikey" | "mercury_apikey" | "okta_apikey"
  resource_domain?: NonEmptyString
}
export interface CreateOAuthAuthorizationCodeIntegration {
  auth_type: "oauth_authorization_code"
  display_name: NonEmptyTrimmedProseString
  provider: "ramp_oauth" | "google_oauth" | "gmail_oauth" | "brex_oauth" | "quickbooks_oauth" | "slack_oauth"
  access_token: null | NonEmptyString
  external_id: null | NonEmptyString
}
export interface CreateOAuthClientCredsIntegration {
  auth_type: "oauth_client_credentials"
  display_name: NonEmptyTrimmedProseString
  provider: "ramp_client_creds"
  client_id: NonEmptyString
  client_secret: NonEmptyString
}
export interface CreateHrisLinkTokenApiIntegration {
  auth_type: "hris_link_token"
  display_name: NonEmptyTrimmedProseString
  provider: "merge_hris_link_token"
  public_token: NonEmptyString
  merge_hris_type: MergeIntegrationHrisType
  access_token: null | NonEmptyString
  origin_id: NonEmptyString
}
export interface CreateAccountingLinkTokenApiIntegration {
  auth_type: "accounting_link_token"
  display_name: NonEmptyTrimmedProseString
  provider: "merge_accounting_link_token"
  public_token: NonEmptyString
  merge_accounting_type: MergeIntegrationAccountingType
  access_token: null | NonEmptyString
  origin_id: NonEmptyString
}
export interface CreateDomainPrivateKeyIntegration {
  auth_type: "domain_private_key"
  display_name: NonEmptyTrimmedProseString
  provider: "okta_oauth"
  resource_domain: NonEmptyString
  private_key: NonEmptyString
  client_id: NonEmptyString
}
export interface PlaidAccount {
  id: NonEmptyString
  name: NonEmptyString
  mask: null | NonEmptyString
  type: NonEmptyString
  subtype: NonEmptyString
}
export interface CreatePlaidAccessTokenIntegration {
  auth_type: "plaid_access_token"
  display_name: NonEmptyTrimmedProseString
  provider: "plaid_access_token"
  institution_id: NonEmptyString
  institution_name: NonEmptyString
  accounts: PlaidAccount[]
  public_token: NonEmptyString
  link_session_id: NonEmptyString
}
export type CreateIntegration =
  | ({
      auth_type: "apikey"
    } & CreateApiKeyIntegration)
  | ({
      auth_type: "oauth_authorization_code"
    } & CreateOAuthAuthorizationCodeIntegration)
  | ({
      auth_type: "oauth_client_credentials"
    } & CreateOAuthClientCredsIntegration)
  | ({
      auth_type: "hris_link_token"
    } & CreateHrisLinkTokenApiIntegration)
  | ({
      auth_type: "accounting_link_token"
    } & CreateAccountingLinkTokenApiIntegration)
  | ({
      auth_type: "domain_private_key"
    } & CreateDomainPrivateKeyIntegration)
  | ({
      auth_type: "plaid_access_token"
    } & CreatePlaidAccessTokenIntegration)
export interface UpdateIntegration {
  integration_id: string
  api_key?: NonEmptyString
  resource_domain?: NonEmptyString
  private_key?: NonEmptyString
  client_id?: NonEmptyString
  client_secret?: NonEmptyString
}
export interface LinkTokenResponse {
  token: string
  originId: string
}
export interface MergeWebhookPayload {
  hook: {
    event: string
  }
  linked_account: {
    id: string
    end_user_origin_id: string
    integration: string
    category: string
    status: string
  }
  data: {
    account_token: string
  }
}
export interface PersonDetails {
  id: string
  object_type: "Person"
  display_name: NonEmptyTrimmedString
  organization_id: string
  user_id: null | string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: null | EmailString
  profile_image: null | ImageAsset
  employment_status: null | ("active" | "inactive" | "pending")
  created_at: DateTimeString
  tool_count: number
  sources: IntegrationPublicFields[]
  last_login: null | {
    authorized_at: DateTimeString
    event_type: LoginEventType
  }
  hris_groups: HrisGroups
  user: null | User
  manager: null | Person
  google_profile_id: null | NonEmptyString
  brex_id: null | NonEmptyString
  ramp_id: null | NonEmptyString
  hris_id: null | NonEmptyString
  okta_id: null | NonEmptyString
  slack_id: null | NonEmptyString
  hris_type: null | MergeIntegrationHrisType
}
export interface LoginScopeSummary {
  [key: string]: {
    scope: NonEmptyString
    description: null | NonEmptyTrimmedProseString
    provider: NonEmptyString
    resource_group: null | NonEmptyString
    risk_score: number
    person_count: number
    tools: ToolMinimal[]
  }[]
}
export interface LoginUserActivityResponse {
  total_active_users: number
  intervals: {
    start_date: DateString
    active_users: number
  }[]
}
export interface TransactionSearchResult {
  type: "transaction"
  id: string
  bank_memo: null | NonEmptyTrimmedString
  bank_external_memo: null | NonEmptyTrimmedString
  cc_memo: null | NonEmptyTrimmedString
  erp_bill_payment_memo: null | NonEmptyTrimmedString
  erp_expense_memo: null | NonEmptyTrimmedString
  merchant_name: NonEmptyString
  receipt_text: null | NonEmptyString
  person: null | Person
  provider: null | IntegrationProvider
  merge_accounting_type: null | MergeIntegrationAccountingType
  vendor: {
    id: string
    display_name: NonEmptyTrimmedString
  }
  tool: null | {
    id: string
    display_name: NonEmptyTrimmedString
  }
  currency_amount: CurrencyAmount
  transacted_at: DateTimeString
  _snippetResult?: {
    receipt_text?: {
      value: string
    }
  }
  _highlightResult?: {
    bank_memo?: {
      value: string
    }
    bank_external_memo?: {
      value: string
    }
    cc_memo?: {
      value: string
    }
    erp_bill_payment_memo?: {
      value: string
    }
    erp_expense_memo?: {
      value: string
    }
    merchant_descriptor?: {
      value: string
    }
    merchant_name?: {
      value: string
    }
    receipt_text?: {
      value: string
    }
    person?: {
      first_name?: {
        value: string
      }
      last_name?: {
        value: string
      }
    }
    vendor?: {
      display_name?: {
        value: string
      }
    }
    tool?: {
      display_name?: {
        value: string
      }
    }
  }
}
export interface PersonSearchResult {
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: null | EmailString
  profile_image: null | ImageAsset
  type: "person"
  id: string
  _highlightResult?: {
    first_name?: {
      value: string
    }
    last_name?: {
      value: string
    }
  }
}
export interface VendorSearchResult {
  /** The company name of the vendor */
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
  /** The description of the vendor */
  description: null | NonEmptyTrimmedProseString
  type: "vendor"
  id: string
  custom_description: null | NonEmptyTrimmedProseString
  custom_name: null | NonEmptyString
  _highlightResult: {
    display_name?: {
      value: string
    }
    description?: {
      value: string
    }
  }
  _snippetResult?: {
    description?: {
      value: string
    }
  }
}
export interface ToolSearchResult {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAsset
  type: "tool"
  id: string
  custom_description: null | NonEmptyTrimmedProseString
  custom_name: null | NonEmptyString
  _highlightResult: {
    display_name?: {
      value: string
    }
    description?: {
      value: string
    }
  }
  _snippetResult?: {
    description?: {
      value: string
    }
  }
}
export interface WorkflowRunSearchResult {
  type: "workflow_run"
  id: string
  workflow_run_name: NonEmptyTrimmedString
  workflow_run_status: WorkflowRunStatus
  workflow_run_kind: WorkflowKind
  workflow_run_created_at: DateTimeString
  organization_id: string
  vendor_id: null | string
  vendor_name: null | NonEmptyTrimmedString
  vendor_logo: null | NonEmptyTrimmedString
  vendor_description: null | NonEmptyTrimmedProseString
  tool_id: null | string
  tool_name: null | NonEmptyTrimmedString
  tool_logo: null | NonEmptyTrimmedString
  description: null | NonEmptyTrimmedProseString
  tool_image_asset: null | ImageAsset
  vendor_image_asset: null | ImageAsset
  _highlightResult: {
    display_name?: {
      value: string
    }
    description?: {
      value: string
    }
  }
  _snippetResult?: {
    description?: {
      value: string
    }
  }
}
export interface LegalDocumentResult {
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date?: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date?: null | DateString
  id: string
  /** The original file basename as uploaded by the user. */
  file_name: null | NonEmptyTrimmedString
  raw_text: null | string
  tools: ToolMinimal[]
  legal_agreement_id: string
  type: "legal_document"
  document_type: null | DocumentType
  visibility_status: AgreementVisibilityStatus
  created_by: null | string
  tool_display_names: NonEmptyTrimmedString[]
  _snippetResult?: {
    raw_text?: {
      value: string
    }
  }
  _highlightResult?: {
    file_name?: {
      value: string
    }
    tool_display_names?: {
      value: string
    }[]
  }
}
export type SearchResponse = (
  | ({
      type: "transaction"
    } & TransactionSearchResult)
  | ({
      type: "person"
    } & PersonSearchResult)
  | ({
      type: "vendor"
    } & VendorSearchResult)
  | ({
      type: "tool"
    } & ToolSearchResult)
  | ({
      type: "workflow_run"
    } & WorkflowRunSearchResult)
  | ({
      type: "legal_document"
    } & LegalDocumentResult)
)[]
export interface UploadableDocument {
  id: string
  object_type: "Document"
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  /** The original file basename as uploaded by the user. */
  file_name: null | NonEmptyTrimmedString
  extraction_status?: null | DocumentExtractionStatus
  /** The file size in bytes */
  file_size: null | number
  /** MIME type like `application/pdf` or `text/plain; charset=utf-8` */
  mime_type: null | NonEmptyTrimmedString
  /** Users who have email integrations which have found this document */
  user_email_integrations?: UserWithProvider[]
  upload_url: string
  status: "pending"
}
export interface UploadedDocument {
  id: string
  object_type: "Document"
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  /** The original file basename as uploaded by the user. */
  file_name: null | NonEmptyTrimmedString
  extraction_status?: null | DocumentExtractionStatus
  /** The file size in bytes */
  file_size: null | number
  /** MIME type like `application/pdf` or `text/plain; charset=utf-8` */
  mime_type: null | NonEmptyTrimmedString
  /** Users who have email integrations which have found this document */
  user_email_integrations?: UserWithProvider[]
  status: "uploaded" | "scanned" | "quarantined"
  existing_legal_agreements: LegalAgreementMinimal[]
}
export type CreateDocumentResponse =
  | ({
      status: "pending"
    } & UploadableDocument)
  | ({
      status: "uploaded"
    } & UploadedDocument)
  | ({
      status: "scanned"
    } & UploadedDocument)
  | ({
      status: "quarantined"
    } & UploadedDocument)
export type HexString = string
export interface DocumentInput {
  /** The original file basename as uploaded by the user. */
  file_name: NonEmptyTrimmedString
  /** The file size in bytes */
  file_size: number
  mime_type: NonEmptyTrimmedString
  /** The SHA1 hash of the file content as a hex string. Optional property for when we want to prevent duplicate documents during bulk upload. */
  content_hash?: HexString
  link_code?: NonEmptyTrimmedString
}
export interface ExtractingDocumentResponse {
  /** The documents that are being extracted into legal agreements */
  documents: DocumentMinimal[]
  /** Whether there are email documents that have text being extracted. Not all email documents get fields extracted into legal agreements */
  has_uncategorized_emails: boolean
}
export type DocumentExtractionType = "user_upload" | "receipt" | "email" | "text" | "workflow_run"
export interface MoveDocumentCreateAction {
  action: "create"
  display_name: string
}
export interface MoveDocumentUpdateAction {
  action: "update"
  id: string
}
export interface MoveDocumentRequest {
  old_agreement_id: string
  new_agreement:
    | ({
        action: "create"
      } & MoveDocumentCreateAction)
    | ({
        action: "update"
      } & MoveDocumentUpdateAction)
  document_id: string
}
export interface DocumentBackgroundExtractionResponse {
  document_id: string
  agreement_id?: string
}
export interface DocumentExtractionSubscriptionLegalAgreementInput {
  agreement_type: "subscription"
  object_type: "LegalAgreement"
  fields_metadata: LegalAgreementInputFieldsMetadata
  tools?: ToolOption[]
  vendor?: null | VendorOption
  custom: CustomFields
  documents: DocumentReference[]
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date?: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date?: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to NOT renew before the next auto-renewal as an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  display_name?: string
}
export interface DocumentExtractionEnterpriseLegalAgreementInput {
  agreement_type: "enterprise"
  object_type: "LegalAgreement"
  fields_metadata: LegalAgreementInputFieldsMetadata
  tools?: ToolOption[]
  vendor?: null | VendorOption
  custom: CustomFields & CustomFields
  documents: DocumentReference[]
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date?: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date?: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to NOT renew before the next auto-renewal as an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed?: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  /** The legal clauses found in the contract. */
  clauses?: LegalClauses
  display_name?: string
}
export type DocumentExtractionLegalAgreementInput =
  | ({
      agreement_type: "subscription"
    } & DocumentExtractionSubscriptionLegalAgreementInput)
  | ({
      agreement_type: "enterprise"
    } & DocumentExtractionEnterpriseLegalAgreementInput)
export interface DocumentBackgroundExtractionUserUpload {
  agreement_id?: string
  agreement_input?: DocumentExtractionLegalAgreementInput
  extraction_type: "user_upload"
}
export interface DocumentBackgroundExtractionWorkflowRun {
  link_code?: NonEmptyTrimmedString
  extraction_type: "workflow_run"
}
export interface DocumentBackgroundExtractionText {
  link_code?: NonEmptyTrimmedString
  extraction_type: "text"
}
export type DocumentBackgroundExtractionRequest =
  | ({
      extraction_type: "user_upload"
    } & DocumentBackgroundExtractionUserUpload)
  | ({
      extraction_type: "workflow_run"
    } & DocumentBackgroundExtractionWorkflowRun)
  | ({
      extraction_type: "text"
    } & DocumentBackgroundExtractionText)
export interface NewExtractedField {
  object_type: CustomizableObjectType
  field_name: FieldName
  is_custom: boolean
  /** The data type of the field as a JSON schema */
  field_schema: null | any
  is_enabled: boolean
  category: null | FieldCategory
  type: "new_field"
  /** The original question from the questionnaire that this field was matched to */
  question: string
}
export interface ExistingExtractedField {
  object_type: CustomizableObjectType
  field_name: FieldName
  is_custom: boolean
  /** The data type of the field as a JSON schema */
  field_schema: null | any
  is_enabled: boolean
  category: null | FieldCategory
  /** The workflow definitions this field is used in. */
  workflow_defs: WorkflowDefMinimal[]
  updated_at: null | DateTimeString
  created_at: null | DateTimeString
  created_by: null | UserMinimal
  updated_by: null | UserMinimal
  type: "existing_field"
  /** The original question from the questionnaire that this field was matched to */
  question: string
}
export type ExtractedFieldConfig =
  | ({
      type: "new_field"
    } & NewExtractedField)
  | ({
      type: "existing_field"
    } & ExistingExtractedField)
export type ExtractedFieldConfigsResponse = ExtractedFieldConfig[]
export interface FormFieldConfig {
  object_type: ObjectType
  field_name: NonEmptyTrimmedString
  is_custom: boolean
  is_required: boolean
  is_internal_only: boolean
}
export interface WorkflowDefUserApprover {
  id: string
  type: "user"
  user: User
}
export interface WorkflowDefVariableApprover {
  id: string
  type: "variable"
  variable: "manager"
}
export type WorkflowDefApprover =
  | ({
      type: "user"
    } & WorkflowDefUserApprover)
  | ({
      type: "variable"
    } & WorkflowDefVariableApprover)
export type ConditionType = "and" | "or"
export interface WorkflowCondition {
  id: string
  field_name: NonEmptyTrimmedString
  object_type: ObjectType
  is_custom: boolean
  condition_schema: any
}
export interface WorkflowConditionGroup {
  id: string
  group_type: ConditionType
  conditions: WorkflowCondition[]
  children_groups: WorkflowConditionGroup[]
}
export interface WorkflowDefApprovalStep {
  id: string
  approvers: WorkflowDefApprover[]
  condition: null | WorkflowConditionGroup
}
export type WorkflowStepStandardType = "details" | "compliance" | "it" | "finance" | "legal" | "close" | "custom"
export interface WorkflowDefStep {
  id: string
  display_name: NonEmptyTrimmedProseString
  fields: FormFieldConfig[]
  approval_steps: WorkflowDefApprovalStep[]
  type: WorkflowStepStandardType
  condition: null | WorkflowConditionGroup
}
export interface WorkflowDef {
  id: string
  created_at: DateTimeString
  kind: WorkflowKind
  display_name: NonEmptyTrimmedProseString
  description: null | NonEmptyTrimmedProseString
  updated_at: DateTimeString
  steps: WorkflowDefStep[]
  last_updated_by: User
}
export interface ApproverUserInput {
  id: string
  organization_id: string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: EmailString
  profile_image: null | ImageAsset
  roles: Role[]
}
export interface WorkflowDefUserApproverInput {
  type: "user"
  id?: string
  user: ApproverUserInput
}
export interface WorkflowDefVariableApproverInput {
  type: "variable"
  variable: "manager"
  id?: string
}
export type WorkflowDefApproverInput =
  | ({
      type: "user"
    } & WorkflowDefUserApproverInput)
  | ({
      type: "variable"
    } & WorkflowDefVariableApproverInput)
export interface WorkflowConditionInput {
  field_name: NonEmptyTrimmedString
  object_type: ObjectType
  is_custom: boolean
  condition_schema: any
}
export interface WorkflowConditionGroupInput {
  group_type: ConditionType
  conditions: WorkflowConditionInput[]
  children_groups: WorkflowConditionGroupInput[]
}
export interface WorkflowDefApprovalStepInput {
  approvers: WorkflowDefApproverInput[]
  condition: null | WorkflowConditionGroupInput
}
export interface WorkflowDefStepInput {
  display_name: NonEmptyTrimmedProseString
  type: WorkflowStepStandardType
  step_configuration_input_id: number
  next_input_ids: number[]
  approval_steps: WorkflowDefApprovalStepInput[]
  fields: FormFieldConfig[]
  condition: null | WorkflowConditionGroupInput
}
export interface WorkflowDefUpdate {
  display_name: NonEmptyTrimmedProseString
  description?: null | NonEmptyTrimmedProseString
  steps: WorkflowDefStepInput[]
}
export interface WorkflowDefInput {
  kind: WorkflowKind
  display_name: NonEmptyTrimmedProseString
  description?: null | NonEmptyTrimmedProseString
  steps: WorkflowDefStepInput[]
}
export interface WorkflowRunListResponse {
  list: WorkflowRunListItem[]
  total: number
}
export interface LegalWorkflowFieldsMetadata {
  total_contract_value?: FieldMetadataWithSuggestions
  start_date?: FieldMetadataWithSuggestions
  end_date?: FieldMetadataWithSuggestions
  first_invoice_date?: FieldMetadataWithSuggestions
  invoice_interval?: FieldMetadataWithSuggestions
  net_payment_term?: FieldMetadataWithSuggestions
  auto_renews?: FieldMetadataWithSuggestions
  auto_renewal_opt_out_period?: FieldMetadataWithSuggestions
  decision_date?: FieldMetadataWithSuggestions
  is_signed?: FieldMetadataWithSuggestions
  buyer_signer_name?: FieldMetadataWithSuggestions
  buyer_signer_title?: FieldMetadataWithSuggestions
  vendor_signer_name?: FieldMetadataWithSuggestions
  vendor_signer_title?: FieldMetadataWithSuggestions
  document?: FieldMetadataWithSuggestions
  vendor?: FieldMetadataWithSuggestions
  tools?: FieldMetadataWithSuggestions
  clauses?: LegalClausesFieldsMetadata
  custom?: {
    [key: string]: FieldMetadataWithSuggestions
  }
}
export interface LegalClausesPatch {
  /** Whether the contract has an arbitration clause. The arbitration clause requires the parties to the contract to resolve any dispute arising out of the contract through an arbitration process, as opposed to litigation or mediation. */
  arbitration?: null | boolean
  /** Whether the contract has an assignment clause. The assignment clause indicates whether and when a party to the contract can assign their rights and obligations under the contract to someone else. */
  assignment?: null | boolean
  /** Whether the contract has a confidentiality clause. The confidentiality clause prevents one party (or both parties) from disclosing information related to the agreement, or other sensitive information. */
  confidentiality?: null | boolean
  /** Whether the contract has a data privacy clause. The data privacy clause may explain what private data will be collected, what the collecting party will do with the data, and/or how the data will be protected. */
  data_privacy?: null | boolean
  /** Whether the contract has a data processing clause. The data processing clause outlines the roles and responsibilities of the parties involved with regards to processing of personal data. It details the methods, purposes, and scope of the data processing activities carried out under the agreement. It may specify the rights and obligations of the data controller and data processor, and the safeguards and measures that must be in place to ensure data security and compliance with relevant data protection laws and regulations. */
  data_processing?: null | boolean
  /** Whether the contract has a data protection clause. The data protection clause ensures that the data given by one party to another is safeguarded from unlawful access by unauthorized users or entities. */
  data_protection?: null | boolean
  /** Whether the contract has a dispute resolution clause. The dispute resolution clause sets forth the circumstances under which any disputes between the parties should be resolved (e.g., whether the dispute should be resolved via arbitration, mediation, or litigation; jurisdiction, venue, etc.) */
  dispute_resolution?: null | boolean
  /** Whether the contract has a force majeure clause. The force majeure clause frees both parties from obligation if an extraordinary event directly prevents one or both parties from performing their obligations under the contract. */
  force_majeure?: null | boolean
  /** Whether the contract has a governing law clause. The governing law clause specifies which jurisdiction’s law will apply in the event of a dispute. */
  governing_law?: null | boolean
  /** Whether the contract has an indemnification clause. The indemnification clause obligates one party to compensate another party for certain costs and expenses, usually stemming from third party claims. */
  indemnification?: null | boolean
  /** Whether the contract has an intellectual property clause. The intellectual property clause sets forth ownership, permitted uses, and use restrictions of intellectual property. */
  intellectual_property?: null | boolean
  /** Whether the contract has a license clause. The license clause gives one party the right to use the product or services of the other party under certain conditions. */
  license?: null | boolean
  /** Whether the contract has a limitation of liability clause. The limitation of liability clause limits the amount and types of compensation one party can recover from the other party when a claim is made or lawsuit is filed in response to a breach of the contract. */
  limitation_of_liability?: null | boolean
  /** Whether the contract has a non disclosure clause. The non-disclosure clause prohibits the parties to the contract from releasing certain information to other parties. */
  non_disclosure?: null | boolean
  /** Whether the contract has a non solicitation clause. The non-solicitation clause prohibits a party from offering employment to the counterparty's employees, for a specified period of time. */
  non_solicitation?: null | boolean
  /** Whether the contract has a representation clause. The representations clause states that the representations contained in the contract are true and accurate as of the date of the agreement. */
  representation?: null | boolean
  /** Whether the contract has a termination clause. The termination clause explains what will happen if either party terminates the contract. The clause may also set forth the circumstances in which one party may terminate the contract, such as required notice. */
  termination?: null | boolean
  /** Whether the contract has a warranty clause. The warranty clause sets forth what promises, if any, are being made regarding the accuracy, completeness, or reliability of the subject matter of the contract, whether data, services, software, or otherwise. Warranty clauses can also specifically state that no such promises are being made or relied upon. */
  warranty?: null | boolean
}
export interface LegalAgreementPatchOutput {
  id: string
  object_type: "LegalAgreement"
  /** The name of the agreement. */
  display_name?: NonEmptyTrimmedProseString
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date?: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date?: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to NOT renew before the next auto-renewal as an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed?: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  custom?: CustomFields
  fields_metadata?: LegalWorkflowFieldsMetadata
  /** Any documents related to this requests. Documents can include an Order Form, Statement of Work, a Quote, Master Services Agreements, etc. */
  documents?: DocumentWithExtraction[]
  /** The legal clauses found in the contract. */
  clauses?: LegalClausesPatch
  tool_ids?: string[]
  vendor_id?: string
}
export interface Tool4 {
  id: string
  object_type: "Tool"
  /** Lists the specific Single Sign-On (SSO) methods the software supports */
  sso_types?: null | SsoType[]
  /** Lists the specific Multi-Factor Authentication methods the software supports */
  mfa_types?: null | MfaType[]
  /** Describes the specific methods used by the software to regulate data access */
  access_control_types?: null | AccessControlType[]
  /** Indicates how the software is deployed and accessed */
  deployment_types?: null | SoftwareDeploymentType[]
  /** Determines if the software provides System for Cross-domain Identity Management (SCIM) functionality */
  scim?: null | boolean
  /** Which other tools does this product integrate with? */
  integrates_with?: null | ToolListingPatch[]
  /** URL or Document containing the service’s privacy policy */
  privacy_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service’s terms of service */
  terms_of_service?: null | DocumentOrUrlString
  /** Risk level can be categorized as:
    
    - **High**: Vendor handles sensitive data or security services; critical business impact if they fail.
    - **Moderate**: Vendor doesn’t handle sensitive data or security; moderate business impact if they fail.
    - **Low**: Vendor has minimal involvement and little to no business impact if they fail.
    - **None**: Vendor has no impact on business operations. */
  customer_security_impact_level?: null | CustomerSecurityImpact
  /** A critical vendor has one or more of the following characteristics:
    
    1) Handles customer data,
    2) Is responsible for our infrastructure or code security,
    3) Has cloud services that affect our system’s security or availability,
    4) Whose failure would result in disruption to our service availability. */
  is_critical?: null | boolean
  /** Whether the product or service is a data subprocessor */
  is_subprocessor?: null | boolean
  /** Link to a vendor’s list of third-party subprocessors */
  subprocessors?: null | HttpUrlString
  /** Whether the product or service is SEA Rule 17(a)4f compliant */
  sea_rule_17a4f?: null | boolean
  /** URL or Document containing the service's cybersecurity policy */
  cybersecurity_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's information security policy */
  information_security_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's Anti-Money Laundering policy */
  aml_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's financial stability evidence */
  financial_stability?: null | DocumentOrUrlString
  /** Whether the product or service stores personal identifying information (PII) */
  stores_pii?: null | boolean
  /** The types of personal identifiable information (PII) the product or service collects or retains */
  pii_data_types?: null | PiiDataType[]
  /** The types of people whose personal identifiable information (PII) the product or service collects or retains */
  pii_person_types?: null | PiiPersonType[]
  /** The regions whose personal identifiable information (PII) the product or service collects or retains */
  pii_regions?: null | PiiRegion[]
  /** The types of data the product or service collects or retains */
  data_stored?: null | ComplianceData[]
  /** Whether the product or service has SOC 2 compliance certification */
  soc2?: null | CompliantWithDocumentPatch
  /** Whether the product or service has HIPAA compliance certification */
  hipaa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has GDPR compliance certification */
  gdpr?: null | CompliantWithDocumentPatch
  /** Whether the product or service has ISO 27001 compliance certification */
  iso_27001?: null | CompliantWithDocumentPatch
  /** Whether the product or service has PCI DSS compliance certification */
  pci_dss?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CCPA compliance certification */
  ccpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CDPA compliance certification */
  cdpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CMMC 2.0 compliance certification */
  cmmc_2?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CPA compliance certification */
  cpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CPRA compliance certification */
  cpra?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CTDPA compliance certification */
  ctdpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has EU Whistleblower compliance certification */
  eu_whistleblower?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Nevada Privacy Law compliance certification */
  nevada_privacy_law?: null | CompliantWithDocumentPatch
  /** Whether the product or service has SOX compliance certification */
  sox?: null | CompliantWithDocumentPatch
  /** Whether the product or service has UCPA compliance certification */
  ucpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service stores data in WORM format */
  worm?: null | ApplicableWithDocumentPatch
  /** The Data Processing Agreement for a tool */
  dpa?: null | DocumentMinimal
  /** The requested spend for the tool */
  requested_spend?: null | CurrencyAmount
  /** The frequency of spend requested for the tool */
  requested_spend_interval?: null | FrequencyOrOneTime
  /** What problem(s) will this tool solve and how? */
  overview?: null | RichText
  /** Why are you choosing this product over alternative tools and services? */
  selection_rationale?: null | RichText
  /** What are the areas of concern or gaps this tool has? */
  risks_limitations?: null | RichText
  /** What’s the estimated return on investment (ROI) for this tool? */
  roi_analysis?: null | RichText
  /** How long will it take to implement and fully deploy? */
  implementation_details?: null | RichText
  /** What level of support is provided by the vendor for the selected plan? */
  support_from_vendor?: null | RichText
  /** What other tools and services were considered during the evaluation of this tool? */
  tools_considered?: ToolListingPatch[]
  custom?: CustomFields
  fields_metadata?: WorkflowFieldsMetadata
}
export interface VendorPatchOutput {
  id: string
  object_type: "Vendor"
  custom?: CustomFields
  /** The year the company was founded */
  founded_year?: null | number
  /** Is the vendor a broker dealer? */
  is_broker_dealer?: null | boolean
  /** The unique number assigned to a business entity by the Internal Revenue Service (IRS). Used for tax filing and reporting purposes */
  ein?: null | EmploymentIdentificationNumber
  /** A nine-digit number assigned by Dun & Bradstreet for business identification and credit reporting */
  duns?: null | DunsNumber
  /** Details on cybersecurity breaches that the company has experienced, if any. */
  cybersecurity_breaches?: null | RichText
  /** Details of any ongoing or pending litigation involving the company, if applicable. */
  litigations?: null | RichText
  /** The Non-Disclosure Agreement document for the vendor */
  nda?: null | DocumentMinimal
  /** The vendor's bank information */
  bank_info?: null | DocumentMinimal
  /** The vendor's W-9 */
  w9?: null | DocumentMinimal
  fields_metadata?: WorkflowFieldsMetadata
}
export interface WorkflowRunStatePatch {
  legal_agreement?: LegalAgreementPatchOutput
  tools?: Tool4[]
  vendor?: null | VendorPatchOutput
}
export interface InvitedSellerUser {
  first_name: null | NonEmptyTrimmedProseString
  last_name: null | NonEmptyTrimmedProseString
  email: EmailString
  is_pending: boolean
  invited_at: DateTimeString
  opened_at: null | DateTimeString
}
export interface WorkflowRunFieldGatherer {
  object_id: string
  object_type: ObjectType
  field_name: NonEmptyTrimmedString
  gatherer: null | User
  gatherer_is_seller: boolean
  updated_at: null | DateTimeString
}
export interface WorkflowRunFieldGathererLookupMap {
  [key: string]: {
    [key: string]: WorkflowRunFieldGatherer
  }
}
export type WorkflowRunStepStatus =
  | "pending"
  | "pending_submitted"
  | "in_progress"
  | "submitted"
  | "completed"
  | "approved"
  | "rejected"
  | "cancelled"
  | "skipped"
export interface WorkflowRunApprover {
  id: string
  type: "user"
  user: User
}
export interface WorkflowRunApprovalStep {
  id: string
  approvers: WorkflowRunApprover[]
  approved_by: null | User
  condition: null | WorkflowConditionGroup
  skipped_by_conditional: boolean
}
export interface FieldCounts {
  completed: number
  completed_required: number
  required: number
  total: number
  approved: number
}
export type WorkflowRunStepFieldApprovalStatus = "approved" | "change_requested" | "needs_review"
export interface WorkflowRunStepFieldApproval {
  id: string
  status: WorkflowRunStepFieldApprovalStatus
  object_id: string
  object_type: ObjectType
  field_name: NonEmptyTrimmedString
  user: User
  updated_at: null | DateTimeString
}
export interface WorkflowRunStepFieldApprovalLookupMap {
  [key: string]: {
    [key: string]: WorkflowRunStepFieldApproval
  }
}
export interface WorkflowRunStepWithContext {
  id: string
  workflow_run_id: string
  status: WorkflowRunStepStatus
  display_name: NonEmptyTrimmedProseString
  type: WorkflowStepStandardType
  fields: FormFieldConfig[]
  submitted_at: null | DateTimeString
  seller_submitted_at: null | DateTimeString
  completed_at: null | DateTimeString
  owner: User
  approval_steps: WorkflowRunApprovalStep[]
  condition: null | WorkflowConditionGroup
  next: string[]
  field_counts: FieldCounts
  field_approvals: WorkflowRunStepFieldApprovalLookupMap
  submitted_by: null | User
  draft_state_updated_at: null | DateTimeString
  draft_state_updated_by: null | User
  comment_count: number
}
export type WorkflowRunSteps = WorkflowRunStepWithContext[]
export interface CommentCounts {
  [key: string]: {
    [key: string]: number
  }
}
export interface LinkWithAuthCode {
  id: string
  created_at: DateTimeString
  first_name: null | NonEmptyTrimmedProseString
  last_name: null | NonEmptyTrimmedProseString
  email: EmailString
  body: null | NonEmptyTrimmedProseString
  auth_code?: NonEmptyTrimmedString
}
export interface WorkflowRun {
  id: string
  object_type: "WorkflowRun"
  display_name: NonEmptyTrimmedProseString
  created_at: DateTimeString
  kind: WorkflowKind
  status: WorkflowRunStatus
  completed_at: null | DateTimeString
  target_date: DateString
  created_by: User
  owner: User
  definition: WorkflowDefMinimal
  context_schema: any
  merged_state: WorkflowRunStatePatch
  seller_users: InvitedSellerUser[]
  seller_due_date: null | DateString
  field_gatherers: WorkflowRunFieldGathererLookupMap
  buyer_organization_image_asset: null | ImageAsset
  steps: WorkflowRunSteps
  comment_counts: CommentCounts
  active_legal_agreement: null | LegalAgreement
  new_legal_agreement: LegalAgreement
  links: LinkWithAuthCode[]
}
export interface LinkWorkflowSellerInvite {
  message: null | NonEmptyTrimmedProseString
  due_date: null | DateString
  recipients: {
    email: EmailString
    first_name: NonEmptyTrimmedProseString
    last_name: NonEmptyTrimmedProseString
  }[]
}
export interface PurchaseWorkflowRunInput {
  owner_id: string
  employee_group_ids: string[]
  tool_owner_id: string
  link_invite: null | LinkWorkflowSellerInvite
  display_name: NonEmptyTrimmedProseString
  target_date: DateString
  kind: "purchase"
  tools: ToolOption[]
}
export interface RenewalWorkflowRunInput {
  owner_id: string
  employee_group_ids: string[]
  tool_owner_id: string
  link_invite: null | LinkWorkflowSellerInvite
  display_name: NonEmptyTrimmedProseString
  target_date: DateString
  kind: "renewal"
  active_legal_agreement_id?: string
  new_legal_agreement_name?: NonEmptyTrimmedString
  tools?: ToolOption[]
}
export type WorkflowRunInput =
  | ({
      kind: "purchase"
    } & PurchaseWorkflowRunInput)
  | ({
      kind: "renewal"
    } & RenewalWorkflowRunInput)
export interface WorkflowRunUpdatePatch {
  owner_id?: string
  display_name?: NonEmptyTrimmedProseString
  target_date?: DateString
}
export interface SetFieldGatherRequest {
  gatherer_id: null | string
  is_custom: boolean
  workflow_run_step_id: string
}
export interface WorkflowRunApproverUpdate {
  id: string
  type: "user"
  user: {
    id: string
  }
}
export interface WorkflowRunApprovalStepUpdate {
  id: string
  approvers: WorkflowRunApproverUpdate[]
}
export type WorkflowRunStepsUpdatePatch = {
  id: string
  owner?: {
    id: string
  }
  approval_steps: WorkflowRunApprovalStepUpdate[]
}[]
export interface SellerLinkData {
  link: LinkWithAuthCode
  workflowRun: WorkflowRunMinimal
  buyerOrganization: Organization
}
export interface SellerLinksResponse {
  links: SellerLinkData[]
}
export interface BrmLinkMinimal {
  id: string
  created_at: DateTimeString
  first_name: null | NonEmptyTrimmedProseString
  last_name: null | NonEmptyTrimmedProseString
  email: EmailString
  body: null | NonEmptyTrimmedProseString
}
export interface CreateBrmLinkInput {
  email: EmailString
  workflow_run_id: string
  is_seller: boolean
}
export interface LegalWorkflowFieldsMetadataInput {
  total_contract_value?: FieldMetadata
  start_date?: FieldMetadata
  end_date?: FieldMetadata
  first_invoice_date?: FieldMetadata
  invoice_interval?: FieldMetadata
  net_payment_term?: FieldMetadata
  auto_renews?: FieldMetadata
  auto_renewal_opt_out_period?: FieldMetadata
  decision_date?: FieldMetadata
  is_signed?: FieldMetadata
  buyer_signer_name?: FieldMetadata
  buyer_signer_title?: FieldMetadata
  vendor_signer_name?: FieldMetadata
  vendor_signer_title?: FieldMetadata
  document?: FieldMetadata
  vendor?: FieldMetadata
  tools?: FieldMetadata
  clauses?: LegalClausesInputFieldsMetadata
  custom?: {
    [key: string]: FieldMetadata
  }
}
export interface LegalAgreementPatchInput {
  id: string
  object_type: "LegalAgreement"
  /** The name of the agreement. */
  display_name?: NonEmptyTrimmedProseString
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date?: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date?: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to NOT renew before the next auto-renewal as an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed?: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  custom?: CustomFields
  fields_metadata?: LegalWorkflowFieldsMetadataInput
  documents?: DocumentMinimal[]
  clauses?: LegalClausesPatch
  tool_ids?: string[]
  vendor_id?: string
}
export interface WorkflowFieldsMetadataInput {
  [key: string]:
    | FieldMetadata
    | {
        [key: string]: FieldMetadata
      }
}
export interface Tool5 {
  id: string
  object_type: "Tool"
  /** Lists the specific Single Sign-On (SSO) methods the software supports */
  sso_types?: null | SsoType[]
  /** Lists the specific Multi-Factor Authentication methods the software supports */
  mfa_types?: null | MfaType[]
  /** Describes the specific methods used by the software to regulate data access */
  access_control_types?: null | AccessControlType[]
  /** Indicates how the software is deployed and accessed */
  deployment_types?: null | SoftwareDeploymentType[]
  /** Determines if the software provides System for Cross-domain Identity Management (SCIM) functionality */
  scim?: null | boolean
  /** Which other tools does this product integrate with? */
  integrates_with?: null | ToolListingPatch[]
  /** URL or Document containing the service’s privacy policy */
  privacy_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service’s terms of service */
  terms_of_service?: null | DocumentOrUrlString
  /** Risk level can be categorized as:
    
    - **High**: Vendor handles sensitive data or security services; critical business impact if they fail.
    - **Moderate**: Vendor doesn’t handle sensitive data or security; moderate business impact if they fail.
    - **Low**: Vendor has minimal involvement and little to no business impact if they fail.
    - **None**: Vendor has no impact on business operations. */
  customer_security_impact_level?: null | CustomerSecurityImpact
  /** A critical vendor has one or more of the following characteristics:
    
    1) Handles customer data,
    2) Is responsible for our infrastructure or code security,
    3) Has cloud services that affect our system’s security or availability,
    4) Whose failure would result in disruption to our service availability. */
  is_critical?: null | boolean
  /** Whether the product or service is a data subprocessor */
  is_subprocessor?: null | boolean
  /** Link to a vendor’s list of third-party subprocessors */
  subprocessors?: null | HttpUrlString
  /** Whether the product or service is SEA Rule 17(a)4f compliant */
  sea_rule_17a4f?: null | boolean
  /** URL or Document containing the service's cybersecurity policy */
  cybersecurity_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's information security policy */
  information_security_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's Anti-Money Laundering policy */
  aml_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's financial stability evidence */
  financial_stability?: null | DocumentOrUrlString
  /** Whether the product or service stores personal identifying information (PII) */
  stores_pii?: null | boolean
  /** The types of personal identifiable information (PII) the product or service collects or retains */
  pii_data_types?: null | PiiDataType[]
  /** The types of people whose personal identifiable information (PII) the product or service collects or retains */
  pii_person_types?: null | PiiPersonType[]
  /** The regions whose personal identifiable information (PII) the product or service collects or retains */
  pii_regions?: null | PiiRegion[]
  /** The types of data the product or service collects or retains */
  data_stored?: null | ComplianceData[]
  /** Whether the product or service has SOC 2 compliance certification */
  soc2?: null | CompliantWithDocumentPatch
  /** Whether the product or service has HIPAA compliance certification */
  hipaa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has GDPR compliance certification */
  gdpr?: null | CompliantWithDocumentPatch
  /** Whether the product or service has ISO 27001 compliance certification */
  iso_27001?: null | CompliantWithDocumentPatch
  /** Whether the product or service has PCI DSS compliance certification */
  pci_dss?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CCPA compliance certification */
  ccpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CDPA compliance certification */
  cdpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CMMC 2.0 compliance certification */
  cmmc_2?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CPA compliance certification */
  cpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CPRA compliance certification */
  cpra?: null | CompliantWithDocumentPatch
  /** Whether the product or service has CTDPA compliance certification */
  ctdpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has EU Whistleblower compliance certification */
  eu_whistleblower?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Nevada Privacy Law compliance certification */
  nevada_privacy_law?: null | CompliantWithDocumentPatch
  /** Whether the product or service has SOX compliance certification */
  sox?: null | CompliantWithDocumentPatch
  /** Whether the product or service has UCPA compliance certification */
  ucpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service stores data in WORM format */
  worm?: null | ApplicableWithDocumentPatch
  /** The Data Processing Agreement for a tool */
  dpa?: null | DocumentMinimal
  /** The requested spend for the tool */
  requested_spend?: null | CurrencyAmount
  /** The frequency of spend requested for the tool */
  requested_spend_interval?: null | FrequencyOrOneTime
  /** What problem(s) will this tool solve and how? */
  overview?: null | RichText
  /** Why are you choosing this product over alternative tools and services? */
  selection_rationale?: null | RichText
  /** What are the areas of concern or gaps this tool has? */
  risks_limitations?: null | RichText
  /** What’s the estimated return on investment (ROI) for this tool? */
  roi_analysis?: null | RichText
  /** How long will it take to implement and fully deploy? */
  implementation_details?: null | RichText
  /** What level of support is provided by the vendor for the selected plan? */
  support_from_vendor?: null | RichText
  /** What other tools and services were considered during the evaluation of this tool? */
  tools_considered?: ToolListingPatch[]
  custom?: CustomFields
  fields_metadata?: WorkflowFieldsMetadataInput
}
export interface VendorPatchInput {
  id: string
  object_type: "Vendor"
  custom?: CustomFields
  /** The year the company was founded */
  founded_year?: null | number
  /** Is the vendor a broker dealer? */
  is_broker_dealer?: null | boolean
  /** The unique number assigned to a business entity by the Internal Revenue Service (IRS). Used for tax filing and reporting purposes */
  ein?: null | EmploymentIdentificationNumber
  /** A nine-digit number assigned by Dun & Bradstreet for business identification and credit reporting */
  duns?: null | DunsNumber
  /** Details on cybersecurity breaches that the company has experienced, if any. */
  cybersecurity_breaches?: null | RichText
  /** Details of any ongoing or pending litigation involving the company, if applicable. */
  litigations?: null | RichText
  /** The Non-Disclosure Agreement document for the vendor */
  nda?: null | DocumentMinimal
  /** The vendor's bank information */
  bank_info?: null | DocumentMinimal
  /** The vendor's W-9 */
  w9?: null | DocumentMinimal
  fields_metadata?: WorkflowFieldsMetadataInput
}
export interface WorkflowRunStatePatchInput {
  legal_agreement?: LegalAgreementPatchInput
  tools?: Tool5[]
  vendor?: null | VendorPatchInput
}
export type WorkflowRunFieldPath = (
  | NonEmptyTrimmedString
  | {
      id: NonEmptyTrimmedString
    }
)[]
export interface WorkflowRunStepDraftStateUpdateInput {
  state: null | WorkflowRunStatePatchInput
  deleted_field_paths: WorkflowRunFieldPath[]
  submit: boolean
}
export interface BrmLink {
  id: string
  created_at: DateTimeString
  first_name: null | NonEmptyTrimmedProseString
  last_name: null | NonEmptyTrimmedProseString
  email: EmailString
  body: null | NonEmptyTrimmedProseString
  sender: UserMinimal
}
export interface WorkflowStepRunSubmission {
  decision: "proceed" | "abort"
  state: WorkflowRunStatePatchInput
  link_auth_code?: NonEmptyTrimmedString
}
export interface CreditCardTransaction {
  integration: IntegrationPublicFields
  external_transaction_id: NonEmptyString
  external_expense_id: null | NonEmptyString
  memo: null | NonEmptyTrimmedString
}
export interface AccountLike {
  display_name: null | NonEmptyTrimmedProseString
}
export interface BillPayment {
  external_id: NonEmptyString
  merge_remote_id: null | NonEmptyString
  account: null | AccountLike
  bill: null | {
    line_items: {
      account: null | AccountLike
    }[]
    memo: null | NonEmptyString
  }
  memo: null | NonEmptyString
  integration: IntegrationPublicFields
}
export interface Expense {
  external_id: NonEmptyString
  merge_remote_id: null | NonEmptyString
  account: null | AccountLike
  line_items: {
    account: null | AccountLike
  }[]
  memo: null | NonEmptyString
  integration: IntegrationPublicFields
}
export interface CreditCard {
  display_name: null | NonEmptyTrimmedProseString
  last_four: null | NonEmptyString
}
export interface Transaction {
  object_type: "ReconciledTransaction"
  id: string
  organization_id: string
  created_at: DateTimeString
  currency_amount: CurrencyAmount
  transacted_at: DateTimeString
  merchant_identification: NonEmptyTrimmedString
  merchant_name: NonEmptyString
  memo: null | NonEmptyTrimmedString
  person: null | Person
  receipts: Receipt[]
  transaction_category: null | TransactionCategory
  payment_method: null | TransactionPaymentMethod
  integrations: IntegrationPublicFields[]
  tool: null | Tool
  vendor: Vendor
  cc_transaction: null | CreditCardTransaction
  bank_memo: null | NonEmptyTrimmedString
  bank_account: null | AccountLike
  bill_payment: null | BillPayment
  expense: null | Expense
  card: null | CreditCard
  /** The department or class categorization for the transaction from your accounting system */
  erp_tracking_categories: ErpTrackingCategoryGroups
}
export interface TransactionSummaryResponse {
  transactions: Transaction[]
  total: number
}
export type TableIdentifier =
  | "tools"
  | "vendors"
  | "persons"
  | "transactions"
  | "tool-logins"
  | "tool-users"
  | "workflow-runs"
  | "legal-agreements"
export interface SavedView {
  id: string
  organization_id: string
  owner_id: null | string
  table_identifier: TableIdentifier
  name: NonEmptyTrimmedString
  is_default: boolean
  is_shared: boolean
  /** The list of property paths that are displayed as columns in the table of the saved view. */
  column_params: null | PropertyPathString[]
  /** The filters as encoded in the URL, i.e. `new URLSearchParams(savedView.filter_params).toString()` will return the URL query string for the filters. */
  filter_params: null | {
    [key: string]: string
  }
  /** The sort order as encoded in the URL, i.e. `new URLSearchParams(savedView.sort_params).toString()` will return the URL query string for the sort order. */
  sort_params: null | {
    sort?: FilterPathString
    sort_desc?: FilterPathString
  }
}
export interface EditSavedView {
  name?: NonEmptyTrimmedString
  is_shared?: boolean
  /** The list of property paths that are displayed as columns in the table of the saved view. */
  column_params?: null | PropertyPathString[]
  /** The filters as encoded in the URL, i.e. `new URLSearchParams(savedView.filter_params).toString()` will return the URL query string for the filters. */
  filter_params?: null | {
    [key: string]: string
  }
  /** The sort order as encoded in the URL, i.e. `new URLSearchParams(savedView.sort_params).toString()` will return the URL query string for the sort order. */
  sort_params?: null | {
    sort?: FilterPathString
    sort_desc?: FilterPathString
  }
}
export interface CreateSavedView {
  table_identifier: TableIdentifier
  name: NonEmptyTrimmedString
  is_shared: boolean
  /** The list of property paths that are displayed as columns in the table of the saved view. */
  column_params: null | PropertyPathString[]
  /** The filters as encoded in the URL, i.e. `new URLSearchParams(savedView.filter_params).toString()` will return the URL query string for the filters. */
  filter_params: null | {
    [key: string]: string
  }
  /** The sort order as encoded in the URL, i.e. `new URLSearchParams(savedView.sort_params).toString()` will return the URL query string for the sort order. */
  sort_params: null | {
    sort?: FilterPathString
    sort_desc?: FilterPathString
  }
}
export interface Message {
  role: "user" | "assistant"
  content: string
  rich_text?: null | RichText
}
export interface Reaction {
  id: string
  created_at: DateTimeString
  emoji: NonEmptyTrimmedString
  user: UserMinimal
}
export interface AggregatedReaction {
  emoji: NonEmptyTrimmedString
  reactions: Reaction[]
  count: number
}
export interface NonPendingUser {
  id: string
  object_type: "User"
  organization_id: string
  email: EmailString
  profile_image: null | ImageAsset
  created_at: DateTimeString
  person: Person
  roles: Role[]
  first_name: NonEmptyTrimmedString
  last_name: NonEmptyTrimmedString
  status: "active" | "deleted"
}
export interface TimelineEventReplyTimelineEvent {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: null | string
  object_id: string
  object_type: ObjectType
  type: "timeline_event_reply"
  actor: NonPendingUser
  rich_text_body: RichText
  parent_id: string
}
export interface Login {
  object_type: "Login"
  organization_id: string
  created_at: DateTimeString
  authorized_at: DateTimeString
  event_type: LoginEventType
  app_name: NonEmptyString
  integration: IntegrationPublicFields
  person: Person
  external_event_id: NonEmptyString
  scopes: LoginScope[]
  tool: ToolMinimal
}
export interface LegalAgreementWithMinimalRelations {
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to NOT renew before the next auto-renewal as an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  /** The legal clauses found in the contract. */
  clauses?: LegalClauses
  custom: CustomFields
  id: string
  object_type: "LegalAgreement"
  /** Whether the Legal Agreement is active, expired, or upcoming. Will be unknown if the agreement has not been verified or is missing start/end dates. */
  effective_status: AgreementEffectiveStatus
  /** The type of agreement (enterprise or subscription) */
  agreement_type: AgreementType
  /** The name of the agreement. */
  display_name: NonEmptyTrimmedProseString
  /** The next occurrence of the billing interval (based on the first invoice date) */
  next_invoice_date?: null | DateString
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  fields_metadata: LegalAgreementFieldsMetadata
  /** Legal agreements that enter through certain sources, like bulk upload or email, need to have their extracted fields verified. Manually created agreements always start verified. Verified agreements may become unverified when more documents are added. */
  verification_status: AgreementVerificationStatus
  visibility_status: AgreementVisibilityStatus
  vendor: null | VendorMinimal
  tools: ToolMinimal[]
}
export type ObjectValue =
  | ({
      object_type: "Vendor"
    } & VendorMinimal)
  | ({
      object_type: "Tool"
    } & ToolMinimal)
  | ({
      object_type: "Login"
    } & Login)
  | ({
      object_type: "ReconciledTransaction"
    } & TransactionMinimal)
  | ({
      object_type: "LegalAgreement"
    } & LegalAgreementWithMinimalRelations)
  | ({
      object_type: "WorkflowRun"
    } & WorkflowRunMinimal)
export interface WorkflowRunStepMinimal {
  id: string
  workflow_run_id: string
  status: WorkflowRunStepStatus
  display_name: NonEmptyTrimmedProseString
  type: WorkflowStepStandardType
  fields: FormFieldConfig[]
  submitted_at: null | DateTimeString
  seller_submitted_at: null | DateTimeString
  completed_at: null | DateTimeString
}
export interface CommentTimelineEvent {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: null | string
  object_id: string
  object_type: ObjectType
  type: "comment"
  actor: NonPendingUser
  field_name: null | NonEmptyTrimmedString
  rich_text_body: RichText
  replies: TimelineEventReplyTimelineEvent[]
  object_value: null | ObjectValue
  is_private: null | boolean
  workflow_run_step: null | WorkflowRunStepMinimal
}
export type AlternateActorType = "unregistered_seller_user" | "system"
export type ActorOrAlternateType = User | NonPendingUser | AlternateActorType
export interface FieldChangeTimelineEvent {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: null | string
  object_id: string
  object_type: ObjectType
  type: "field_change"
  actor: ActorOrAlternateType
  field_name: NonEmptyTrimmedString
  old_value: any
  new_value: any
  is_custom_field: boolean
  object_value: null | ObjectValue
}
export interface NewObjectTimelineEvent {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: null | string
  object_id: string
  object_type: ObjectType
  type: "new_object"
  actor: ActorOrAlternateType
  object_value: null | ObjectValue
}
export interface FieldApprovedTimelineEvent {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: null | string
  object_id: string
  object_type: ObjectType
  type: "field_approved"
  actor: User
  field_name: NonEmptyTrimmedString
}
export interface FieldUnapprovedTimelineEvent {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: null | string
  object_id: string
  object_type: ObjectType
  type: "field_unapproved"
  actor: User
  field_name: NonEmptyTrimmedString
}
export interface WorkflowStepUnapprovedTimelineEvent {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: null | string
  type: "workflow_step_unapproved"
  actor: User
  rich_text_body: RichText
  workflow_run_step: {
    id: string
    display_name: NonEmptyTrimmedProseString
    workflow_run_id: string
  }
}
export interface WorkflowSellerInviteSentEvent {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: (null | string) & string
  workflow_run_step_id: null | string
  object_id: string
  object_type: ObjectType
  type: "workflow_seller_invite_sent"
  actor: ActorOrAlternateType
  invited_user: {
    first_name?: NonEmptyTrimmedString
    last_name?: NonEmptyTrimmedString
    email: EmailString
  }
}
export interface WorkflowSellerLinkStepSubmittedEvent {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: (null | string) & string
  object_id: string
  object_type: ObjectType
  type: "workflow_seller_link_step_submitted"
  actor: ActorOrAlternateType
  workflow_run_step: {
    id: string
    display_name: NonEmptyTrimmedProseString
    workflow_run_id: string
  }
}
export interface WorkflowStepSubmittedForReviewEvent {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: (null | string) & string
  object_id: string
  object_type: ObjectType
  type: "workflow_step_submitted_for_review"
  actor: User
  workflow_run_step: {
    id: string
    display_name: NonEmptyTrimmedProseString
    workflow_run_id: string
  }
}
export interface WorkflowStepApprovedEvent {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: (null | string) & string
  object_id: string
  object_type: ObjectType
  type: "workflow_step_approved"
  actor: User
  workflow_run_step: {
    id: string
    display_name: NonEmptyTrimmedProseString
    workflow_run_id: string
  }
}
export interface WorkflowStepCompletedEvent {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: (null | string) & string
  object_id: string
  object_type: ObjectType
  type: "workflow_step_completed"
  actor: User
  workflow_run_step: {
    id: string
    display_name: NonEmptyTrimmedProseString
    workflow_run_id: string
  }
}
export interface DeleteObjectTimelineEvent {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: null | string
  object_id: string
  object_type: ObjectType
  type: "delete_object"
  actor: ActorOrAlternateType
  object_value: null | ObjectValue
}
export interface WorkflowStepChangesRequestedEvent {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: (null | string) & string
  object_id: string
  object_type: ObjectType
  type: "workflow_step_changes_requested"
  actor: User
  workflow_run_step: {
    id: string
    display_name: NonEmptyTrimmedProseString
    workflow_run_id: string
  }
}
export type TimelineEvent =
  | ({
      type: "comment"
    } & CommentTimelineEvent)
  | ({
      type: "timeline_event_reply"
    } & TimelineEventReplyTimelineEvent)
  | ({
      type: "field_change"
    } & FieldChangeTimelineEvent)
  | ({
      type: "new_object"
    } & NewObjectTimelineEvent)
  | ({
      type: "field_approved"
    } & FieldApprovedTimelineEvent)
  | ({
      type: "field_unapproved"
    } & FieldUnapprovedTimelineEvent)
  | ({
      type: "workflow_step_unapproved"
    } & WorkflowStepUnapprovedTimelineEvent)
  | ({
      type: "workflow_seller_invite_sent"
    } & WorkflowSellerInviteSentEvent)
  | ({
      type: "workflow_seller_link_step_submitted"
    } & WorkflowSellerLinkStepSubmittedEvent)
  | ({
      type: "workflow_step_submitted_for_review"
    } & WorkflowStepSubmittedForReviewEvent)
  | ({
      type: "workflow_step_approved"
    } & WorkflowStepApprovedEvent)
  | ({
      type: "workflow_step_completed"
    } & WorkflowStepCompletedEvent)
  | ({
      type: "delete_object"
    } & DeleteObjectTimelineEvent)
  | ({
      type: "workflow_step_changes_requested"
    } & WorkflowStepChangesRequestedEvent)
export interface TimelineParams {
  limit?: LimitNumber
  workflow_run_id?: string
  tool_id?: string
  vendor_id?: string
  /** ISO datetime string to return events until (i.e. `created_at < until`). Cannot be used in combination with `since`. */
  until?: string
  /** ISO datetime string to return events since (i.e. `created_at > since`). Cannot be used in combination with `until`. */
  since?: string
}
export interface PaginatedTimelineResponseSchema {
  /** The items for the current page of the collection. */
  items: TimelineEvent[]
  previousPageParams?: TimelineParams
  nextPageParams?: TimelineParams
}
export interface TimelineUpdate {
  rich_text_body?: RichText
}
export type GetWorkflowRunFieldTimelineResponse = TimelineEvent[]
export interface CommentTimelineEventInput {
  type: "comment"
  rich_text_body: RichText
  is_private?: boolean
}
export interface TimelineEventReplyTimelineEventInput {
  type: "timeline_event_reply"
  rich_text_body: RichText
  parent_id: string
}
export type TimelineInput =
  | ({
      type: "comment"
    } & CommentTimelineEventInput)
  | ({
      type: "timeline_event_reply"
    } & TimelineEventReplyTimelineEventInput)
export interface ReactionInput {
  emoji: NonEmptyTrimmedString
}
export type ErpTrackingCategory =
  | ({
      type: "department"
    } & ErpDepartment)
  | ({
      type: "class"
    } & ErpClass)
export interface UploadableImageAsset {
  id: string
  gcs_file_name: NonEmptyTrimmedString
  created_at: DateTimeString
  file_name: null | NonEmptyTrimmedString
  mime_type: null | NonEmptyTrimmedString
  upload_url: string
}
export interface ImageAssetInput {
  file_name: NonEmptyTrimmedString
  file_size: number
  mime_type: NonEmptyTrimmedString
}
export interface MentionNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "mention"
  timeline_event:
    | ({
        type: "timeline_event_reply"
      } & TimelineEventReplyTimelineEvent)
    | ({
        type: "comment"
      } & CommentTimelineEvent)
  vendor_display_name: null | string
  vendor_logo_asset: null | ImageAsset
  tool_display_name: null | string
  workflow_run_display_name: null | string
  workflow_run_step_display_name: null | string
  workflow_run_organization_id: null | string
  link_auth_code: null | string
  field_title: null | string
}
export interface WorkflowRunWithUsers {
  id: string
  object_type: "WorkflowRun"
  display_name: NonEmptyTrimmedProseString
  created_at: DateTimeString
  kind: WorkflowKind
  status: WorkflowRunStatus
  completed_at: null | DateTimeString
  target_date: DateString
  created_by: User
  owner: User
}
export interface WorkflowRunStartedNotification {
  id: string
  sender: (null | UserMinimal) & UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run.started"
  workflow_run: WorkflowRunWithUsers
  workflow_run_display_name: null | string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export interface WorkflowRunInputNeededNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run.input_needed"
  workflow_run: WorkflowRunMinimal
  workflow_run_display_name: null | string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export interface WorkflowRunCompletedNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run.completed"
  workflow_run: WorkflowRunMinimal
  workflow_run_display_name: string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export interface WorkflowRunStepApprovedNotification {
  id: string
  sender: (null | UserMinimal) & UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run_step.approved"
  workflow_run_step: WorkflowRunStepMinimal
  workflow_run: WorkflowRunMinimal
  workflow_run_display_name: string
  workflow_run_step_display_name: string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export interface WorkflowRunStepApprovalRequestedNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run_step.approval_requested"
  workflow_run_step: WorkflowRunStepMinimal
  workflow_run: WorkflowRunMinimal
  workflow_run_display_name: string
  workflow_run_step_display_name: string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export interface WorkflowRunStepUnapprovedNotification {
  id: string
  sender: (null | UserMinimal) & UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run_step.unapproved"
  workflow_run_step: WorkflowRunStepMinimal
  workflow_run: WorkflowRunMinimal
  workflow_run_display_name: string
  workflow_run_step_display_name: string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export interface WorkflowRunStepChangesRequestedNotification {
  id: string
  sender: (null | UserMinimal) & UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run_step.changes_requested"
  workflow_run_step: WorkflowRunStepMinimal
  workflow_run: WorkflowRunMinimal
  workflow_run_display_name: string
  workflow_run_step_display_name: string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export interface WorkflowRunStepFieldReopenedNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run_step_field.reopened"
  workflow_run_step_field_approval: WorkflowRunStepFieldApproval
  workflow_run_step: WorkflowRunStepMinimal
  workflow_run: WorkflowRunMinimal
  timeline_event: FieldUnapprovedTimelineEvent
  workflow_run_display_name: string
  workflow_run_step_display_name: string
  field_title: string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export interface WorkflowRunStepTimelineEventNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run.timeline_comment"
  timeline_event: CommentTimelineEvent
  workflow_run_step: null | WorkflowRunStepMinimal
  workflow_run: WorkflowRunMinimal
  workflow_run_display_name: string
  workflow_run_step_display_name: null | string
  workflow_run_organization_id: null | string
  link_auth_code: null | string
  field_title: null | string
  vendor_logo_asset: null | ImageAsset
}
export interface ToolTimelineCommentNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "tool.timeline_comment"
  timeline_event: CommentTimelineEvent
  tool_display_name: null | string
}
export interface VendorTimelineCommentNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "vendor.timeline_comment"
  timeline_event: CommentTimelineEvent
  vendor_display_name: string
}
export interface RenewalReminder30DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "renewal_reminder.30d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface RenewalReminder60DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "renewal_reminder.60d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface RenewalReminder90DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "renewal_reminder.90d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface OptOutReminder30DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "opt_out_reminder.30d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface OptOutReminder60DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "opt_out_reminder.60d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface OptOutReminder90DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "opt_out_reminder.90d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface ToolOwnedTimelineCommentNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "tool_owned.timeline_comment"
  timeline_event: CommentTimelineEvent
  tool_display_name: string
}
export interface ToolOwnedRenewalReminder30DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "tool_owned_renewal_reminder.30d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface ToolOwnedRenewalReminder60DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "tool_owned_renewal_reminder.60d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface ToolOwnedRenewalReminder90DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "tool_owned_renewal_reminder.90d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface ToolOwnedOptOutReminder30DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "tool_owned_opt_out_reminder.30d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface ToolOwnedOptOutReminder60DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "tool_owned_opt_out_reminder.60d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface ToolOwnedOptOutReminder90DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "tool_owned_opt_out_reminder.90d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface VendorOwnedTimelineCommentNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "vendor_owned.timeline_comment"
  timeline_event: CommentTimelineEvent
  vendor_display_name: string
}
export interface VendorOwnedRenewalReminder30DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "vendor_owned_renewal_reminder.30d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface VendorOwnedRenewalReminder60DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "vendor_owned_renewal_reminder.60d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface VendorOwnedRenewalReminder90DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "vendor_owned_renewal_reminder.90d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface VendorOwnedOptOutReminder30DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "vendor_owned_opt_out_reminder.30d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface VendorOwnedOptOutReminder60DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "vendor_owned_opt_out_reminder.60d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export interface VendorOwnedOptOutReminder90DNotification {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "vendor_owned_opt_out_reminder.90d"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export type InboxNotification =
  | ({
      type: "mention"
    } & MentionNotification)
  | ({
      type: "workflow_run.started"
    } & WorkflowRunStartedNotification)
  | ({
      type: "workflow_run.input_needed"
    } & WorkflowRunInputNeededNotification)
  | ({
      type: "workflow_run.completed"
    } & WorkflowRunCompletedNotification)
  | ({
      type: "workflow_run_step.approved"
    } & WorkflowRunStepApprovedNotification)
  | ({
      type: "workflow_run_step.approval_requested"
    } & WorkflowRunStepApprovalRequestedNotification)
  | ({
      type: "workflow_run_step.unapproved"
    } & WorkflowRunStepUnapprovedNotification)
  | ({
      type: "workflow_run_step.changes_requested"
    } & WorkflowRunStepChangesRequestedNotification)
  | ({
      type: "workflow_run_step_field.reopened"
    } & WorkflowRunStepFieldReopenedNotification)
  | ({
      type: "workflow_run.timeline_comment"
    } & WorkflowRunStepTimelineEventNotification)
  | ({
      type: "tool.timeline_comment"
    } & ToolTimelineCommentNotification)
  | ({
      type: "vendor.timeline_comment"
    } & VendorTimelineCommentNotification)
  | ({
      type: "renewal_reminder.30d"
    } & RenewalReminder30DNotification)
  | ({
      type: "renewal_reminder.60d"
    } & RenewalReminder60DNotification)
  | ({
      type: "renewal_reminder.90d"
    } & RenewalReminder90DNotification)
  | ({
      type: "opt_out_reminder.30d"
    } & OptOutReminder30DNotification)
  | ({
      type: "opt_out_reminder.60d"
    } & OptOutReminder60DNotification)
  | ({
      type: "opt_out_reminder.90d"
    } & OptOutReminder90DNotification)
  | ({
      type: "tool_owned.timeline_comment"
    } & ToolOwnedTimelineCommentNotification)
  | ({
      type: "tool_owned_renewal_reminder.30d"
    } & ToolOwnedRenewalReminder30DNotification)
  | ({
      type: "tool_owned_renewal_reminder.60d"
    } & ToolOwnedRenewalReminder60DNotification)
  | ({
      type: "tool_owned_renewal_reminder.90d"
    } & ToolOwnedRenewalReminder90DNotification)
  | ({
      type: "tool_owned_opt_out_reminder.30d"
    } & ToolOwnedOptOutReminder30DNotification)
  | ({
      type: "tool_owned_opt_out_reminder.60d"
    } & ToolOwnedOptOutReminder60DNotification)
  | ({
      type: "tool_owned_opt_out_reminder.90d"
    } & ToolOwnedOptOutReminder90DNotification)
  | ({
      type: "vendor_owned.timeline_comment"
    } & VendorOwnedTimelineCommentNotification)
  | ({
      type: "vendor_owned_renewal_reminder.30d"
    } & VendorOwnedRenewalReminder30DNotification)
  | ({
      type: "vendor_owned_renewal_reminder.60d"
    } & VendorOwnedRenewalReminder60DNotification)
  | ({
      type: "vendor_owned_renewal_reminder.90d"
    } & VendorOwnedRenewalReminder90DNotification)
  | ({
      type: "vendor_owned_opt_out_reminder.30d"
    } & VendorOwnedOptOutReminder30DNotification)
  | ({
      type: "vendor_owned_opt_out_reminder.60d"
    } & VendorOwnedOptOutReminder60DNotification)
  | ({
      type: "vendor_owned_opt_out_reminder.90d"
    } & VendorOwnedOptOutReminder90DNotification)
export interface NotificationListParams {
  limit: LimitNumber
  /** ISO datetime string to return events until (i.e. `created_at < until`). Cannot be used in combination with `since`. */
  until?: string
  /** ISO datetime string to return events since (i.e. `created_at > since`). Cannot be used in combination with `until`. */
  since?: string
}
export interface NotificationListResponse {
  /** The items for the current page of the collection. */
  items: InboxNotification[]
  previousPageParams?: NotificationListParams
  nextPageParams?: NotificationListParams
}
export interface WorkflowStepGathererTask {
  sort_by_date: DateString
  recipient_id: string
  organization_id: string
  workflow_run_id: string
  workflow_name: string
  tool_logo_gcs_file_name: null | string
  workflow_run_due_date: DateString
  pending_steps: WorkflowStepStandardType[]
  pending_step_ids: string[]
  type: "workflow_step_gatherer"
}
export interface WorkflowChampTaskSchema {
  sort_by_date: DateString
  recipient_id: string
  organization_id: string
  workflow_run_id: string
  workflow_name: string
  tool_logo_gcs_file_name: null | string
  workflow_run_due_date: DateString
  type: "workflow_champ"
  workflow_type: WorkflowKind
  tool_display_name: string
}
export interface WorkflowStepReadyForReviewTask {
  sort_by_date: DateString
  recipient_id: string
  organization_id: string
  workflow_run_id: string
  workflow_name: string
  tool_logo_gcs_file_name: null | string
  workflow_run_due_date: DateString
  workflow_run_step_id: string
  workflow_run_step_display_name: WorkflowStepStandardType
  submitter_first_name: string
  submitter_last_name: string
  submitter_id: string
  type: "workflow_run_step_ready_for_review"
}
export interface WorkflowRunAggregatedStepsReadyForReviewTask {
  sort_by_date: DateString
  recipient_id: string
  organization_id: string
  workflow_run_id: string
  workflow_name: string
  tool_logo_gcs_file_name: null | string
  workflow_run_due_date: DateString
  workflow_run_step_id: string
  workflow_run_step_display_name: WorkflowStepStandardType
  type: "workflow_run_aggregated_steps_ready_for_review"
}
export interface WorkflowFieldGathererTask {
  sort_by_date: DateString
  recipient_id: string
  organization_id: string
  workflow_run_id: string
  workflow_name: string
  tool_logo_gcs_file_name: null | string
  workflow_run_due_date: DateString
  object_type: ObjectType
  object_id: string
  field_name: string
  is_custom: boolean
  type: "workflow_field_gatherer"
  workflow_run_step_id: string
  workflow_run_step_display_name: WorkflowStepStandardType
}
export type Task =
  | ({
      type: "workflow_step_gatherer"
    } & WorkflowStepGathererTask)
  | ({
      type: "workflow_champ"
    } & WorkflowChampTaskSchema)
  | ({
      type: "workflow_run_step_ready_for_review"
    } & WorkflowStepReadyForReviewTask)
  | ({
      type: "workflow_run_aggregated_steps_ready_for_review"
    } & WorkflowRunAggregatedStepsReadyForReviewTask)
  | ({
      type: "workflow_field_gatherer"
    } & WorkflowFieldGathererTask)
export interface TaskListResponse {
  items: Task[]
}
export interface NotificationCountResponse {
  count: number
}
export interface NotificationUnreadResponse {
  unread: boolean
}
export interface NotificationUpdate {
  read_at?: null | DateTimeString
  archived_at?: null | DateTimeString
}
export interface NotificationBulkActionBody {
  action: "delete_all" | "delete_read" | "mark_all_read" | "mark_all_unread"
}
export type DynamoRunStatus =
  | "init"
  | "collected"
  | "answered"
  | "done"
  | "collection_failed"
  | "answering_failed"
  | "unreachable_url"
export interface DynamoRun {
  id: string
  created_at: string
  updated_at: string
  status: DynamoRunStatus
  reviewed_links: HttpUrlString[]
  relevant_links: HttpUrlString[]
  emails: EmailString[]
  field_names: string[]
  tool_listing_id: string
}
export type IsDynamoRunning = {
  id: string
  created_at: string
  updated_at: string
  status: DynamoRunStatus
  reviewed_links: HttpUrlString[]
  relevant_links: HttpUrlString[]
  emails: EmailString[]
  field_names: string[]
  tool_listing_id: string
} | null
export type ListingStatus = "active" | "hidden"
export interface CreateToolListing {
  key: NonEmptyString
  admin_email: EmailString
  display_name: NonEmptyTrimmedProseString
  description: NonEmptyTrimmedProseString
  vendor_listing_id: string
  website?: HttpUrlString
  status?: ListingStatus
}
export interface UpdateToolListing {
  admin_email?: EmailString
  display_name?: NonEmptyTrimmedProseString
  description?: NonEmptyTrimmedProseString
  website?: HttpUrlString
  status?: ListingStatus
}
export type ToolUserReportColumn = "display_name" | "description" | "logo_image_asset_id" | "website" | "other"
export interface ToolUserReport {
  report_columns: ToolUserReportColumn[]
  report_body?: NonEmptyString
}
export interface LoginIdentifier {
  client_id: NonEmptyString
  provider: IntegrationProvider
}
export interface CreateVendorListing {
  key: NonEmptyString
  admin_email: EmailString
  display_name: NonEmptyTrimmedProseString
  description: NonEmptyTrimmedProseString
  website?: HttpUrlString
  status?: ListingStatus
}
export interface UpdateVendorListing {
  admin_email?: EmailString
  display_name?: NonEmptyTrimmedProseString
  description?: NonEmptyTrimmedProseString
  website?: HttpUrlString
  status?: ListingStatus
}
export interface VendorUserReport {
  report_columns: ("display_name" | "description" | "logo_image_asset_id" | "website" | "other")[]
  report_body?: NonEmptyTrimmedProseString
}
export type ToolOrVendorString = "tool" | "vendor"
export const {
  useGetOpenapiQuery,
  useLazyGetOpenapiQuery,
  useGetSchemaV1ByObjectTypeQuery,
  useLazyGetSchemaV1ByObjectTypeQuery,
  useGetSchemaV1ByObjectTypePatchQuery,
  useLazyGetSchemaV1ByObjectTypePatchQuery,
  useGetSchemaV1ByObjectTypeInputQuery,
  useLazyGetSchemaV1ByObjectTypeInputQuery,
  useGetSchemaV1ByObjectTypeFieldsQuery,
  useLazyGetSchemaV1ByObjectTypeFieldsQuery,
  usePostSchemaV1FieldsDuplicatesQuery,
  useLazyPostSchemaV1FieldsDuplicatesQuery,
  usePutSchemaV1ByObjectTypeFieldsAndFieldNameMutation,
  useDeleteSchemaV1ByObjectTypeFieldsAndFieldNameMutation,
  usePostSchemaV1FieldsTextToFieldMutation,
  usePostSchemaV1MapQuery,
  useLazyPostSchemaV1MapQuery,
  usePostEmailV1InboundMutation,
  useGetUserV1WhoamiQuery,
  useLazyGetUserV1WhoamiQuery,
  useGetUserV1SettingsQuery,
  useLazyGetUserV1SettingsQuery,
  useGetUserV1SettingsTotpQrQuery,
  useLazyGetUserV1SettingsTotpQrQuery,
  useGetUserV1EmailDelegatesQuery,
  useLazyGetUserV1EmailDelegatesQuery,
  usePostUserV1EmailDelegatesMutation,
  usePostUserV1SettingsTotpEnableMutation,
  usePostUserV1SettingsTotpDisableMutation,
  useGetUserV1NotificationSettingsQuery,
  useLazyGetUserV1NotificationSettingsQuery,
  usePutUserV1NotificationSettingsMutation,
  usePostUserV1DisableNotificationChannelMutation,
  useGetUserV1ByIdQuery,
  useLazyGetUserV1ByIdQuery,
  useDeleteUserV1ByIdMutation,
  usePutUserV1ByIdMutation,
  useGetUserV1DeletionRequirementsByIdQuery,
  useLazyGetUserV1DeletionRequirementsByIdQuery,
  usePostUserV1OrganizationQuery,
  useLazyPostUserV1OrganizationQuery,
  usePostUserV1CreateMutation,
  usePostUserV1UpdateRolesMutation,
  usePostUserV1InviteMutation,
  usePostUserV1ResendInviteMutation,
  usePostUserV1LoginMutation,
  usePostUserV1SigninOktaInitiateMutation,
  usePostUserV1ForgotPasswordMutation,
  usePostUserV1ChangePasswordMutation,
  useGetUserV1LogoutMutation,
  usePostUserV1CreateUserMutation,
  useGetOrganizationV1CurrentQuery,
  useLazyGetOrganizationV1CurrentQuery,
  useGetOrganizationV1CurrentOktaConfigQuery,
  useLazyGetOrganizationV1CurrentOktaConfigQuery,
  usePutOrganizationV1UpdateMutation,
  usePostOrganizationV1CreateMutation,
  usePostOrganizationV1ClearOrganizationMutation,
  usePostOrganizationV1PickableEntitiesQuery,
  useLazyPostOrganizationV1PickableEntitiesQuery,
  usePostOrganizationV1PermissionedPickableEntitiesQuery,
  useLazyPostOrganizationV1PermissionedPickableEntitiesQuery,
  useGetSpendV1ByTypeAndIdQuery,
  useLazyGetSpendV1ByTypeAndIdQuery,
  useGetStatisticsV1EmailIntegrationsQuery,
  useLazyGetStatisticsV1EmailIntegrationsQuery,
  useGetStatisticsV1AgreementsQuery,
  useLazyGetStatisticsV1AgreementsQuery,
  useGetStatisticsV1PersonsQuery,
  useLazyGetStatisticsV1PersonsQuery,
  useGetStatisticsV1ToolsQuery,
  useLazyGetStatisticsV1ToolsQuery,
  usePostToolV1ListQuery,
  useLazyPostToolV1ListQuery,
  usePostToolV1CsvMutation,
  usePostToolV1AiFilterMutation,
  useGetToolV1PickerOptionsQuery,
  useLazyGetToolV1PickerOptionsQuery,
  usePutToolV1ByIdFollowingMutation,
  usePatchToolV1ByIdMutation,
  useGetToolV1ByIdQuery,
  useLazyGetToolV1ByIdQuery,
  usePostToolV1ByIdLoginsListQuery,
  useLazyPostToolV1ByIdLoginsListQuery,
  usePostToolV1ByIdPersonsListQuery,
  useLazyPostToolV1ByIdPersonsListQuery,
  usePostToolV1ByIdPersonsCsvMutation,
  usePostToolV1Mutation,
  useGetToolV1CategoriesQuery,
  useLazyGetToolV1CategoriesQuery,
  useGetToolV1ByIdAndQuery,
  useLazyGetToolV1ByIdAndQuery,
  usePostLegalV1AgreementsListQuery,
  useLazyPostLegalV1AgreementsListQuery,
  useGetLegalV1AgreementsPickerOptionsByQueryQuery,
  useLazyGetLegalV1AgreementsPickerOptionsByQueryQuery,
  useGetLegalV1AgreementsByIdQuery,
  useLazyGetLegalV1AgreementsByIdQuery,
  useDeleteLegalV1AgreementsByIdMutation,
  usePostLegalV1AgreementsByIdVerificationMutation,
  usePostLegalV1DetectLegalAgreementsByOrganizationIdMutation,
  useGetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlQuery,
  useLazyGetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlQuery,
  useGetLegalV1AgreementsByIdDocumentsAndDocumentIdContentQuery,
  useLazyGetLegalV1AgreementsByIdDocumentsAndDocumentIdContentQuery,
  usePostLegalV1AgreementsMutation,
  useGetLegalV1AgreementsByIdAndQuery,
  useLazyGetLegalV1AgreementsByIdAndQuery,
  usePostVendorV1ListQuery,
  useLazyPostVendorV1ListQuery,
  usePostVendorV1CsvMutation,
  usePostVendorV1AiFilterMutation,
  useGetVendorV1PickerOptionsQuery,
  useLazyGetVendorV1PickerOptionsQuery,
  usePutVendorV1ByIdFollowingMutation,
  usePatchVendorV1ByIdMutation,
  useGetVendorV1ByIdQuery,
  useLazyGetVendorV1ByIdQuery,
  useGetVendorV1ByIdSpendBreakdownQuery,
  useLazyGetVendorV1ByIdSpendBreakdownQuery,
  useGetVendorV1ByIdAndQuery,
  useLazyGetVendorV1ByIdAndQuery,
  useGetOauthV1SigninByProviderQuery,
  useLazyGetOauthV1SigninByProviderQuery,
  useGetOauthV1SigninByProviderCallbackQuery,
  useLazyGetOauthV1SigninByProviderCallbackQuery,
  useGetOauthV1AuthByProviderQuery,
  useLazyGetOauthV1AuthByProviderQuery,
  useGetOauthV1AuthByProviderCallbackQuery,
  useLazyGetOauthV1AuthByProviderCallbackQuery,
  useGetOauthV1ConnectByProviderQuery,
  useLazyGetOauthV1ConnectByProviderQuery,
  useGetOauthV1ConnectByProviderCallbackQuery,
  useLazyGetOauthV1ConnectByProviderCallbackQuery,
  useGetIntegrationV1Query,
  useLazyGetIntegrationV1Query,
  useGetIntegrationV1PrivateQuery,
  useLazyGetIntegrationV1PrivateQuery,
  useDeleteIntegrationV1ByIdMutation,
  usePostIntegrationV1ClearDataMutation,
  usePostIntegrationV1CreateMutation,
  usePostIntegrationV1ByIdDisableMutation,
  usePostIntegrationV1ByIdEnableMutation,
  usePostIntegrationV1UpdateMutation,
  usePostIntegrationV1CrawlByIdMutation,
  useGetIntegrationV1HrisBySourceLinkTokenQuery,
  useLazyGetIntegrationV1HrisBySourceLinkTokenQuery,
  useGetIntegrationV1AccountingBySourceLinkTokenQuery,
  useLazyGetIntegrationV1AccountingBySourceLinkTokenQuery,
  usePostIntegrationV1MergeSyncMutation,
  usePostPersonV1Query,
  useLazyPostPersonV1Query,
  usePostPersonV1MergeMutation,
  useGetPersonV1ByIdQuery,
  useLazyGetPersonV1ByIdQuery,
  useGetPersonV1ByIdToolsQuery,
  useLazyGetPersonV1ByIdToolsQuery,
  useGetLoginV1SummaryQuery,
  useLazyGetLoginV1SummaryQuery,
  useGetLoginV1UserActivityQuery,
  useLazyGetLoginV1UserActivityQuery,
  useGetSearchV1TextQuery,
  useLazyGetSearchV1TextQuery,
  useGetEmployeeGroupV1Query,
  useLazyGetEmployeeGroupV1Query,
  usePostDocumentV1Mutation,
  usePutDocumentV1ByIdMutation,
  useGetDocumentV1ExtractingQuery,
  useLazyGetDocumentV1ExtractingQuery,
  usePostDocumentV1BackgroundExtractMutation,
  usePostDocumentV1MoveToAgreementMutation,
  usePostDocumentV1ByIdBackgroundExtractMutation,
  usePostDocumentV1RetryPartialExtractionMutation,
  usePostDocumentV1ByIdRetryExtractionMutation,
  usePostDocumentV1ByIdExtractFormMutation,
  useGetDocumentV1OwnByIdContentQuery,
  useLazyGetDocumentV1OwnByIdContentQuery,
  useGetDocumentV1OwnByIdUrlQuery,
  useLazyGetDocumentV1OwnByIdUrlQuery,
  useGetWorkflowV1ByKindSchemaQuery,
  useLazyGetWorkflowV1ByKindSchemaQuery,
  useGetWorkflowV1DefinitionsByIdQuery,
  useLazyGetWorkflowV1DefinitionsByIdQuery,
  usePutWorkflowV1DefinitionsByIdMutation,
  useGetWorkflowV1DefinitionsRenewalQuery,
  useLazyGetWorkflowV1DefinitionsRenewalQuery,
  useGetWorkflowV1DefinitionsPurchaseQuery,
  useLazyGetWorkflowV1DefinitionsPurchaseQuery,
  useGetWorkflowV1DefinitionsQuery,
  useLazyGetWorkflowV1DefinitionsQuery,
  usePostWorkflowV1DefinitionsMutation,
  useGetWorkflowV1RunsPickablesQuery,
  useLazyGetWorkflowV1RunsPickablesQuery,
  usePostWorkflowV1DefinitionsConditionalMutation,
  usePostWorkflowV1RunsListQuery,
  useLazyPostWorkflowV1RunsListQuery,
  usePostWorkflowV1RunsMutation,
  useGetWorkflowV1RunsByIdQuery,
  useLazyGetWorkflowV1RunsByIdQuery,
  usePatchWorkflowV1RunsByIdMutation,
  useDeleteWorkflowV1RunsByIdMutation,
  usePutWorkflowV1RunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameGathererMutation,
  usePostWorkflowV1RunsByIdCancelMutation,
  usePatchWorkflowV1RunsByIdStepsMutation,
  usePutWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApprovalMutation,
  useGetWorkflowV1SellerLinksQuery,
  useLazyGetWorkflowV1SellerLinksQuery,
  usePostWorkflowV1LinksMutation,
  useGetWorkflowV1LinksByCodeWorkflowRunQuery,
  useLazyGetWorkflowV1LinksByCodeWorkflowRunQuery,
  usePostWorkflowV1LinksByCodeWorkflowRunStepStatesAndWorkflowRunStepIdMutation,
  useGetWorkflowV1LinksByCodeQuery,
  useLazyGetWorkflowV1LinksByCodeQuery,
  usePostWorkflowV1LinksByCodeMutation,
  usePostWorkflowV1StepRunsByIdDraftMutation,
  usePostWorkflowV1StepRunsByWorkflowRunStepIdUnapproveMutation,
  usePostWorkflowV1StepRunsByWorkflowRunStepIdRequestChangesMutation,
  usePostWorkflowV1StepRunsByWorkflowRunStepIdSubmissionMutation,
  useGetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdUrlQuery,
  useLazyGetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdUrlQuery,
  useGetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdContentQuery,
  useLazyGetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdContentQuery,
  useGetTransactionV1ByIdQuery,
  useLazyGetTransactionV1ByIdQuery,
  usePostTransactionV1ListQuery,
  useLazyPostTransactionV1ListQuery,
  useGetTransactionV1ByIdReceiptsAndReceiptIdDocumentUrlQuery,
  useLazyGetTransactionV1ByIdReceiptsAndReceiptIdDocumentUrlQuery,
  useGetTransactionV1ByIdReceiptsAndReceiptIdDocumentContentQuery,
  useLazyGetTransactionV1ByIdReceiptsAndReceiptIdDocumentContentQuery,
  useGetSavedViewV1ByTableIdentifierQuery,
  useLazyGetSavedViewV1ByTableIdentifierQuery,
  usePutSavedViewV1ByIdMutation,
  useDeleteSavedViewV1ByIdMutation,
  usePostSavedViewV1CreateMutation,
  usePostBetsyV1VectorMutation,
  usePostBetsyV1AskMutation,
  usePostBetsyV1SlackEventMutation,
  useGetBetsyV1ConversationsQuery,
  useLazyGetBetsyV1ConversationsQuery,
  useGetBetsyV1ConversationsByIdQuery,
  useLazyGetBetsyV1ConversationsByIdQuery,
  usePutErpAccountV1ByIdMutation,
  useGetTimelineV1EventsQuery,
  useLazyGetTimelineV1EventsQuery,
  useDeleteTimelineV1EventsByIdMutation,
  usePutTimelineV1EventsByIdMutation,
  useGetTimelineV1WorkflowRunByWorkflowRunIdQuery,
  useLazyGetTimelineV1WorkflowRunByWorkflowRunIdQuery,
  useGetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdQuery,
  useLazyGetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdQuery,
  useGetTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldNameQuery,
  useLazyGetTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldNameQuery,
  useGetTimelineV1EventsByIdDocumentContentQuery,
  useLazyGetTimelineV1EventsByIdDocumentContentQuery,
  usePostTimelineV1EventsObjectsByObjectTypeAndObjectIdMutation,
  usePostTimelineV1EventsByIdReactionsMutation,
  useDeleteTimelineV1EventsByIdReactionsAndReactionIdMutation,
  useGetErpTrackingCategoryV1Query,
  useLazyGetErpTrackingCategoryV1Query,
  usePostImageAssetV1ImageAssetsMutation,
  useGetImageAssetV1ByIdContentQuery,
  useLazyGetImageAssetV1ByIdContentQuery,
  useGetPlaidV1LinkTokenQuery,
  useLazyGetPlaidV1LinkTokenQuery,
  usePostPlaidV1WebhooksMutation,
  useGetNotificationV1Query,
  useLazyGetNotificationV1Query,
  useGetNotificationV1TasksQuery,
  useLazyGetNotificationV1TasksQuery,
  useGetNotificationV1CountQuery,
  useLazyGetNotificationV1CountQuery,
  useGetNotificationV1UnreadQuery,
  useLazyGetNotificationV1UnreadQuery,
  useGetNotificationV1ByIdQuery,
  useLazyGetNotificationV1ByIdQuery,
  usePutNotificationV1ByIdMutation,
  usePutNotificationV1BulkMutation,
  usePostDynamoV1ByToolListingIdMutation,
  useGetDynamoV1RunByRunIdQuery,
  useLazyGetDynamoV1RunByRunIdQuery,
  useGetDynamoV1ByIdIsDynamoRunningQuery,
  useLazyGetDynamoV1ByIdIsDynamoRunningQuery,
  usePostDynamoV1ByIdReportDataTelemetryMutation,
  usePostRetoolV1DynamoRunLinearQaAgentMutation,
  usePostRetoolV1DynamoRunLinearQaAgentByIssueIdMutation,
  usePostRetoolV1DynamoRunComplianceAgentMutation,
  usePostToolListingV1Mutation,
  usePutToolListingV1ByIdMutation,
  useDeleteToolListingV1ByIdMutation,
  usePostToolListingV1ByIdReportDataMutation,
  usePostToolListingV1KeysMutation,
  usePutToolListingV1ReplaceListingMutation,
  usePutToolListingV1ByIdLogoMutation,
  usePostToolListingV1TransferLoginMutation,
  usePostToolListingV1TransferDescriptorMutation,
  usePostToolListingV1UpdateListingLogosMutation,
  usePostVendorListingV1Mutation,
  usePostVendorListingV1MarkSimilarityCheckedMutation,
  usePostVendorListingV1QueryVectorsMutation,
  usePutVendorListingV1ByIdMutation,
  useDeleteVendorListingV1ByIdMutation,
  usePostVendorListingV1ByIdReportDataMutation,
  usePostVendorListingV1KeysMutation,
  usePutVendorListingV1ReplaceListingMutation,
  usePutVendorListingV1ByIdLogoMutation,
  useGetVendorListingV1FindWebsiteByListingTypeQuery,
  useLazyGetVendorListingV1FindWebsiteByListingTypeQuery,
  usePostVendorListingV1TransferToolMutation,
  usePostVendorListingV1TransferDescriptorMutation,
  usePostVendorListingV1UpdateListingLogosMutation,
  useGetVendorListingV1DraftListingFromUrlByListingTypeQuery,
  useLazyGetVendorListingV1DraftListingFromUrlByListingTypeQuery,
  usePutTransactionVendorToolListingV1ByMerchantIdentificationMutation,
} = injectedRtkApi
